<template>
  <HeaderLine/>
  <Menu/>
  <div class="recipe">
    <div class="gingamuCheck">
      <div class="recipeJP">{{ title }}</div>
    </div>
    <div class="breadcrumbWrap">
      <span @click="transition('RentinMenu')">トップページ</span>&nbsp;>&nbsp;{{ dispTitle }}
    </div>
    <div id="previewPart">
      <div class="preview">
        <div class="tags" v-if="tags && tags.length > 0">
          <span class="tag" v-for="(value, key) in tags" :key="key">{{ value }}</span>
        </div>
        <div class="previewImg" v-if="imgUrls.length > 0">
          <img :src="imgUrls[0]" alt="">
        </div>
        <div><span v-html="autoLink(description)"></span></div>
        <div class="materialPart" v-if="materialData">
          <p class="how">材料（{{ toFullWidth(materialData.numOfPeople) }}分）</p>
          <div class="material">
            <div v-if="materialData.ingredients && materialData.ingredients.length > 0" class="ingredient">
              <div class="ingredientPart">
                <p class="title">【材料】</p>
                <div class="ingredientZentai">
                  <div v-for="(ingredient, key) in materialData.ingredients" :key="key">
                    <table class="iHeight ingredientTable">
                      <tbody>
                        <tr>
                          <td class="ingredientTd">・{{ ingredient.name }}</td>
                          <td>{{ toFullWidth(ingredient.weight1 ? ingredient.weight1 : "") }}{{ toFullWidth(ingredient.weight2 ? ingredient.weight2 : "") }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-if="ingredient.preparation && ingredient.preparation.length > 0" class="kakko">
                      <table class="preparationPart frame-box-002">
                        <tbody>
                          <tr v-for="(preparation, key) in ingredient.preparation" :key="key" class="iHeight">
                            <td class="preparationTd">{{ preparation.name }}</td>
                            <td>{{ toFullWidth(preparation.weight1 ? preparation.weight1 : "") }}{{ toFullWidth(preparation.weight2 ? preparation.weight2 : "") }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="materialData.seasonings && materialData.seasonings.length > 0" class="seasoning">
              <div class="seasoningPart">
                <p class="title">【調味料】</p>
                <div class="seasonZentai">
                  <div v-for="(seasoning, key) in materialData.seasonings" :key="key">
                    <table class="iHeight seasoningTable">
                      <tbody>
                        <tr>
                          <td class="seasoningTd"><span v-if="!seasoning.tenCheck || seasoning.tenCheck == false">・</span><span v-else>&emsp;</span>{{ seasoning.group ? seasoning.group : "" }}{{ seasoning.name }}</td>
                          <td>{{ toFullWidth(seasoning.weight1 ? seasoning.weight1 : "") }}{{ toFullWidth(seasoning.weight2 ? seasoning.weight2 : "") }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="procedure1" class="how">作り方</div>
        <ol>
          <li class="indent" v-if="procedure1">&#9312; <span v-html="autoLink(procedure1)"></span></li>
          <li class="indent" v-if="procedure2">&#9313; <span v-html="autoLink(procedure2)"></span></li>
          <li class="indent" v-if="procedure3">&#9314; <span v-html="autoLink(procedure3)"></span></li>
          <li class="indent" v-if="procedure4">&#9315; <span v-html="autoLink(procedure4)"></span></li>
          <li class="indent" v-if="procedure5">&#9316; <span v-html="autoLink(procedure5)"></span></li>
          <li class="indent" v-if="procedure6">&#9317; <span v-html="autoLink(procedure6)"></span></li>
          <li class="indent" v-if="procedure7">&#9318; <span v-html="autoLink(procedure7)"></span></li>
          <li class="indent" v-if="procedure8">&#9319; <span v-html="autoLink(procedure8)"></span></li>
          <li class="indent" v-if="procedure9">&#9320; <span v-html="autoLink(procedure9)"></span></li>
          <li class="indent" v-if="procedure10">&#9321; <span v-html="autoLink(procedure10)"></span></li>
        </ol>
        <div v-if="point" class="pointWrap">
          <div class="pointTitle">Point!!</div>
          <div class="point indent"><span v-html="autoLink(point)"></span></div>
        </div>
        <div v-if="youtubeUrl">
          <iframe class="youtubeMovie" width="1273" height="695" :src="'https://www.youtube.com/embed/' + youtubeUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
  <Recommend/>
  <ToTop/>
  <Footer/>
</template>

<script>
import firebase from "../main.js";
import { doc, getDoc } from "firebase/firestore";

import HeaderLine from "@/components/HeaderLine.vue";
import Menu from "@/components/Menu.vue";
import Recommend from "@/components/Recommend.vue";
import ToTop from "@/components/ToTop.vue";
import Footer from "@/components/Footer.vue";

import { getAnalytics, logEvent } from "firebase/analytics";

export default {
  name: 'Recipe',
  components: {
    HeaderLine,
    Menu,
    Recommend,
    ToTop,
    Footer,
  },
  data() {
    return {
      id: null,
      title: null,
      dispTitle: null,
      tags: null,
      description: null,
      procedure1: null,
      procedure2: null,
      procedure3: null,
      procedure4: null,
      procedure5: null,
      procedure6: null,
      procedure7: null,
      procedure8: null,
      procedure9: null,
      procedure10: null,
      point: null,
      youtubeUrl: null,
      rentinTimes: null,
      imgUrls: [],
      materialData: null,
      recipeYoutubeUrl: null,
      hirogeruValue: 0,
      sebameruValue: 0,
      zentaiAgeruValue: 0,
      zentaiSageruValue: 0,
      ingredientDetailHidariValue: 0,
      ingredientDetailMigiValue: 0,
      ingredientPartHidariValue: 0,
      ingredientPartMigiValue: 0,
      preparationDetailHidariValue: 0,
      preparationDetailMigiValue: 0,
      preparationPartHidariValue: 0,
      preparationPartMigiValue: 0,
      seasoningDetailHidariValue: 0,
      seasoningDetailMigiValue: 0,
      seasoningPartHidariValue: 0,
      seasoningPartMigiValue: 0,
    }
  },
  watch: {
    $route () {
      location.reload()
    }
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.awaitFunc();
    if (location.pathname == "/trecipe") {
      document.getElementsByClassName("gingamuCheck")[0].style.backgroundImage = "linear-gradient(90deg, rgba(93, 84, 214, 0.1) 50%, transparent 50%), linear-gradient(rgba(93, 84, 214, 0.1) 50%, transparent 50%)";
    }
    if (location.host.indexOf("localhost") < 0) {
      logEvent(getAnalytics(), 'view_recipe', {
        content_id: this.id,
        content_title: this.title
      });
    }
  },
  methods: {
    async awaitFunc() {
      const cookingSnapshot = await getDoc(doc(firebase.db, "Cookings2", this.id));
      let cooking = cookingSnapshot.data();
      this.title = cooking.title;
      this.dispTitle = cooking.dispTitle;
      this.tags = cooking.tags ? cooking.tags.split(",").filter(Boolean) : [];
      this.description = cooking.description;
      this.procedure1 = cooking.procedure1;
      this.procedure2 = cooking.procedure2;
      this.procedure3 = cooking.procedure3;
      this.procedure4 = cooking.procedure4;
      this.procedure5 = cooking.procedure5;
      this.procedure6 = cooking.procedure6;
      this.procedure7 = cooking.procedure7;
      this.procedure8 = cooking.procedure8;
      this.procedure9 = cooking.procedure9;
      this.procedure10 = cooking.procedure10;
      this.point = cooking.point;
      this.imgUrls = cooking.imgUrls;
      this.materialData = JSON.parse(cooking.material);
      this.youtubeUrl = cooking.youtubeUrl;
      this.hirogeruValue = cooking.hirogeruValue ? cooking.hirogeruValue : 0;
      this.sebameruValue = cooking.sebameruValue ? cooking.sebameruValue : 0;
      this.zentaiAgeruValue = cooking.zentaiAgeruValue ? cooking.cookingzentaiAgeruValue : 0;
      this.zentaiSageruValue = cooking.zentaiSageruValue ? cooking.zentaiSageruValue : 0;
      this.ingredientDetailHidariValue = cooking.ingredientDetailHidariValue ? cooking.ingredientDetailHidariValue : 0;
      this.ingredientDetailMigiValue = cooking.ingredientDetailMigiValue ? cooking.ingredientDetailMigiValue : 0;
      this.ingredientPartHidariValue = cooking.ingredientPartHidariValue ? cooking.ingredientPartHidariValue : 0;
      this.ingredientPartMigiValue = cooking.ingredientPartMigiValue ? cooking.ingredientPartMigiValue : 0;
      this.preparationDetailHidariValue = cooking.preparationDetailHidariValue ? cooking.preparationDetailHidariValue : 0;
      this.preparationDetailMigiValue = cooking.preparationDetailMigiValue ? cooking.preparationDetailMigiValue : 0;
      this.preparationPartHidariValue = cooking.preparationPartHidariValue ? cooking.preparationPartHidariValue : 0;
      this.preparationPartMigiValue = cooking.preparationPartMigiValue ? cooking.preparationPartMigiValue : 0;
      this.seasoningDetailHidariValue = cooking.seasoningDetailHidariValue ? cooking.seasoningDetailHidariValue : 0;
      this.seasoningDetailMigiValue = cooking.seasoningDetailMigiValue ? cooking.seasoningDetailMigiValue : 0;
      this.seasoningPartHidariValue = cooking.seasoningPartHidariValue ? cooking.seasoningPartHidariValue : 0;
      this.seasoningPartMigiValue = cooking.seasoningPartMigiValue ? cooking.seasoningPartMigiValue : 0;
      this.$nextTick(() => {
        let gyoukanValue = (this.hirogeruValue - this.sebameruValue);
        if (gyoukanValue != 0) {
          // gyoukanValue *= 2;
          let iHeights = document.getElementsByClassName("iHeight");
          const prevHeight = iHeights[0].clientHeight;
          for (let iHeight of iHeights) {
            iHeight.style.height = (prevHeight + gyoukanValue) + "px";
          }
        }
        let zentaiJougeValue = (this.zentaiSageruValue - this.zentaiAgeruValue);
        if (zentaiJougeValue) {
          // zentaiJougeValue *= 2;
          let iHeight = document.getElementsByClassName("ingredientZentai")[0];
          iHeight.style.top = iHeight.style.top ? (parseInt(iHeight.style.top.replace("px","")) - zentaiJougeValue) + "px" : zentaiJougeValue + "px";

          let sHeight = document.getElementsByClassName("seasonZentai")[0];
          sHeight.style.top = sHeight.style.top ? (parseInt(sHeight.style.top.replace("px","")) - zentaiJougeValue) + "px" : zentaiJougeValue + "px";
        }
        let ingredientDetailValue = (this.ingredientDetailMigiValue - this.ingredientDetailHidariValue);
        if (ingredientDetailValue != 0) {
          // ingredientDetailValue *= 2;
          let iWidths = document.getElementsByClassName("ingredientTd");
          const prevWidth = parseInt(iWidths[0].clientWidth) + ingredientDetailValue;
          for (let iWidth of iWidths) {
            iWidth.style.width = prevWidth + "px";
          }
        }
        if (!(window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches)) {
          let ingredientPartValue = (this.ingredientPartMigiValue - this.ingredientPartHidariValue);
          if (ingredientPartValue != 0) {
            // ingredientPartValue *= 2;
            let iHeight = document.getElementsByClassName("ingredientPart")[0];
            iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) + ingredientPartValue) + "px" : ingredientPartValue + "px";
          }
        }
        let preparationDetailValue = (this.preparationDetailMigiValue - this.preparationDetailHidariValue);
        if (preparationDetailValue != 0) {
          // preparationDetailValue *= 2;
          let iWidths = document.getElementsByClassName("preparationTd");
          const prevWidth = parseInt(iWidths[0].clientWidth) + preparationDetailValue;
          for (let iWidth of iWidths) {
            iWidth.style.width = prevWidth + "px";
          }
        }
        if (!(window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches)) {
          let preparationPartValue = (this.preparationPartMigiValue - this.preparationPartHidariValue);
          if (preparationPartValue != 0) {
            // preparationPartValue *= 2;
            let iHeight = document.getElementsByClassName("preparationPart")[0];
            iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) - preparationPartValue) + "px" : preparationPartValue + "px";
          }
        }
        let seasoningDetailValue = (this.seasoningDetailMigiValue - this.seasoningDetailHidariValue);
        if (seasoningDetailValue != 0) {
          // seasoningDetailValue *= 2;
          let iWidths = document.getElementsByClassName("seasoningTd");
          const prevWidth = parseInt(iWidths[0].clientWidth) + seasoningDetailValue;
          for (let iWidth of iWidths) {
            iWidth.style.width = prevWidth + "px";
          }
        }
        if (!(window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches)) {
          let seasoningPartValue = (this.seasoningPartMigiValue - this.seasoningPartHidariValue);
          if (seasoningPartValue != 0) {
            // seasoningPartValue *= 2;
            let iHeight = document.getElementsByClassName("seasoningPart")[0];
            iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) + seasoningPartValue) + "px" : seasoningPartValue + "px";
          }
        }
      });
    },
    toFullWidth(str) {
      // 半角英数字を全角に変換
      str = String(str);
      if (str.indexOf("/") > -1) {
        let strs = str.split("/");
        for (let i = 0; i < strs.length; i++) {
          strs[i] = strs[i].replace(/[A-Za-z0-9]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
          });
        }
        return strs[0] + "/" + strs[1];
      } else {
        return str.replace(/[A-Za-z0-9]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
        });
      }
    },
    calcRentinTime() {
      let times = this.htmlText.match('レンチン.分.秒|レンチン.分..秒');
      if (times && 0 < times.length) {
        times = [...new Set(times)];
        times.forEach((time) => {
          let minutesText = times[0].match('.分')[0].replace('分', '');
          let secondsText = times[0].match('.秒')[0].replace('秒', '');
          let minute = minutesText.replace(/[０-９]/g, m=>'０１２３４５６７８９'.indexOf(m));
          let second = secondsText.replace(/[０-９]/g, m=>'０１２３４５６７８９'.indexOf(m));
          this.htmlText.replaceAll(time, time + this.modifyTimes((minute * 60) + second));
        });

      } else {
        times = this.htmlText.match('レンチン.分(?!.秒|..秒)');
        if (times && 0 < times.length) {
          times = [...new Set(times)];
          times.forEach((time) => {
            let minutesText = times[0].match('.分')[0].replace('分', '');
            let minute = minutesText.replace(/[０-９]/g, m=>'０１２３４５６７８９'.indexOf(m));
            this.htmlText.replaceAll(time, time + this.modifyTimes((minute * 60)));
          });
        }
      }
    },
    modifyTimes(second) {
        const w500Seconds = Math.floor(second * 600 / 500);
        const w700Seconds = Math.floor(second * 600 / 700);
        const w500 = Math.floor(w500Seconds / 60) + '分' + Math.floor(w500Seconds % 60) + '秒';
        const w600 = Math.floor(second / 60) + '分' + Math.floor(second % 60) + '秒';
        const w700 = Math.floor(w700Seconds / 60) + '分' + Math.floor(w700Seconds % 60) + '秒';
        let ret = '';
        ret += '<div class="rentinTimes">';
        ret += '<span>500W : ' + w500 + '</span>';
        ret += '<span>600W : ' + w600 + '</span>';
        ret += '<span>700W : ' + w700 + '</span>';
        ret += '</div>';
        return ret;
    },
    wrapYoutube() {
      if (this.htmlText.indexOf('iframe')) {
        this.htmlText = this.htmlText.replaceAll('<iframe', '<div class="youtube"><iframe');
        this.htmlText = this.htmlText.replaceAll('iframe>', 'iframe></div>');
      }
    },
    addIndent(str) {
      if (str) {
        return str.replace(/\n/g, '\n　 ');
      } else {
        return null;
      }
    },
    autoLink(text) {
      return text ? text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' style='color:blue;' target='_blank'>$1</a>") : '';
    },
    transition(menu) {
      this.$router.push({name: menu});
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../public/css/markdown.css';

@font-face {
  font-family: 'chihaya';
  src: url('../../public/fonts/nchihaya.ttf') format('truetype');
}
.title {
  font-family: 'chihaya', Avenir, Helvetica, Arial, sans-serif;
  margin-left: 16vw;
  text-align: left;
  font-size: 2.2vw;
}
.gingamuCheck {
	margin: 1.5em auto 0.6em;
	padding: 2.8em;/*内側余白*/
	background-image: linear-gradient(90deg, rgba(111, 111, 111, .1) 50%, transparent 50%), linear-gradient(rgba(111, 111, 111,.1) 50%, transparent 50%);/* チェック色 */
	background-size: 15px 15px;	/* チェックのサイズ */
}
.recipeJP {
  font-family: 'chihaya', Avenir, Helvetica, Arial, sans-serif;
  font-size: 1.8vw;
  font-weight: bold;
  color: #a83424;
}
#previewPart {
  width: 50%;
  white-space: pre-wrap;
  font-size: 1.4rem;
  text-align: left;
  font-family: 'chihaya';
  overflow-y: auto;
  margin: 0 auto;
  overflow-x: hidden;
}
.preview {
  padding-left: 2vw;
}
.previewImg {
  margin: 2vw 0;
}
.previewImg img {
  width: 100%;
  height: auto;
}
.how {
  margin-top: 4vw;
  font-size: 2rem;
  margin-bottom: 2vw;
}
.pointWrap {
  margin-top: 4vw;
  position: relative;
}
.pointTitle {
  position: absolute;
  font-size: 2rem;
  top: -15px;
  background-color: rgb(255, 255, 234);
  left: 14px;
  padding: 0 0.4vw;
}
.point {
  margin-top: 1vw;
  border: 5px dotted #a83424;
  padding: 2vw 0 2vw 1vw;
  margin-left: unset !important;
  border-radius: 14px;
}
.indent {
  margin-left: 1vw;
}
.youtubeMovie {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  margin-top: 2vw;
}
ol  {
  margin: 0;
  padding: 0
}
ol li  {
  list-style: none;
  line-height: 1.6rem;
  margin-bottom: 1vw;
  text-indent: -2rem;
  padding-left: 2rem;
}
.tags {
  font-family: chihaya;
  position: relative;
  text-align: left;
  margin: 1vw auto;
  flex-wrap: wrap;
  display: flex;
}
.tag {
  font-size: 1.2rem;
  margin-right: 10px;
  background-color: #fce2e2;
  border-radius: 10px;
  padding: 4px 6px;
}
/* materialPart */
.materialTitle {
  font-size: 4rem;
  line-height: 8rem;
  text-align: center;
  margin: 0;
}
.material {
  width: 99%;
  display: inline-flex;
  position: relative;
  font-size: 1.4rem;
  background: #ededed;
  box-shadow: 0px 0px 0px 5px #ededed;
  border: dashed 2px white;
  padding-bottom: 2vw;
}
.material:after {
  position: absolute;
  content: '';
  right: -7px;
  top: -7px;
  border-width: 0 15px 15px 0;
  border-style: solid;
  border-color: #d9d9d9 #fff #d9d9d9;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15);
}
.title {
  font-size: 2rem;
  line-height: 4rem;
}
.material p {
  margin: 0; 
  padding: 0;
}
.ingredientZentai {
  position: relative;
  top: 0px;
}
.seasonZentai {
  position: relative;
  top: 0px;
}
.ingredient {
  position: relative;
  width: 50%;
}
.ingredientPart {
  position: relative;
  width: 100%;
  top: 0%;
  left: 0%;
  padding-left: 10px;
}
.seasoning {
  position: relative;
  width: 50%;
}
.seasoningPart {
  position: relative;
  width: 100%;
  top: 0%;
  left: 0%;
  padding-left: 10px;
}
.ingredientTable,
.seasoningTable {
  margin-bottom: 0;
  width: 100%;
  padding-left: 10px;
}
.ingredientTd {
  width: 50%;
  padding: 0;
}
.preparationTd {
  width: 50%;
  padding: 0;
}
.seasoningTd {
  width: 50%;
  padding: 0;
}
/* preparationPart */
.preparationPart {
  width: 100%;
  padding-left: 1vw;
}
.kakko {
  position: relative;
  margin: 0.4vw 0 1vw 0;
  padding-left: 50px;
  width: 84%;
}
.shitaaji {
  position: absolute;
  font-size: 0.9em;
  left: 2.5vw;
  z-index: 999;
  top: 3.4vw;
}
.downloadImage {
  position: absolute;
  width: 5vw;
  height: 1.2vw;
  border: none;
  background-color: unset;
  top: 10px;
  right: 30px;
}
.frame-box-002 {
	position: relative;
}
.frame-box-002::before, .frame-box-002::after {
	content: '';
	width: 15px;
	height: 100%;
	position: absolute;
}
.frame-box-002::before {
	border-left: solid 2px #717171;
	border-top: solid 2px #717171;
	border-bottom: solid 2px #717171;
  border-radius: 3px;
	top: 0;
	left: 0;
}
.frame-box-002::after {
	border-right: solid 2px #717171;
	border-top: solid 2px #717171;
	border-bottom: solid 2px #717171;
  border-radius: 3px;
	top: 0;
	right: 0;
}
.iHeight, .sHeight {
  line-height: 1.6rem;
}
.breadcrumbWrap {
  font-family: 'chihaya';
  text-align: left;
  width: 80%;
  margin: 0 auto 2rem;
}
@media screen and (max-width:640px) {
  #previewPart {
    width: 90%;
    font-size: 1.2rem;
  }
  .preview {
     padding-left: unset;
  }
  .material {
    display: grid;
  }
  .ingredient, .seasoning {
    width: 100%;
  }
  .recipeJP {
    font-size: 1.6rem;
    line-height: 1.8rem;
    white-space: pre-wrap;
  }
  .gingamuCheck {
    margin: 0 auto 0.4em;
    padding: 1.8em;
    
  }
  .tag {
    margin-bottom: 10px;
  }
  .youtubeMovie {
    margin-top: 10vw;
  }
  .iHeight, .sHeight {
    line-height: 1.3rem;
    font-size: 1.2rem;
  }
  .ingredientTd {
    white-space: nowrap;
  }
  .preparationTd {
    white-space: nowrap;
  }
  .seasoningTd {
    white-space: nowrap;
  }
  .kakko {
    width: 80%;
  }
  .point {
    padding: 7vw 3vw;
  }
  .pointWrap,
  .how {
    margin-top: 12vw;
  }
  .breadcrumbWrap {
    text-indent: 0.6rem;
    width: 100%;
    margin: 0 auto 2rem;
  }
}
</style>
