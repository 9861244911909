<template>
  <HeaderLine/>
  <Menu/>
  <div class="toasterMenu">
    <div class="gingamuCheck">
      <div class="recipeJP">トースターメニュー</div>
      <div class="recipe">toaster menu</div>
    </div>
      <div id="recipePart">
      <dd v-if="cookings && cookings.length > 0">
        <dl class="cooking" v-for="(item, key) in cookings" :key="key">
          <div @click="displayRecipe(item.id)" class="deployCooking cookImgSum">
            <div class="ranchonBack"></div>
            <img class="cookImg" v-if="item.imgUrls && item.imgUrls.length > 0" :src="item.imgUrls[0]" alt="">
          </div>
          <div @click="displayRecipe(item.id)" class="deployCooking cookSum">
            <div v-if="item.dispTitle" class="cookSumTitle">{{ item.dispTitle }}</div>
            <div v-else class="cookSumTitle">{{ item.title }}</div>
          </div>
        </dl>
      </dd>
      <div v-else>
        準備中…
      </div>
    </div>
  </div>
  <ToTop/>
  <Footer/>
</template>

<script>
import firebase from "../main.js";
import { collection, getDocs } from "firebase/firestore";

import HeaderLine from "@/components/HeaderLine.vue";
import Menu from "@/components/Menu.vue";
import ToTop from "@/components/ToTop.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'ToasterMenu',
  components: {
    HeaderLine,
    Menu,
    ToTop,
    Footer,
  },
  data() {
    return {
      cookings: [],
    }
  },
  mounted() {
    this.awaitFunc();
    document.getElementsByTagName('body')[0].style.backgroundColor = "#ffffea";
  },
  methods: {
    async awaitFunc() {
      this.cookings = [];
      const cookingsQuerySnapshot = await getDocs(collection(firebase.db, "Cookings2"));
      let dataIndex = 0;
      cookingsQuerySnapshot.forEach((docu) => {
        let data = docu.data();
        if (data.recipeCategory == "toaster" && data.isPublic) {
          data.id = docu.id;
          data.index = dataIndex;
          data.createDateFmt = this.dateFormat(data.createDate);
          data.updateDateFmt = this.dateFormat(data.updateDate);
          this.cookings.push(data);
        }
        dataIndex++;
      });
      this.cookings.sort((a, b) => b.createDate - a.createDate);
    },
    dateFormat(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return date.getFullYear() + "." + ("00" + (date.getMonth()+1)).slice(-2) + "." + ("00" + date.getDate()).slice(-2);
    },
    displayRecipe(id) {
      this.$router.push({name: 'ToasterRecipe', query: {id: id}});
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: 'chihaya';
  src: url('../../public/fonts/nchihaya.ttf') format('truetype');
}
.toasterMenu {
  font-family: 'chihaya', Avenir, Helvetica, Arial, sans-serif;
  width: 100%;
}
.gingamuCheck {
  margin: 1.5em auto 2em;
  padding: 2.2em;
  background-image: linear-gradient(90deg, rgba(93, 84, 214, 0.1) 50%, transparent 50%), linear-gradient(rgba(93, 84, 214, 0.1) 50%, transparent 50%);
  background-size: 15px 15px;
}
.recipeJP {
  font-size: 1.8vw;
  font-weight: bold;
  color: #a83424;
}
.recipe {
  font-family: 'chihaya', Avenir, Helvetica, Arial, sans-serif;
  font-size: 1vw;
  color: #a83424;
}
#recipePart {
  width: 60%;
  margin: 0 auto;
  height: auto;
}
.ranchonBack {
  width: 16vw;
  height: 10vw;
  position: absolute;
  top: -7px;
  left: -10px;
  background: linear-gradient(to right, #AAB300, #212114);
  transform: rotate(3deg);
  opacity: 0.5;
}
.cookImg {
  position: absolute;
  width: 100%;
  height: 10vw;
  object-fit: cover;
  z-index: 0;
}
.cookSum {
  width: 15vw;
  margin: 0 auto;
}
.cookImgSum {
  height: 10vw;
}
.cookSumDate {
  font-size: 14px;
  text-align: center;
}
.cookSumTitle {
  font-size: 1.2vw;
  line-height: 2.2vw;
  text-align: center;
  font-weight: bold;
}
.deployCooking {
  cursor: pointer;
}
dd {
  width: 100%;
  display: inline-flex;
  margin-inline-start: 0px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
dl {
  position: relative;
  width: 16vw;
  max-width: 16vw;
  height: 15vw;
  margin: 1vw 1.6vw 0;
  text-align: left;
}
@media screen and (max-width:640px) {
  .recipeJP {
    font-size: 2rem;
  }
  .recipe {
    font-size: 1.6rem;
  }
  .gingamuCheck {
    margin: 0 auto 2em;
    padding: 1em;/*内側余白*/
  }
  #recipePart {
    width: 95%;
    margin-bottom: 2rem;
  }
  dd {
    display: grid;
  }
  dl {
    width: unset;
    max-width: 100%;
    height: 11rem;
    margin: 0 auto 3rem;
    height: unset;
  }
  .cookImg {
    display: grid;
    position: relative;
    width: 80%;
    height: auto;
    margin: 0 auto 2vw;
    align-items: center;
  }
  .cookSum {
    width: 100%;
    margin: 0 auto;
  }
  .cookImgSum {
    height: unset;
  }
  .ranchonBack {
    width: 80%;
    height: 42vw;
    left: 27px;
  }
  .cookSumTitle {
    font-size: 1.2rem;
    line-height: 1.4rem;
    word-break: keep-all;
  }
}
</style>