<template>
  <RouterView/>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      adminMode: false,
      inputCommand: [],
      adminCommand: [72,65,84,73,75,79]
    }
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown)
  },
  methods: {
    onKeyDown(event) {
      if (event.keyCode == 27) {
        this.inputCommand = [];
      } else {
        this.inputCommand.push(event.keyCode);
        if (JSON.stringify(this.inputCommand) == JSON.stringify(this.adminCommand)) {
          this.inputCommand = [];
          this.$router.push({name: "Post"});
        }
      }
    }
  }
}
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: rgb(255, 255, 234);
}
/* markdown */
.markdown-body img {
  height: 17vw;
}
.markdown-body p {
  line-height: 2rem;
}
.markdown-body li+li {
    margin-top: 0;
    line-height: 3rem;
}
.v-show-content {
  background-color: unset !important;
}
::-webkit-scrollbar{
  width: 10px;
}
::-webkit-scrollbar-track{
  background-color: #ccc;
}
::-webkit-scrollbar-thumb{
  background-color: rgb(245 225 171);
  border-radius: 20px;
}
@media screen and (max-width:640px) {
  .markdown-body img {
    height: auto;
  }
}
</style>
