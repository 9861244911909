<template>
  <HeaderLine/>
  <Menu/>
  <div class="gingamuCheck">
    <div class="recipeJP">はっちゃん</div>
    <div class="recipe">hatchan</div>
  </div>
  <div class="about">
    <div class="image">
      <img src="../assets/images/rentinkun_migimuki.png" alt="">
    </div>
    <div class="description">
      はじめまして。<br>
      レンチン食堂です。<br>
      ご飯を作っている、全ての人お疲れ様です！<br>
      毎日毎食、ご飯を作るのって、本当に大変ですよね。<br>
      私もワーママとして、長く嫌々料理をしてきました。<br>
      だけど、どうせなら楽して美味しく作れないかな？<br>
      という気持ちが芽生え試行錯誤しながら、<br>
      毎日のお料理を続けてきました。<br>
      Youtubeチャンネルでは、そんな私が日々作っている料理を<br>
      娘や、お料理しているみんなにシェアするつもりで投稿しています。<br>
      <a href="https://www.youtube.com/channel/UC0hWaNJCTPDzalprencBYjQ">Youtubeページ</a><br>
      Instagramにもこんなに簡単だよ！とお伝えできれば、<br>
      という気持ちでレシピを紹介しています。<br>
      <a href="https://www.instagram.com/rentin_syokudou/?hl=ja">Instagramページ</a><br>
      毎日クタクタで、お惣菜やお弁当に頼りたくなるけど<br>
      レンチン料理で楽にしっかりご飯してみませんか？<br>
      Youtubeチャンネルとインスタでお待ちしていますね♪
    </div>
  </div>
  <div class="mov">
    <video controls>
      <source src="../assets/movie/hacchan.mp4" type="video/mp4" />
    </video>
  </div>
  <ToTop/>
  <Footer/>
</template>

<script>
import HeaderLine from "@/components/HeaderLine.vue";
import Menu from "@/components/Menu.vue";
import ToTop from "@/components/ToTop.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'About',
  components: {
    HeaderLine,
    Menu,
    ToTop,
    Footer,
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: 'chihaya';
  src: url('../../public/fonts/nchihaya.ttf') format('truetype');
}
.about {
  font-family: 'chihaya';
  display: inline-flex;
  margin: 3vw 0 2vw;
}
.image {
  width: 9vw;
  height: 9vw;
  background-color: antiquewhite;
  border-radius: 50%;
  position: relative;
  margin-right: 5vw;
}
.image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 9vw;
  height: auto;
}
.description {
  text-align: left;
  font-size: 17px;
  line-height: 50px;
}
.gingamuCheck {
	margin: 1.5em auto 2em;
	padding: 2.2em;/*内側余白*/
	background-image: linear-gradient(90deg, rgba(247, 50, 50, 0.1) 50%, transparent 50%), linear-gradient(rgba(247, 50, 50, 0.1) 50%, transparent 50%);/* チェック色 */
	background-size: 15px 15px;	/* チェックのサイズ */
}
.recipeJP {
  font-family: 'chihaya', Avenir, Helvetica, Arial, sans-serif;
  font-size: 1.8vw;
  font-weight: bold;
  color: #a83424;
}
.recipe {
  font-family: 'chihaya', Avenir, Helvetica, Arial, sans-serif;
  font-size: 1vw;
  color: #a83424;
}
.mov {
  width: 450px;
  margin: 0 auto;
  margin-bottom: 6vw;
}
video {
  width: 100%;
}
@media screen and (max-width:640px) {
  .about {
    display: grid;
  }
  .image {
    width: 8rem;
    height: 8rem;
    margin: 0 auto;
  }
  .image img {
    width: 9rem;
  }
  .recipeJP {
    font-size: 2rem;
  }
  .recipe {
    font-size: 1.6rem;
  }
  .gingamuCheck {
    margin: 0 auto 2em;
    padding: 1em;
  }
  .description {
    margin: 2rem auto;
    width: 80%;
    font-size: 1.2rem;
    line-height: 2.6rem;
  }
  .mov {
    width: 90%;
    margin-bottom: 17vw;
  }
}
</style>
