<template>
  <div class="blogPost">
    <div id="inputPart">
      <section>
        <label for="title">タイトル</label>
        <input type="text" id="title" v-model="idInput">
      </section>
      <section>
        <label for="summary">本文</label>
        <textarea name="summary" id="summary" cols="30" rows="10"></textarea>
      </section>
      <section>
        <label for="post"></label>
        <button>登録</button>
      </section>
    </div>
    <div id="historyPart">
aaaa
    </div>
  </div>
</template>

<script>

export default {
  name: 'BlogPost',
  components: {
  },
  data() {
    return {
      titleInput: null,
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: 'chihaya';
  src: url('../../public/fonts/nchihaya.ttf') format('truetype');
}
.blogPost {
  font-family: 'chihaya';
  display: inline-flex;
  width: 100%;
  font-size: 1vw;
}
#inputPart {
  display: grid;
  width: 70%;
}
#historyPart {
  width: 30%;
}
label {
  width: 20vw;
  text-align: right;
  display: block;
}
textarea {
  height: 6vw;
}
@media screen and (max-width:640px) {
}
</style>
