<template>
  <div class="post">
    <div id="message" class="message">
      <p>{{ message }}</p>
    </div>
    <div class="tabMenuWrap">
      <div class="tabMenu" :class="showMeta.cls" @click="showClick('meta')">商品情報</div>
      <div class="tabMenu" :class="showInput.cls" @click="showClick('input')">入力</div>
      <div class="tabMenu" :class="showPreview.cls" @click="showClick('preview')">確認</div>
    </div>
    <button v-if="isHistory == false" @click="dispHistory" class="menu">
      <img src="../assets/icons/menu.svg" alt="">
    </button>
    <button v-if="isHistory == true" @click="dispHistory" class="close">
      <img src="../assets/icons/close.svg" alt="">
    </button>

    <div class="materialDialogBG" v-if="materialDataDialogStatus" @click="openMaterialDialog"></div>
    <MaterialDialog v-if="materialDataDialogStatus" :materialData="materialData" @dialogClose="closeMaterialDialog($event)"/>
    <div class="configDialogBG" v-if="configDialogStatus" @click="openConfigDialog"></div>
    <ConfigDialog v-if="configDialogStatus" @dialogClose="closeConfigDialog"/>

    <div id="editPart" v-if="isMobile == false || isHistory == false">
      <div class="metaPartWrap"  v-if="showMeta.show">

        <section class="junbiSectionMobile" v-if="isMobile == true">
          <label>【準備】</label>
          <div class="metaPart">
            <label for="idInput">【ID】</label>
            <input type="text" id="idInput" v-model="idInput" disabled>
          </div>
          <div class="metaPart">
            <label for="titleInput">【タイトル】</label>
            <input type="text" id="titleInput" @change="changeText('title', $event)" v-model="titleInput">
          </div>
          <div class="metaPart">
            <label for="tagInput">【タグ】</label>
            <input type="text" id="tagInput" @change="changeText('tags', $event)" v-model="tagInput" placeholder="複数指定は「,」で入力">
          </div>
          <div class="material-youtube">
            <input type="text" id="youtubeUrl" @change="changeText('youtubeUrl', $event)" v-model="youtubeUrl" placeholder="youtube url">
          </div>
          <button class="imgButton" v-if="!imgUrls || imgUrls.length == 0" @click="$refs.fileInput.click()">写真選択</button>
          <div class="dndImg"
            @dragenter="dragEnter"
            @dragleave="dragLeave"
            @dragover.prevent
            @drop.prevent="dropFile"
            :class="{enter: isDragEnter}">
            <span class="dndImgEmptyMsg" v-if="!imgUrls || imgUrls.length == 0">Image Drag and Drop Area</span>
            <input type="file" ref="fileInput" @change="dropFile" style="display: none;">
            <div class="imgContents" v-if="imgUrls && imgUrls.length > 0">
              <div class="imgContent" v-for="(item, index) in imgUrls" :key="index">
                <img class="deleteImg" @click="deleteImg(index)" src="../assets/icons/delete.svg" alt="">
                <img class="imgContentImg" @click="imgUrlCopy(item)" :src="item" alt="">
              </div>
            </div>
          </div>
        </section>
      </div>

      <div id="mdInput">
        <div id="inputPart" v-if="showInput.show">
          <section class="junbiSection" v-if="isMobile == false">
            <label for="idInput">【ID】</label>
            <input id="idInput" v-model="idInput" disabled>
            <label for="titleInput">【タイトル】</label>
            <textarea id="titleInput" v-model="titleInput" @change="changeText('title', $event)"></textarea>
            <label for="dispTitleInput">【メニュー表示名】</label>
            <textarea id="dispTitleInput" v-model="dispTitleInput" @change="changeText('dispTitle', $event)"></textarea>
            <label for="tagInput">【タグ】</label>
            <textarea id="tagInput" v-model="tagInput" @change="changeText('tags', $event)" placeholder="複数指定は「,」で入力"></textarea>
            <div class="tagHistory" v-if="showMeta.show">
              <div v-for="(item, key) in tagHistory" :key="key">
                <div class="tagHistoryPart" @click="addTag(item.tag)">
                  {{ item.tag }}
                </div>
              </div>
            </div>
            <label>【準備】</label>
            <div class="material-youtube">
              <button class="buttonMaterial" @click="openMaterialDialog">材料</button>
              <div class="recipeCategory" v-if="showMeta.show">
                <input type="radio" v-model="recipeCategory" @change="changeText('recipeCategory', $event)" id="rentin" value="rentin">
                <label for="rentin">
                  <img src="../assets/images/rentinkun_migimuki.png" alt="">
                </label>
                <input type="radio" v-model="recipeCategory" @change="changeText('recipeCategory', $event)" id="toaster" value="toaster">
                <label for="toaster">
                  <img src="../assets/images/toaster_color.png" alt="">
                </label>
              </div>
              <input type="text" id="youtubeUrl" @change="changeText('youtubeUrl', $event)" v-model="youtubeUrl" placeholder="youtube url">
            </div>
            <div class="dndImg"
              @dragenter="dragEnter"
              @dragleave="dragLeave"
              @dragover.prevent
              @drop.prevent="dropFile"
              :class="{enter: isDragEnter}">
              <span class="dndImgEmptyMsg" v-if="!imgUrls || imgUrls.length == 0">Image Drag and Drop Area</span>
              <input type="file" ref="fileInput" @change="dropFile" style="display: none;">
              <button class="imgButton" v-if="!imgUrls || imgUrls.length == 0" @click="$refs.fileInput.click()">選択</button>
              <div class="imgContents" v-if="imgUrls && imgUrls.length > 0">
                <div class="imgContent" v-for="(item, index) in imgUrls" :key="index">
                  <img class="deleteImg" @click="deleteImg(index)" src="../assets/icons/delete.svg" alt="">
                  <img class="imgContentImg" @click="imgUrlCopy(item)" :src="item" alt="">
                </div>
              </div>
            </div>
          </section>


          <div v-if="youtubeUrl && isMobile == true">
            <iframe class="youtubeMovie" width="1273" height="695" :src="'https://www.youtube.com/embed/' + youtubeUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <section class="procedureSection" v-if="showInput.show">
            <label for="description">【冒頭説明】</label>
            <textarea id="description" v-model="description" @change="changeText('description', $event)"></textarea>

            <label for="procedure1">【手順1】</label>
            <textarea id="procedure1" v-model="procedure1" @change="changeText('procedure1', $event)"></textarea>
            
            <label for="procedure2">【手順2】</label>
            <textarea id="procedure2" v-model="procedure2" @change="changeText('procedure2', $event)"></textarea>

            <label for="procedure3">【手順3】</label>
            <textarea id="procedure3" v-model="procedure3" @change="changeText('procedure3', $event)"></textarea>

            <label for="procedure4">【手順4】</label>
            <textarea id="procedure4" v-model="procedure4" @change="changeText('procedure4', $event)"></textarea>

            <label for="procedure5">【手順5】</label>
            <textarea id="procedure5" v-model="procedure5" @change="changeText('procedure5', $event)"></textarea>

            <label for="procedure6">【手順6】</label>
            <textarea id="procedure6" v-model="procedure6" @change="changeText('procedure6', $event)"></textarea>

            <label for="procedure7">【手順7】</label>
            <textarea id="procedure7" v-model="procedure7" @change="changeText('procedure7', $event)"></textarea>

            <label for="procedure8">【手順8】</label>
            <textarea id="procedure8" v-model="procedure8" @change="changeText('procedure8', $event)"></textarea>

            <label for="procedure9">【手順9】</label>
            <textarea id="procedure9" v-model="procedure9" @change="changeText('procedure9', $event)"></textarea>

            <label for="procedure10">【手順10】</label>
            <textarea id="procedure10" v-model="procedure10" @change="changeText('procedure10', $event)"></textarea>

            <label for="point">【Point】</label>
            <textarea id="point" v-model="point" @change="changeText('point', $event)"></textarea>

          </section>
        </div>
        <div id="previewPart" v-if="showPreview.show">
          <div class="preview">
            <div class="tags" v-if="tags && tags.length > 0">
              <span class="tag" v-for="(value, key) in tags" :key="key">{{ value }}</span>
            </div>
            <div class="previewImg" v-if="imgUrls.length > 0">
              <img :src="imgUrls[0]" alt="">
            </div>
            <div>{{ description }}</div>
            <div class="materialPart" v-if="materialData">
              <p class="how">材料（{{ toFullWidth(materialData.numOfPeople) }}分）</p>
              <button class="zairyoButton" @click="redirectZairyo" v-if="materialData">材料サムネイル</button>
              <div class="material">
                <div v-if="materialData.ingredients && materialData.ingredients.length > 0" class="ingredient">
                  <div class="ingredientPart">
                    <p class="title">【材料】</p>
                    <div class="ingredientZentai">
                      <div v-for="(ingredient, key) in materialData.ingredients" :key="key">
                        <table class="iHeight ingredientTable">
                          <tbody>
                            <tr>
                              <td class="ingredientTd">・{{ ingredient.name }}</td>
                              <td>{{ toFullWidth(ingredient.weight1 ? ingredient.weight1 : "") }}{{ toFullWidth(ingredient.weight2 ? ingredient.weight2 : "") }}</td>
                            </tr>
                          </tbody>
                        </table>
                        <div v-if="ingredient.preparation && ingredient.preparation.length > 0" class="kakko">
                          <table class="preparationPart frame-box-002">
                            <tbody>
                              <tr v-for="(preparation, key) in ingredient.preparation" :key="key" class="iHeight">
                                <td class="preparationTd">{{ preparation.name }}</td>
                                <td>{{ toFullWidth(preparation.weight1 ? preparation.weight1 : "") }}{{ toFullWidth(preparation.weight2 ? preparation.weight2 : "") }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="materialData.seasonings && materialData.seasonings.length > 0" class="seasoning">
                  <div class="seasoningPart">
                    <p class="title">【調味料】</p>
                    <div class="seasonZentai">
                      <div v-for="(seasoning, key) in materialData.seasonings" :key="key">
                        <table class="iHeight seasoningTable">
                          <tbody>
                            <tr>
                              <td class="seasoningTd"><span v-if="!seasoning.tenCheck || seasoning.tenCheck == false">・</span><span v-else>&emsp;</span>{{ seasoning.group ? seasoning.group : "" }}{{ seasoning.name }}</td>
                              <td>{{ toFullWidth(seasoning.weight1 ? seasoning.weight1 : "") }}{{ toFullWidth(seasoning.weight2 ? seasoning.weight2 : "") }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="procedure1" class="how">作り方</div>
            <ol>
              <li class="indent" v-if="procedure1">&#9312; {{ procedure1 }}</li>
              <li class="indent" v-if="procedure2">&#9313; {{ procedure2 }}</li>
              <li class="indent" v-if="procedure3">&#9314; {{ procedure3 }}</li>
              <li class="indent" v-if="procedure4">&#9315; {{ procedure4 }}</li>
              <li class="indent" v-if="procedure5">&#9316; {{ procedure5 }}</li>
              <li class="indent" v-if="procedure6">&#9317; {{ procedure6 }}</li>
              <li class="indent" v-if="procedure7">&#9318; {{ procedure7 }}</li>
              <li class="indent" v-if="procedure8">&#9319; {{ procedure8 }}</li>
              <li class="indent" v-if="procedure9">&#9320; {{ procedure9 }}</li>
              <li class="indent" v-if="procedure10">&#9321; {{ procedure10 }}</li>
            </ol>
            <div v-if="point" class="pointWrap">
              <div class="pointTitle">Point!!</div>
              <div class="point indent"><span v-html=(point)></span></div>
            </div>
            <div v-if="youtubeUrl">
              <iframe class="youtubeMovie" width="1273" height="695" :src="'https://www.youtube.com/embed/' + youtubeUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div id="historyPart" v-if="isHistory == true">
      <div class="historyHeader">
        <div class="historyTitle">過去の登録</div>
        <button class="buttonNew" @click="clearFunc">
          <img class="new" src="../assets/icons/new.svg" alt="">
        </button>
        <button v-if="isMobile == false" class="buttonConfig" @click="openConfigDialog">
          <img class="config" src="../assets/icons/config.svg" alt="">
        </button>
      </div>
      <div class="search">
        <input type="text" class="searchHistoryInput" @input="searchHistory" placeholder="検索">
        <div class="searchBtns">
          <div class="filterCategoryWrap">
            <input type="radio" v-model="filterCategory" @change="filterCategoryExec('all')" id="allSearch" value="allSearch">
            <label for="allSearch">すべて</label>
            <input type="radio" v-model="filterCategory" class="filterRadio" @change="filterCategoryExec('rentin')" id="rentinSearch" value="rentinSearch">
            <label for="rentinSearch">レンチン</label>
            <input type="radio" v-model="filterCategory" class="filterRadio" @change="filterCategoryExec('toaster')" id="toasterSearch" value="toasterSearch">
            <label for="toasterSearch">トースター</label>
          </div>
          <button class="searchBtn" @click="sortCookings(0, $event)">昇順</button>
          <button class="searchBtn" @click="sortCookings(1, $event)">降順</button>
          <button class="searchBtn" @click="sortCookings(2, $event)">登録日順</button>
          <button class="searchBtn searchBtnActive" @click="sortCookings(3, $event)">更新日順</button>
        </div>
      </div>
      <dd>
        <dl class="cooking" v-for="(item, key) in dispCookings" :key="key" :class="[item.recipeCategory + 'BG', item.selectDL]">
          <div class="cookImg">
            <img class="cookImgImg" v-if="item.imgUrls && item.imgUrls.length > 0" :src="item.imgUrls[0]" alt="">
            <img class="cookImgImg nophoto" v-if="!item.imgUrls || item.imgUrls.length == 0" src="../assets/icons/nophoto.svg" alt="">
          </div>
          <div class="historyRecipeCategory">
            <img v-if="item.recipeCategory == 'rentin'" src="../assets/images/rentinkun_migimuki.png" alt="">
            <img v-else src="../assets/images/toaster_color.png" alt="">
          </div>
          <div class="cookSum">
            <div v-if="item.dispTitle" class="cookSumTitle">{{ item.dispTitle }}</div>
            <div v-else-if="item.title" class="cookSumTitle">{{ item.title }}</div>
            <div v-else class="cookSumTitle">【無題】</div>
            <div class="cookSumDateWrap">
              <div class="cookSumDate">
                <img src="../assets/icons/save.svg" alt="">
                {{ item.createDateFmt }}
              </div>
              <div class="cookSumDate">
                <img src="../assets/icons/update.svg" alt="">
                {{ item.updateDateFmt }}
              </div>
            </div>
          </div>
          <div class="cookIconArea">
            <div class="cookIconWrap deployIcon" @click="deployCooking(item.id)">
              <img class="cookIcon" src="../assets/icons/edit.svg" alt="">
            </div>
            <div class="cookIconWrap deleteIcon" @click="deleteCooking(item.id, item.index)">
              <img class="cookIcon" src="../assets/icons/trash.svg" alt="">
            </div>
            <div class="cookIconWrap copyIcon" @click="copyCooking(item.id, item.index)">
              <img class="cookIcon" src="../assets/icons/copy.svg" alt="">
            </div>
            <div v-if="item.isPublic == true" class="cookIconWrap publicIcon" @click="privateCooking(item.id);">
              <img class="cookIcon" src="../assets/icons/public.svg" alt="">
            </div>
            <div v-if="item.isPublic == false" id="privateIcon" class="cookIconWrap privateIcon" @click="publicCooking(item.id);">
              <img class="cookIcon trashIcon" src="../assets/icons/private.svg" alt="">
            </div>
          </div>
        </dl>
      </dd>
    </div>
  </div>
</template>

<script>
import 'mavon-editor/dist/css/index.css';

import firebase from "../main.js";
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore";
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

import MaterialDialog from '../components/MaterialDialog.vue';
import ConfigDialog from '../components/ConfigDialog.vue';

export default {
  name: 'Post',
  components: {
    MaterialDialog,
    ConfigDialog,
  },
  data() {
    return {
      cookings: [
        { isPublic: false }
      ],
      dispCookings: [],

      message: null,

      idInput: "",
      titleInput: "",
      dispTitleInput: "",
      tagInput: null,
      tags: null,

      recipeCategory: "rentin",

      index: 0,
      id: null,
      createDate: null,

      imgFullPaths: [],
      imgUrls: [],

      isDragEnter: false,

      tagHistory: [],

      materialDataDialogStatus: false,
      materialData: null,
      originMaterialJson: null,

      configDialogStatus: false,

      uuid: null,

      description: null,
      procedure1: null,
      procedure2: null,
      procedure3: null,
      procedure4: null,
      procedure5: null,
      procedure6: null,
      procedure7: null,
      procedure8: null,
      procedure9: null,
      procedure10: null,
      point: null,
      youtubeUrl: null,
      hirogeruValue: 0,
      sebameruValue: 0,
      zentaiAgeruValue: 0,
      zentaiSageruValue: 0,
      ingredientDetailHidariValue: 0,
      ingredientDetailMigiValue: 0,
      ingredientPartHidariValue: 0,
      ingredientPartMigiValue: 0,
      preparationDetailHidariValue: 0,
      preparationDetailMigiValue: 0,
      preparationPartHidariValue: 0,
      preparationPartMigiValue: 0,
      seasoningDetailHidariValue: 0,
      seasoningDetailMigiValue: 0,
      seasoningPartHidariValue: 0,
      seasoningPartMigiValue: 0,

      showMeta: {
        show: true,
        cls: ''
      },
      showInput: {
        show: true,
        cls: ''
      },
      showPreview: {
        show: true,
        cls: ''
      },

      isMobile: false,
      isHistory: true,

      filterCategory: 'allSearch'
    }
  },
  mounted() {
    this.awaitFunc();
    this.uuid = this.getUniqueStr();
    document.getElementsByTagName('body')[0].style.backgroundColor = "#ffffea";

    if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
      this.showMeta.show = true;
      this.showMeta.cls = "tabMenu-select";
      this.showInput.show = false;
      this.showPreview.show = false;
      this.isMobile = true;
      this.isHistory = false;

    } else {
      this.showMeta.show = true;
      this.showInput.show = true;
      this.showPreview.show = true;

      this.isMobile = false;
      this.isHistory = true;

      this.$nextTick(() => {
        this.calculateWindowWidth();
        window.addEventListener('resize', this.calculateWindowWidth);
      });
    }
  },
  methods: {
    calculateWindowWidth() {
      document.getElementById("historyPart").style.height = window.innerHeight + "px";
      document.getElementById("inputPart").style.height = window.innerHeight - 40 + "px";
      document.getElementById("previewPart").style.height = window.innerHeight - 40 + "px";
    },
    clearFunc() {
      this.idInput = null;
      this.titleInput = null;
      this.dispTitleInput = null;
      this.tagInput = null;
      this.tags = null;
      this.recipeCategory = "rentin";
      this.id = null;
      this.imgFullPaths = [];
      this.imgUrls = [];
      this.materialData = null; 
      this.createDate = null;
      this.description = null;
      this.procedure1 = null;
      this.procedure2 = null;
      this.procedure3 = null;
      this.procedure4 = null;
      this.procedure5 = null;
      this.procedure6 = null;
      this.procedure7 = null;
      this.procedure8 = null;
      this.procedure9 = null;
      this.procedure10 = null;
      this.point = null;
      this.youtubeUrl = null;

      for (let cook of this.dispCookings) {
        cook.selectDL = "";
      }
    },
    async awaitFunc() {
      this.cookings = [];
      const cookingsQuerySnapshot = await getDocs(collection(firebase.db, "Cookings2"));
      let dataIndex = 0;
      cookingsQuerySnapshot.forEach((docu) => {
        let data = docu.data();
        data.id = docu.id;
        data.idInput = data.recipeCategory == "rentin" ? "https://rentinsyokudo.com/recipe?id=" + data.id : "https://rentinsyokudo.com/trecipe?id=" + data.id;
        data.index = dataIndex;
        data.createDateFmt = this.dateFormat(data.createDate);
        data.updateDateFmt = this.dateFormat(data.updateDate);
        this.cookings.push(data);
        data.selectDL = "";
        
        dataIndex++;
      });
      this.dispCookings =  JSON.parse(JSON.stringify(this.cookings));
      this.dispCookings.sort((a, b) => {
        return a.updateDate.seconds < b.updateDate.seconds ? 1 : -1;
      });
      this.tagSnapshot();
    },
    async tagSnapshot() {
      this.tagHistory = [];
      const tagsQuerySnapshot = await getDocs(collection(firebase.db, "Tags"));
      tagsQuerySnapshot.forEach((tag) => {
        this.tagHistory.push(tag.data());
      })
      this.tagHistory.sort((a, b) => {
        return a.index < b.index ? -1 : 1;
      });
    },
    dateFormat(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return date.getFullYear() + "." + ("00" + (date.getMonth()+1)).slice(-2) + "." + ("00" + date.getDate()).slice(-2) + " " + ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2) + ":" + ("00" + date.getSeconds()).slice(-2);
    },
    dispMessage(msg) {
      this.message = msg;
      const messageElement = document.getElementById("message");
      messageElement.style.opacity = 1;
      messageElement.style.display = "grid";
      setTimeout(() => {
        messageElement.style.opacity = 0;
        messageElement.style.display = "none";
      }, 1000);
    },
    dragEnter() {
      this.isDragEnter = true;
    },
    dragLeave() {
      this.isDragEnter = false;
    },
    dropFile(event) {
      this.isDragEnter = false;
      let file = null;
      if (event.dataTransfer) {
        file = event.dataTransfer.files[0];
      } else {
        file = event.target.files[0];
      }

      if (!file) {
        return;
      }

      const storage = getStorage();
      const storageRef = ref(storage, "/" + file.name);
      uploadBytes(storageRef, file)
      .then((snapshot) => {
        this.imgFullPaths.push(snapshot.metadata.fullPath);
        getDownloadURL(ref(storage, snapshot.metadata.fullPath))
        .then((url) => {
          console.log(url);
          this.imgUrls.push(url);
          this.changeText("image", null);
        })
        .catch((error) => {
          console.log(error);
        });
      });
    },
    imgUrlCopy(url) {
      navigator.clipboard.writeText(url)
      this.dispMessage("コピーしたー");
    },
    deleteImg(index) {
      const targetImgFullPath = this.imgFullPaths[index];
      if (targetImgFullPath) {
        const storage = getStorage();
        deleteObject(ref(storage, targetImgFullPath)).then(() => {
          this.imgUrls.splice(index, 1);
          this.imgFullPaths.splice(index, 1);
          if (this.id) {
            updateDoc(doc(firebase.db, "Cookings2", this.id), { imgUrls: this.imgUrls, imgFullPaths: this.imgFullPaths });
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    addTag(tag) {
      if (!this.tagInput) {
        this.tagInput = tag;
      } else {
        this.tagInput = this.tagInput + "," + tag;
      }
      this.changeText("tags", null);
    },
    deployCooking(id) {
      const cooking = this.cookings.find((cooking) => cooking.id == id);
      this.idInput = cooking.idInput;
      this.titleInput = cooking.title;
      this.dispTitleInput = cooking.dispTitle;
      this.tagInput = cooking.tags;
      this.tags = cooking.tags != null ? cooking.tags.split(",") : null;
      this.recipeCategory = cooking.recipeCategory;
      this.id = id;
      this.index = cooking.dataIndex;
      this.imgFullPaths = cooking.imgFullPaths;
      this.imgUrls = cooking.imgUrls;
      this.createDate = cooking.createDate;
      this.materialData = cooking.material != null ? JSON.parse(cooking.material) : null;
      this.originMaterialJson = cooking.material != null ? JSON.stringify(JSON.parse(cooking.material)) : null;
      this.description = cooking.description != null ? cooking.description : null;
      this.procedure1 = cooking.procedure1 != null ? cooking.procedure1 : null;
      this.procedure2 = cooking.procedure2 != null ? cooking.procedure2 : null;
      this.procedure3 = cooking.procedure3 != null ? cooking.procedure3 : null;
      this.procedure4 = cooking.procedure4 != null ? cooking.procedure4 : null;
      this.procedure5 = cooking.procedure5 != null ? cooking.procedure5 : null;
      this.procedure6 = cooking.procedure6 != null ? cooking.procedure6 : null;
      this.procedure7 = cooking.procedure7 != null ? cooking.procedure7 : null;
      this.procedure8 = cooking.procedure8 != null ? cooking.procedure8 : null;
      this.procedure9 = cooking.procedure9 != null ? cooking.procedure9 : null;
      this.procedure10 = cooking.procedure10 != null ? cooking.procedure10 : null;
      this.point = cooking.point != null ? cooking.point : null;
      this.youtubeUrl = cooking.youtubeUrl != null ? cooking.youtubeUrl : null;
      this.hirogeruValue = cooking.hirogeruValue ? cooking.hirogeruValue : 0;
      this.sebameruValue = cooking.sebameruValue ? cooking.sebameruValue : 0;
      this.zentaiAgeruValue = cooking.zentaiAgeruValue ? cooking.cookingzentaiAgeruValue : 0;
      this.zentaiSageruValue = cooking.zentaiSageruValue ? cooking.zentaiSageruValue : 0;
      this.ingredientDetailHidariValue = cooking.ingredientDetailHidariValue ? cooking.ingredientDetailHidariValue : 0;
      this.ingredientDetailMigiValue = cooking.ingredientDetailMigiValue ? cooking.ingredientDetailMigiValue : 0;
      this.ingredientPartHidariValue = cooking.ingredientPartHidariValue ? cooking.ingredientPartHidariValue : 0;
      this.ingredientPartMigiValue = cooking.ingredientPartMigiValue ? cooking.ingredientPartMigiValue : 0;
      this.preparationDetailHidariValue = cooking.preparationDetailHidariValue ? cooking.preparationDetailHidariValue : 0;
      this.preparationDetailMigiValue = cooking.preparationDetailMigiValue ? cooking.preparationDetailMigiValue : 0;
      this.preparationPartHidariValue = cooking.preparationPartHidariValue ? cooking.preparationPartHidariValue : 0;
      this.preparationPartMigiValue = cooking.preparationPartMigiValue ? cooking.preparationPartMigiValue : 0;
      this.seasoningDetailHidariValue = cooking.seasoningDetailHidariValue ? cooking.seasoningDetailHidariValue : 0;
      this.seasoningDetailMigiValue = cooking.seasoningDetailMigiValue ? cooking.seasoningDetailMigiValue : 0;
      this.seasoningPartHidariValue = cooking.seasoningPartHidariValue ? cooking.seasoningPartHidariValue : 0;
      this.seasoningPartMigiValue = cooking.seasoningPartMigiValue ? cooking.seasoningPartMigiValue : 0;
      this.$nextTick(() => {
        let gyoukanValue = (this.hirogeruValue - this.sebameruValue);
        if (gyoukanValue != 0) {
          // gyoukanValue *= 2;
          let iHeights = document.getElementsByClassName("iHeight");
          const prevHeight = iHeights[0].clientHeight;
          for (let iHeight of iHeights) {
            iHeight.style.height = (prevHeight + gyoukanValue) + "px";
          }
        }
        let zentaiJougeValue = (this.zentaiSageruValue - this.zentaiAgeruValue);
        if (zentaiJougeValue) {
          // zentaiJougeValue *= 2;
          let iHeight = document.getElementsByClassName("ingredientZentai")[0];
          iHeight.style.top = iHeight.style.top ? (parseInt(iHeight.style.top.replace("px","")) - zentaiJougeValue) + "px" : zentaiJougeValue + "px";

          let sHeight = document.getElementsByClassName("seasonZentai")[0];
          sHeight.style.top = sHeight.style.top ? (parseInt(sHeight.style.top.replace("px","")) - zentaiJougeValue) + "px" : zentaiJougeValue + "px";
        }
        let ingredientDetailValue = (this.ingredientDetailMigiValue - this.ingredientDetailHidariValue);
        if (ingredientDetailValue != 0) {
          // ingredientDetailValue *= 2;
          let iWidths = document.getElementsByClassName("ingredientTd");
          const prevWidth = parseInt(iWidths[0].clientWidth) + ingredientDetailValue;
          for (let iWidth of iWidths) {
            iWidth.style.width = prevWidth + "px";
          }
        }
        let ingredientPartValue = (this.ingredientPartMigiValue - this.ingredientPartHidariValue);
        if (ingredientPartValue != 0) {
          // ingredientPartValue *= 2;
          let iHeight = document.getElementsByClassName("ingredientPart")[0];
          iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) + ingredientPartValue) + "px" : ingredientPartValue + "px";
        }
        let preparationDetailValue = (this.preparationDetailMigiValue - this.preparationDetailHidariValue);
        if (preparationDetailValue != 0) {
          // preparationDetailValue *= 2;
          let iWidths = document.getElementsByClassName("preparationTd");
          const prevWidth = parseInt(iWidths[0].clientWidth) + preparationDetailValue;
          for (let iWidth of iWidths) {
            iWidth.style.width = prevWidth + "px";
          }
        }
        let preparationPartValue = (this.preparationPartMigiValue - this.preparationPartHidariValue);
        if (preparationPartValue != 0) {
          // preparationPartValue *= 2;
          let iHeight = document.getElementsByClassName("preparationPart")[0];
          iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) - preparationPartValue) + "px" : preparationPartValue + "px";
        }
        let seasoningDetailValue = (this.seasoningDetailMigiValue - this.seasoningDetailHidariValue);
        if (seasoningDetailValue != 0) {
          // seasoningDetailValue *= 2;
          let iWidths = document.getElementsByClassName("seasoningTd");
          const prevWidth = parseInt(iWidths[0].clientWidth) + seasoningDetailValue;
          for (let iWidth of iWidths) {
            iWidth.style.width = prevWidth + "px";
          }
        }
        let seasoningPartValue = (this.seasoningPartMigiValue - this.seasoningPartHidariValue);
        if (seasoningPartValue != 0) {
          // seasoningPartValue *= 2;
          let iHeight = document.getElementsByClassName("seasoningPart")[0];
          iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) + seasoningPartValue) + "px" : seasoningPartValue + "px";
        }
      });

      for (let cook of this.dispCookings) {
        if (cook.id == id) {
          cook.selectDL = "selectDL";
        } else {
          cook.selectDL = "";
        }
      }
    },
    async deleteCooking(id, index) {
      if (confirm("ま？")) {
        const targetCooking = this.cookings[index];
        const storage = getStorage();
        if (targetCooking.imgFullPaths && 0 < targetCooking.imgFullPaths.length) {
          targetCooking.imgFullPaths.forEach((element) => {
            deleteObject(ref(storage, element));
          });
        }
        await deleteDoc(doc(firebase.db, "Cookings2", id));

        if (this.id && id == this.id) {
          this.clearFunc();
        }
        
        await this.awaitFunc();
      }
    },
    async copyCooking(id) {
      const cooking = this.cookings.find((cooking) => cooking.id == id);

      let data = {
        title: "(copy)" + cooking.title,
        tags: cooking.tags,
        imgFullPaths: [],
        imgUrls: [],
        material: cooking.materialData != null ? JSON.stringify(cooking.materialData) : null,
        uuid: this.getUniqueStr(),
        isPublic: false,
        recipeCategory: cooking.recipeCategory,
        createDate: new Date(),
        updateDate: new Date(),
      };

      const db = collection(firebase.db, "Cookings2");
      await addDoc(db, data);
      await this.awaitFunc();
      this.dispMessage("コピーしたー");

    },
    async privateCooking(id) {
      await updateDoc(doc(firebase.db, "Cookings2", id), {isPublic: false});
      await this.awaitFunc();
    },
    async publicCooking(id) {
      await updateDoc(doc(firebase.db, "Cookings2", id), {isPublic: true});
      await this.awaitFunc();
    },
    preparationData() {
      return {
        title: this.titleInput,
        dispInput: this.dispTitleInput,
        tags: this.tagInput,
        isPublic: false,
        recipeCategory: this.recipeCategory,
        imgFullPaths: this.imgFullPaths,
        imgUrls: this.imgUrls,
        material: this.materialData != null ? JSON.stringify(this.materialData) : null,
        description: this.description != null ? this.description : null,
        procedure1: this.procedure1 != null ? this.procedure1 : null,
        procedure2: this.procedure2 != null ? this.procedure2 : null,
        procedure3: this.procedure3 != null ? this.procedure3 : null,
        procedure4: this.procedure4 != null ? this.procedure4 : null,
        procedure5: this.procedure5 != null ? this.procedure5 : null,
        procedure6: this.procedure6 != null ? this.procedure6 : null,
        procedure7: this.procedure7 != null ? this.procedure7 : null,
        procedure8: this.procedure8 != null ? this.procedure8 : null,
        procedure9: this.procedure9 != null ? this.procedure9 : null,
        procedure10: this.procedure10 != null ? this.procedure10 : null,
        point: this.point != null ? this.point : null,
        youtubeUrl: this.youtubeUrl != null ? this.youtubeUrl : null,
        createDate: new Date(),
        updateDate: new Date(),
      };
    },
    async changeText(target, event) {
      if (target == "youtubeUrl") {
        let temYoutubeUrl = this.youtubeUrl.replace("https://www.youtube.com/watch?v=", "");
        temYoutubeUrl = temYoutubeUrl.replace("https://youtu.be/", "");
        let temYoutubeUrls = temYoutubeUrl.split("?");
        this.youtubeUrl = temYoutubeUrls[0];
      }
      if (!this.id) {
        const data = this.preparationData();
        if (event) {
          if (target == "youtubeUrl") {
            data[target] = this.youtubeUrl;
          } else {
            data[target] = event.target.value;
          }
        } else {
          if (target == "image") {
            data.imgUrls = this.imgUrls;
            data.imgFullPaths = this.imgFullPaths;

          } else if (target == "tags") {
            data.tags = this.tagInput;

          }
        }
        const db = collection(firebase.db, "Cookings2");
        const docRef = await addDoc(db, data);
        this.id = docRef.id;
        this.idInput = data.recipeCategory == "rentin" ? "https://rentinsyokudo.com/recipe?id=" + this.id : "https://rentinsyokudo.com/trecipe?id=" + this.id;

      } else {
        const data = {
          updateDate: new Date()
        };
        if (event) {
          if (target == "youtubeUrl") {
            data[target] = this.youtubeUrl;
          } else {
            data[target] = event.target.value;
          }
        } else {
          if (target == "image") {
            data.imgUrls = this.imgUrls;
            data.imgFullPaths = this.imgFullPaths;

          } else if (target == "tags") {
            data.tags = this.tagInput;

          }
        }
        await updateDoc(doc(firebase.db, "Cookings2", this.id), data); 

      }
      if (target == "tags") {
        if (event) {
          this.tags = event.target.value.split(",");
        } else {
          this.tags = this.tagInput.split(",");
        }
      }
      await this.awaitFunc();
      for (let cook of this.dispCookings) {
        if (cook.id == this.id) {
          cook.selectDL = "selectDL";
        } else {
          cook.selectDL = "";
        }
      }
    },
    filterCategoryExec(category) {
      if (category == "all") {
        this.dispCookings =  JSON.parse(JSON.stringify(this.cookings));
      } else {
        this.dispCookings =  this.cookings.filter((cook) => cook.recipeCategory == category);
      }
      document.getElementsByClassName("searchBtnActive")[0].click();
    },
    openMaterialDialog() {
      if (this.materialDataDialogStatus) {
        this.materialDataDialogStatus = false;
      } else {
        this.materialDataDialogStatus = true;
      }
    },
    openConfigDialog() {
      if (this.configDialogStatus) {
        this.configDialogStatus = false;
      } else {
        this.configDialogStatus = true;
      }
    },
    async closeMaterialDialog(materialData) {
      let changeFlg = false;
      let data = {};
      let numOfPeople = 0;
      if (materialData.numOfPeople) {
        numOfPeople = materialData.numOfPeople;
        changeFlg = true;
      }
      data.numOfPeople = numOfPeople;
      data.ingredients = [];
      data.seasonings = [];

      let ingredient = {};
      let preparations = [];
      for (let i = 1; i <= 14; i++) {
        if (i > 1) {
          if (materialData["iCheck_" + i]) {
            let preparation = {};
            preparation.name = materialData["iName_" + i];
            preparation.weight1 = materialData["iWeight_" + i + "_1"];
            preparation.weight2 = materialData["iWeight_" + i + "_2"];
            if (preparation.name || preparation.weight1 || preparation.weight2) {
              preparations.push(preparation);
            }

          } else {
            if (ingredient.name || ingredient.weight1 || ingredient.weight2) {
              changeFlg = true;
              ingredient.preparation = JSON.parse(JSON.stringify(preparations));
              data.ingredients.push(JSON.parse(JSON.stringify(ingredient)));

              ingredient = {};
              preparations = [];
            }

            ingredient.name = materialData["iName_" + i];
            ingredient.weight1 = materialData["iWeight_" + i + "_1"];
            ingredient.weight2 = materialData["iWeight_" + i + "_2"];
          }

        } else {
          ingredient.name = materialData["iName_" + i];
          ingredient.weight1 = materialData["iWeight_" + i + "_1"];
          ingredient.weight2 = materialData["iWeight_" + i + "_2"];
        }

        let seasoning = {};
        seasoning.name = materialData["sName_" + i];
        seasoning.weight1 = materialData["sWeight_" + i + "_1"];
        seasoning.weight2 = materialData["sWeight_" + i + "_2"];
        seasoning.group = materialData["sGroup_" + i];
        seasoning.tenCheck = materialData["sTenCheck_" + i];
        if (seasoning.name || seasoning.weight1 || seasoning.weight2) {
          changeFlg = true;
          data.seasonings.push(JSON.parse(JSON.stringify(seasoning)));
        }
      }
      if (changeFlg && data && (data.numOfPeople || data.ingredients.length > 0 || data.seasonings.length > 0)) {
        this.materialData = data;
        if (!this.id) {
          const data = this.preparationData();
          const db = collection(firebase.db, "Cookings2");
          const docRef = await addDoc(db, data);
          this.id = docRef.id;
          this.idInput = data.recipeCategory == "rentin" ? "https://rentinsyokudo.com/recipe?id=" + this.id : "https://rentinsyokudo.com/trecipe?id=" + this.id;

        } else {
          const data = {
            updateDate: new Date()
          };
          data.material = JSON.stringify(this.materialData);
          if (this.originMaterialJson) {
            if (data.material != this.originMaterialJson) {
              await updateDoc(doc(firebase.db, "Cookings2", this.id), data); 
              await this.awaitFunc();
              for (let cook of this.dispCookings) {
                if (cook.id == this.id) {
                  cook.selectDL = "selectDL";
                } else {
                  cook.selectDL = "";
                }
              }
            }
          } else {
            await updateDoc(doc(firebase.db, "Cookings2", this.id), data); 
            await this.awaitFunc();
            for (let cook of this.dispCookings) {
              if (cook.id == this.id) {
                cook.selectDL = "selectDL";
              } else {
                cook.selectDL = "";
              }
            }
          }
        }
      }
    }, 
    closeConfigDialog() {
      // TAGSの読み直し
      this.tagSnapshot();
    },
    searchHistory(e) {
      const searchStr = e.target.value;
      const upper = searchStr.toUpperCase();
      const lower = searchStr.toLowerCase();
      this.dispCookings = this.cookings.filter((cook) => {
        if (cook.title) {
          const upperTitle = cook.title.toUpperCase();
          const lowerTitle = cook.title.toLowerCase();
          return upperTitle.indexOf(upper) >= 0
                || lowerTitle.indexOf(lower) >= 0;
        }
        return false;
      })
    },
    sortCookings(type, event) {
      if (type == 0) {
        // 昇順
        this.dispCookings.sort((a, b) => {
          return a.title < b.title ? -1 : 1;
        });

      } else if (type == 1) {
        // 降順
        this.dispCookings.sort((a, b) => {
          return a.title < b.title ? 1 : -1;
        });

      } else if (type == 2) {
        // 登録日順
        this.dispCookings.sort((a, b) => {
          return a.createDate.seconds < b.createDate.seconds ? 1 : -1;
        });

      } else if (type == 3) {
        // 更新日順
        this.dispCookings.sort((a, b) => {
          return a.updateDate.seconds < b.updateDate.seconds ? 1 : -1;
        });

      }
      Array.from(document.getElementsByClassName("searchBtn")).forEach((element) => {
        element.classList.remove("searchBtnActive");
      });
      event.target.classList.add("searchBtnActive");
    },
    getUniqueStr() {
      const strong = 1000;
      return new Date().getTime().toString(16) + Math.floor(strong * Math.random()).toString(16);
    },
    toFullWidth(str) {
      // 半角英数字を全角に変換
      str = String(str);
      if (str.indexOf("/") > -1) {
        let strs = str.split("/");
        for (let i = 0; i < strs.length; i++) {
          strs[i] = strs[i].replace(/[A-Za-z0-9]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
          });
        }
        return strs[0] + "/" + strs[1];
      } else {
        return str.replace(/[A-Za-z0-9]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
        });
      }
    },
    redirectZairyo() {
      const url = "/zairyo?id=" + this.id;
      window.open(url, '_blank');
    },
    addIndent(str) {
      if (str) {
        return str.replace(/\n/g, '\n　 ');
      } else {
        return null;
      }
    },
    showClick(tabName) {
      if (tabName == "meta") {
        this.showMeta.show = true;
        this.showMeta.cls = 'tabMenu-select';
        this.showInput.show = false;
        this.showInput.cls = '';
        this.showPreview.show = false;
        this.showPreview.cls = '';

      } else if (tabName == "input") {
        this.showMeta.show = false;
        this.showMeta.cls = '';
        this.showInput.show = true;
        this.showInput.cls = 'tabMenu-select';
        this.showPreview.show = false;
        this.showPreview.cls = '';

      } else if (tabName == "preview") {
        this.showMeta.show = false;
        this.showMeta.cls = '';
        this.showInput.show = false;
        this.showInput.cls = '';
        this.showPreview.show = true;
        this.showPreview.cls = 'tabMenu-select';
      }
    },
    dispHistory() {
      this.isHistory = !this.isHistory;
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
@font-face {
  font-family: 'chihaya';
  src: url('../../public/fonts/nchihaya.ttf') format('truetype');
}
<style scoped>
.post {
  position: relative;
  width: 100%;
  display: inline-flex;
  background: linear-gradient(to right, #fffff7, #FFFFEA);
}
/* 色々入力 */
#editPart {
  position: relative;
  width: 77%;
}
#historyPart {
  width: 23%;
  margin-left: 0.5vw;
}
#mdInput {
  margin: 1vw 0 0;
  position: relative;
  display: inline-flex;
  width: 100%;
}
#inputPart {
  width: 50%;
  display: grid;
  text-align: left;
  padding: 0 1vw;
  overflow-y: auto;
  background-color: rgb(255 247 227);
}
#tagInput::placeholder {
  color: #d3d3d3;
}
.recipeCategory {
  position: relative;
  display: inline-flex;
  margin-left: 1vw;
}
.recipeCategory input {
  vertical-align: 1.6em;
  width: 1vw;
}
.recipeCategory img {
  width: 3vw;
  height: auto;
}
label {
  font-size: 1vw;
}
.titleTagInput {
  height: 1.6vw;
  font-size: 1.2rem;
  margin-left: 1vw;
  margin-bottom: 0.8vw;
  border-radius: 5px;
  border: 1px solid #919191;
}
input:focus {
  outline: none;
}
.procedureSection {
  display: grid;
}
.junbiSection {
  display: grid;
}
/* 説明 */
#description,
#point {
  height: 6vw;
}
.pointWrap {
  margin-top: 4vw;
  position: relative;
}
.pointTitle {
  position: absolute;
  font-size: 2rem;
  top: -15px;
  background-color: rgb(255, 255, 234);
  left: 14px;
  padding: 0 0.4vw;
}
.point {
  margin-top: 1vw;
  border: 5px dotted #a83424;
  padding: 2vw 0 2vw 1vw;
  margin-left: unset !important;
  border-radius: 14px;
}
#idInput {
  resize: vertical;
  margin-bottom: 0.8vw;
  margin-left: 1vw;
  height: 2vw;
  font-size: 1.2em;
  padding: 0.4vw;
  border-radius: 5px;
}
textarea {
  resize: vertical;
  margin-bottom: 0.8vw;
  margin-left: 1vw;
  height: 2vw;
  font-size: 1.2em;
  padding: 0.4vw;
  border-radius: 5px;
}
/* 履歴 */
.historyHeader {
  display: inline-flex;
  height: 3vw;
}
.historyTitle {
  font-size: 1.6rem;
  font-weight: bold;
  display: block;
  line-height: 3vw;
}
.search {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
  width: 95%;
  padding: 10px 0;
}
.searchHistoryInput {
  width: 18vw;
  height: 1.4vw;
  font-size: 1.2rem;
  vertical-align: text-bottom;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #919191;
  background-color: unset;
}
.searchBtns {
  margin-top: 10px;
}
.searchBtns button {
  margin: 0 10px;
  padding: 2px 8px;
  border: none;
  border-radius: 7px;
  background-color: bisque;
}
.searchBtnActive {
  background-color: rgb(130, 213, 216) !important;
  font-weight: bold;
  color: white;
}
.cooking {
  position: relative;
}
.rentinBG {
  background-color: #ffe3e3;
}
.toasterBG {
  background-color: #d2e4ff;
}
.cookImg {
  position: relative;
  width: 20%;
}
.cookImgImg {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.historyRecipeCategory {
  position: absolute;
  bottom: 0.2vw;
}
.historyRecipeCategory img {
  width: 2vw;
}
.cookSum {
  width: 60%;
  margin-left: 10px;
}
.cookSumTitle {
  font-size: 1vw;
  font-weight: bold;
  width: 100%;
  height: 3vw;
  max-height: 3vw;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.cookSumDate {
  font-size: 0.8rem;
}
.cookSumDate img {
  width: 13px;
  height: auto;
}
.cookIconArea {
  width: 20%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.cookIconWrap {
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  padding: 4px;
  border: 1px solid black;
  margin-right: 6px;
  cursor: pointer;
}
.cookIcon {
  width: 1vw;
  height: 1vw;
}
dd {
  margin-left: 0;
  background-color: #fff;
  height: 78%;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
  width: 95%;
}
dl {
  display: flex;
  width: 90%;
  margin: 7px auto;
  text-align: left;
}
.selectDL {
  border: 3px solid #3300ff;
}
.buttonNew {
  width: 2vw;
  height: 2vw;
  aspect-ratio: 1;
  z-index: 1;
  right: 4vw;
  position: absolute;
  top: 0.5vw;
}
.buttonConfig {
  width: 2vw;
  height: 2vw;
  aspect-ratio: 1;
  z-index: 1;
  right: 1vw;
  position: absolute;
  top: 0.5vw;
}
.material-youtube {
  display: inline-flex;
}
#youtubeUrl {
  height: 2vw;
  border: #ffd8c6 2px solid;
  border-radius: 5px;
  font-size: 1.2rem;
  margin: 0 0 0 1vw;
  width: 60%;
  text-indent: 0.5rem;
}
.buttonMaterial {
  justify-content: center;
  color: #3b3b3b;
  font-size: 1.2rem;
  background-color: #efefef;
  margin-left: 1vw;
  border-radius: 5px;
  height: 2.2vw;
  width: 5vw;
}
.buttonMaterialSumi {
  
  justify-content: center;
  color: #4c41ac;
  font-size: 1.2rem;
  background-color: #fcefe9;
  margin-left: 1vw;
  border-radius: 5px;
  height: 2.2vw;
  font-weight: bold;
}
.materialPart {
  position: relative;
}
.message {
  display: none;
  position: fixed;
  width: 200px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  transition: opacity 0.5s;
  opacity: 0;
}
.message p {
  position: relative;
  font-size: 1.2vw;
  line-height: 50px;
  margin: 0;
  color: #ebebeb;
}
.dndImg {
  position: relative;
  border: 2px dotted #3b3b3b;
  border-radius: 10px;
  background-color: #fff;
  height: 4vw;
  margin-top: 0.6vw;
  margin-left: 1vw;
  margin-bottom: 1vw;
}
.dndImgEmptyMsg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  letter-spacing: 3px;
  width: 100%;
  font-style: italic;
  text-indent: 0.5rem;
}
.enter {
  border: 2px dotted powderblue;
}
.imgContents {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
  overflow-y: auto;
}
.imgContent {
  margin: 5px;
  position: relative;
}
.imgContentImg {
  width: 6vw;
  height: 3vw;
  object-fit: contain;
}
.deleteImg {
  width: 1vw;
  border: 1px solid black;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  right: 0;
  padding: 4px;
}
.tagHistory {
  display: flex;
  background-color: white;
  height: 7vw;
  border-radius: 10px;
  flex-wrap: wrap;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
  margin-bottom: 0.8vw;
  margin-left: 1vw;
}
.tagHistoryPart {
  margin: 0.2vw 0.4vw;
  background-color: #F7EDED;
  border-radius: 8px;
  line-height: 1.4vw;
  padding: 0 10px;
}
.materialDialogBG, .configDialogBG {
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  z-index: 9998;
  width: 100%;
  height: 100%;
}

/* Preview */
#previewPart {
  width: 50%;
  white-space: pre-wrap;
  font-size: 1.4rem;
  text-align: left;
  font-family: 'chihaya';
  overflow-y: auto;
  overflow-x: hidden;
}
.preview {
  width: 95%;
  margin: 0 auto;
}
.previewImg {
  margin: 2vw 0;
}
.previewImg img {
  width: 100%;
  height: auto;
}
.how {
  margin-top: 4vw;
  font-size: 2rem;
  margin-bottom: 2vw;
}
.indent {
  margin-left: 1vw;
}
.youtubeMovie {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  margin-top: 2vw;
}
ol  {
  margin: 0;
  padding: 0
}
ol li  {
  list-style: none;
  line-height: 1.6rem;
  margin-bottom: 1vw;
  text-indent: -2rem;
  padding-left: 2rem;
}
.material {
  width: 99%;
  display: inline-flex;
  position: relative;
  font-size: 1.4rem;
  background: #ededed;
  box-shadow: 0px 0px 0px 5px #ededed;
  border: dashed 2px white;
  padding-bottom: 1vw;
}
.material:after {
  position: absolute;
  content: '';
  right: -7px;
  top: -7px;
  border-width: 0 15px 15px 0;
  border-style: solid;
  border-color: #d9d9d9 #fff #d9d9d9;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15);
}
.ingredientZentai {
  position: relative;
  top: 0px;
}
.seasonZentai {
  position: relative;
  top: 0px;
}
.ingredient {
  position: relative;
  width: 50%;
}
.ingredientPart {
  position: relative;
  width: 100%;
  top: 0%;
  left: 0%;
  padding-left: 10px;
}
.seasoning {
  position: relative;
  width: 50%;
}
.seasoningPart {
  position: relative;
  width: 100%;
  top: 0%;
  left: 0%;
  padding-left: 10px;
}
.ingredientTable,
.seasoningTable {
  margin-bottom: 0;
  width: 100%;
  padding-left: 10px;
}
.ingredientTd {
  width: 50%;
  padding: 0;
}
.preparationTd {
  width: 50%;
  padding: 0;
}
.seasoningTd {
  width: 50%;
  padding: 0;
}
.title {
  margin: 0; 
  padding: 0;
  font-family: 'chihaya', Avenir, Helvetica, Arial, sans-serif;
  text-align: left;
  font-size: 1.6rem;
  line-height: 3rem;
}
.indent {
  margin-left: 1vw;
}
.tags {
  font-family: chihaya;
  position: relative;
  text-align: left;
  margin: 1vw auto;
}
.tag {
  font-size: 1.2rem;
  margin-right: 10px;
  background-color: #fce2e2;
  border-radius: 10px;
  padding: 4px 6px;
  margin-bottom: 10px;
  display: inline-block;
}
.part {
  position: relative;
  width: 95%;
  margin: 0 auto;
}
/* preparationPart */
.preparationPart {
  width: 100%;
  padding-left: 1vw;
}
.kakko {
  position: relative;
  margin: 0.4vw 0 1vw 0;
  padding-left: 50px;
  width: 84%;
}
.frame-box-002 {
	position: relative;
}
.frame-box-002::before, .frame-box-002::after {
	content: '';
	width: 15px;
	height: 100%;
	position: absolute;
}
.frame-box-002::before {
	border-left: solid 2px #717171;
	border-top: solid 2px #717171;
	border-bottom: solid 2px #717171;
  border-radius: 3px;
	top: 0;
	left: 0;
}
.frame-box-002::after {
	border-right: solid 2px #717171;
	border-top: solid 2px #717171;
	border-bottom: solid 2px #717171;
  border-radius: 3px;
	top: 0;
	right: 0;
}
.iHeight, .sHeight {
  line-height: 1rem;
  font-size: 1rem;
}
/* ------------------------------------ */
.zairyoButton {
  position: absolute;
  top: 0.8vw;
  right: 3vw;
  color: white;
  background-color: #7985ff;
  border-radius: 5px;
  border: 2px solid gray;
  cursor: pointer;
}
.nophoto {
  position: relative;
  width: 80%;
  margin: 0 auto;
}
.imgButton {
  position: absolute;
  top: 25px;
  left: 15vw;
  padding: 0 1vw;
  height: 1.4vw;
  cursor: pointer;
}
.menu {
  display:none;
}
.close {
  display:none;
}
.tabMenuWrap {
  display: none;
}
.tabMenu {
  display: none;
}
.tabMenu-select {
  display: none;
}
.filterCategoryWrap {
  margin-bottom: 0.6vw;
}
.filterRadio {
  margin-left: 2vw;
}
@media screen and (max-width:640px) {
  .post {
    display: grid;
  }
  #editPart {
    width: 100%;
  }
  #historyPart {
    width: 100%;
  }
  #inputPart {
    width: 100%;
  }
  #previewPart {
    width: 100%;
  }
  textarea {
    margin: 0;
    height: 10vw;
    margin-bottom: 2vw;
  }
  .metaPart {
    padding-left: unset;
  }
  .metaPart input {
    width: 95%;
    height: 8vw;
  }
  #description,
  #point {
    height: 20vw;
  }
  label {
    font-size: 1.4rem;
  }
  .menu {
    display: grid;
    border: none;
    background-color: unset;
    width: 8vw;
    position: fixed;
    top: 3vw;
    right: 3vw;
  }
  .close {
    display: grid;
    border: none;
    background-color: unset;
    width: 8vw;
    position: fixed;
    top: 3vw;
    right: 3vw;
  }
  .tabMenuWrap {
    display: grid;
    width: 85%;
    display: inline-flex;
    background: linear-gradient(to right, #7d7d7d, #454545);
    height: 10vw;
    border-radius: 10px;
    margin: 1vw;
  }
  .tabMenu {
    display: grid;
    color: white;
    font-weight: bold;
    width: 30vw;
    margin: 1vw;
    height: 8vw;
    vertical-align: middle;
    border-radius: 10px;
    line-height: 8vw;
  }
  .tabMenu-select {
    display: grid;
    color: unset !important;
    background-color: rgba(255,255,255,0.9) !important;
  }
  .procedureSection {
    display: grid;
    height: 105vw;
    overflow-y: auto;
  }
  .tagHistory {
    position: relative;
    width: 95%;
    height: 40vw;
    margin: 0 auto;
    top: unset;
    right: unset;
  }
  .recipeCategory {
      position: relative;
      display: inline-flex;
  }
  .recipeCategory input {
    vertical-align: 2em;
    width: 5vw;
    height: 5vw;
  }
  .recipeCategory img {
    width: 25vw;
  }
  .tagHistoryPart {
      margin: 1vw 2vw;
      line-height: 2rem;
  }
  .junbiSectionMobile {
    display: grid;
    padding-top: 10px;
    text-align: left;
  }
  #youtubeUrl {
    height: 8vw;
    width: 95%;
  }
  .dndImg {
    border: 2px dotted #3b3b3b;
    height: 22vw;
    width: 96%;
    margin-top: 3vw;
  }
  .imgButton {
    position: relative;
    left: 5px;
    height: 9vw;
    width: 20vw;
    margin-bottom: 4vw;
  }
  .buttonNew {
    top: unset;
    left: unset;
    position: relative;
    aspect-ratio: 1;
    z-index: 1;
    height: 9vw;
    width: 20vw;
    display: grid;
    margin-left: 5px;
  }
  .new {
    width: 7vw;
  }
  .cookSumTitle {
    font-size: 1.3rem;
    width: 100%;
    height: 14vw;
    max-height: 14vw;
  }
  .searchHistoryInput {
    width: 80%;
    height: 5vw;
  }
  .historyRecipeCategory img {
    width: 8vw;
  }
  
  .cookIconWrap {
    width: 4vw;
    height: 4vw;
    margin-top: 6px;
  }
  .cookIcon {
    width: 4vw;
    height: 4vw;
  }
  dd {
    height: 40%;
    margin: 0 auto;
  }
  .imgContentImg {
    width: 9rem;
    height: auto;
  }
  .deleteImg {
    width: 5vw;
  }
}
</style>
