<template>
  <div class="footer">
    <div class="copyright">Copyright(C) Hachiko co.,Ltd. All Rights Reserved.</div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.footer {
  position: relative;
  width: 100%;
  height: 3vw;
  background-color: rgb(245 225 171);
  margin-top: 6vw;
}
.copyright {
  position: absolute;
  line-height: 3vw;
  color: #a83424;
  width: 100%;
}
@media screen and (max-width:640px) {
  .footer {
    position: relative;
    width: 100%;
    height: 3rem;
    background-color: rgb(245 225 171);
  }
  .copyright {
    font-size: 0.8rem;
    line-height: 3rem;
    margin: 0 auto;
    display: contents;
  }
}
</style>
