<template>
  <div class="toTop">
    <button @click="toTop">
      <img src="../assets/icons/toTop.svg" alt="">
    </button>
  </div>
</template>

<script>
export default {
  name: 'ToTop',
  data() {
    return {
    }
  },
  methods: {
    toTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }
}
</script>

<style scoped>
button {
  position: fixed;
  width: 3vw;
  height: auto;
  bottom: 1vw;
  right: 1vw;
  background-color: unset;
  border: unset;
  cursor: pointer;
  z-index: 1;
}
.toTop {
  position: relative;
  width: 100%;
}
@media screen and (max-width:640px) {
  button {
    position: absolute;
    width: 15vw;
  }
}
</style>
