<template>
  <div class="message">項目をダブルクリックすると、削除できます。（並び変えは今からです）</div>
  <div class="assemblyConfig">
    <div class="category">
      <div class="categoryTitle">カテゴリ１</div>
      <div class="inputCategory">
        <label for="newCategoryValue">【追加値】</label>
        <input type="text" id="newCategoryValue1" v-model="newCategoryValue1">
        <button @click="addCategory(1)">{{ buttonTitle }}</button>
      </div>
      <div class="categorySpan">
        <span v-for="(value, index) in category1" :key="index" @click="changeValue(1, index)" @dblclick="deleteValue(1, index)" :id="'categoryValue_1_' + index" class="values red">{{ value }}</span>
      </div>
      <!-- <div v-for="(val, index) in category1" :key="index">
        <input type="text" :value="val">
      </div> -->
    </div>
    <div class="category">
      <span class="categoryTitle">カテゴリ２</span>
      <div class="inputCategory">
        <label for="newCategoryValue">【追加値】</label>
        <input type="text" id="newCategoryValue2" v-model="newCategoryValue2">
        <button @click="addCategory(2)">追加</button>
      </div>
      <div class="categorySpan">
        <span v-for="(value, index) in category2" :key="index" @click="changeValue(2, index)" @dblclick="deleteValue(2, index)" :id="'categoryValue_2_' + index" class="values blue">{{ value }}</span>
      </div>
      <!-- <div v-for="(val, index) in category2" :key="index">
        <input type="text" :value="val">
      </div> -->
    </div>
    <div class="category">
      <span class="categoryTitle">カテゴリ３</span>
      <div class="inputCategory">
        <label for="newCategoryValue">【追加値】</label>
        <input type="text" id="newCategoryValue3" v-model="newCategoryValue3">
        <button @click="addCategory(3)">追加</button>
      </div>
      <div class="categorySpan">
        <span v-for="(value, index) in category3" :key="index" @click="changeValue(3, index)" @dblclick="deleteValue(3, index)" :id="'categoryValue_3_' + index" class="values green">{{ value }}</span>
      </div>
      <!-- <div v-for="(val, index) in category3" :key="index">
        <input type="text" :value="val">
      </div> -->
    </div>
  </div>
</template> 

<script>
import firebase from "../main.js";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";

export default {
  name: 'ConfigDialog',
  data() {
    return {
      category1Id: null,
      category2Id: null,
      category3Id: null,
      category1: [],
      category2: [],
      category3: [],

      newCategoryValue1: null,
      newCategoryValue2: null,
      newCategoryValue3: null,

      buttonTitle: "追加",
      selectCategory: null,
      selectIndex: null,
    }
  },
  mounted() {
    this.awaitFunc();
  },
  beforeUnmount() {
  },
  methods: {
    async awaitFunc() {
      this.categories = [];
      const categoriesQuerySnapshot = await getDocs(collection(firebase.db, "Categories"));
      categoriesQuerySnapshot.forEach((docu) => {
        let data = docu.data();
        if (data.category == 1) {
          this.category1Id = docu.id;
          this.category1 = data.values;

        } else if (data.category == 2) {
          this.category2Id = docu.id;
          this.category2 = data.values;

        } else if (data.category == 3) {
          this.category3Id = docu.id;
          this.category3 = data.values;

        }
      });
    },
    async addCategory(category) {
      let id = null;
      let data = {};
      if (category == 1) {
        id = this.category1Id;
        let values = this.category1.concat();
        values.push(this.newCategoryValue1);
        data.values = values;
        this.newCategoryValue1 = null;

      } else if (category == 2) {
        id = this.category2Id;
        let values = this.category2.concat();
        values.push(this.newCategoryValue2);
        data.values = values;
        this.newCategoryValue2 = null;

      } else {
        id = this.category3Id;
        let values = this.category3.concat();
        values.push(this.newCategoryValue3);
        data.values = values;
        this.newCategoryValue3 = null;

      }
      await updateDoc(doc(firebase.db, "Categories", id), data);

      this.awaitFunc();
    },
    changeValue(category, index) {
      if (this.selectCategory, this.selectIndex) {
        if (this.selectCategory == this.selectCategory && this.selectIndex == index) {
          if (category == 1) {
            this.newCategoryValue1 = null;
          } else if (category == 2) {
            this.newCategoryValue2 = null;
          } else {
            this.newCategoryValue3 = null;
          }

        }
      } else {
        if (category == 1) {
          this.newCategoryValue1 = document.getElementById("categoryValue_1_" + index).innerText;
        } else if (category == 2) {
          this.newCategoryValue2 = document.getElementById("categoryValue_2_" + index).innerText;
        } else {
          this.newCategoryValue3 = document.getElementById("categoryValue_3_" + index).innerText;
        }
      }
    },
    async deleteValue(category, index) {
      let id = null;
      let data = {};
      let target = null;
      if (category == 1) {
        target = this.category1[index];
      } else if (category == 2) {
        target = this.category2[index];
      } else {
        target = this.category3[index];
      }
      if (!confirm("【" + target + "】を削除して良い？")) {
        return;
      }
      if (category == 1) {
        id = this.category1Id;
        target = this.category1.splice(index, 1);
        data.values = this.category1

      } else if (category == 2) {
        id = this.category2Id;
        target = this.category2.splice(index, 1);
        data.values = this.category2

      } else {
        id = this.category3Id;
        target = this.category3.splice(index, 1);
        data.values = this.category3

      }

      await updateDoc(doc(firebase.db, "Categories", id), data);
      this.awaitFunc();

    }
  }
}
</script>

<style scoped>
.assemblyConfig {
  display: inline-flex;
  width: 90%;
  height: 34vw;
}

.category {
  position: relative;
  display: inline-flex;
  width: 23vw;
  margin: 0.4vw 1vw;
  border: 1px solid black;
  text-align: left;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: auto;
}
.values {
  height: 1vw;
  padding: 0.4vw 0.7vw;
  background-color: antiquewhite;
  margin: 0.2vw 0.5vw;
  font-size: 0.8rem;
  border-radius: 5px;
  cursor: pointer;
}
.categoryTitle {
  width: 100%;
  text-align: center;
}
.categorySpan {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.inputCategory {
  margin-top: 1vw;
  margin-bottom: 1vw;
  width: 100%;
}
.inputSection {
  margin: 0.5vw 0;
}
#newCategorySel {
  width: 100%;
  height: 1.4vw;
}
#newCategoryValue {
  height: 1.2vw;
}
button {
  margin: 1vw 0 0 1vw;
  width: 4vw;
  height: 1.6vw;
}
.red{
  background-color: rgb(255, 225, 225);
}
.blue {
  background-color: rgb(215, 229, 255);
}
.green{
  background-color: rgb(201, 255, 213);
}
.message {
  width: 100%;
  font-size: 1.2rem;
  color: #ff7676;
  font-weight: bold;
}
table {
  width: 100%;
}
input {
  width: 12vw;
  height: 1.6vw;
}
</style>
