<template>
  <div class="headerLine">
    <div class="headerLine1"></div>
    <div class="headerLine2"></div>
  </div>
</template>

<script>
export default {
  name: 'HeaderLine',
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.headerLine1 {
  width: 100%;
  height: 6px; 
  background-color: #BCC0B0;
}
.headerLine2 {
  margin-top: 2px;
  width: 100%;
  height: 2px; 
  background-color: #BCC0B0;
}
</style>
