import { createRouter, createWebHistory } from 'vue-router'

import About from '../views/About.vue'

import RentinMenu from '../views/RentinMenu.vue'
import Recipe from '../views/Recipe.vue'

import ToasterMenu from '../views/ToasterMenu.vue'

import Post from '../views/Post.vue'
import BlogPost from '../views/BlogPost.vue'

import ManageList from '@/views/ManageList.vue'

import Zairyo from '../views/Zairyo.vue'

const routes = [

  { path: '/about', name: 'About', component: About },

  { path: '/', name: 'RentinMenu', component: RentinMenu },
  { path: '/recipe', name: 'RentinRecipe', component: Recipe, props: true },

  { path: '/tmenu', name: 'ToasterMenu', component: ToasterMenu },
  { path: '/trecipe', name: 'ToasterRecipe', component: Recipe, props: true },

  { path: '/post', name: 'Post', component: Post },
  { path: '/blogPost', name: 'BlogPost', component: BlogPost },

  { path: '/manageList', name: 'ManageList', component: ManageList },

  { path: '/zairyo', name: 'Zairyo', component: Zairyo },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
})

export default router
