<template>
  <div class="recommend">
    <div class="gingamuCheck">
      <span>オススメのレンチンRecipe</span>
    </div>
    <carousel :items-to-show="3">
      <slide @click="displayRecipe(cooking.id, cooking.recipeCategory)" class="slide" v-for="(cooking, index) in cookings" :key="index">
        <img class="cookImg" v-if="cooking.imgUrls && cooking.imgUrls.length > 0" :src="cooking.imgUrls[0]" alt="">
        <span class="slideTitle">{{ cooking.dispTitle }}</span>
      </slide>

      <template #addons>
        <navigation />
      </template>
    </carousel>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

import firebase from "../main.js";
import { collection, getDocs  } from "firebase/firestore";

export default {
  name: 'Recommend',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      cookings: []
    }
  },
  mounted() {
    this.awaitFunc();
  },
  methods: {
    async awaitFunc() {
      this.cookings = [];
      const cookingsQuerySnapshot = await getDocs(collection(firebase.db, "Cookings2"));
      cookingsQuerySnapshot.forEach((docu) => {
        let data = docu.data();
        if (data.isPublic == true) {
          data.id = docu.id;
          this.cookings.push(data);
        }
      });
      this.cookings.sort((a, b) => {
        return a.updateDate.seconds < b.updateDate.seconds ? 1 : -1;
      });
    },
    displayRecipe(id, recipeCategory) {
      if (recipeCategory == 'rentin') {
        this.$router.push({name: 'RentinRecipe', query: {id: id}});
      } else {
        this.$router.push({name: 'ToasterRecipe', query: {id: id}});
      }
    },
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'chihaya';
  src: url('../../public/fonts/nchihaya.ttf') format('truetype');
}

.recommend {
  font-family: 'chihaya';
  width: 60%;
  position: relative;
  margin: 5vw auto;
}
.gingamuCheck {
	margin: 1em;
	padding: 0.8em;/*内側余白*/
	background-image: linear-gradient(90deg, rgba(255, 0, 0, 0.1) 50%, transparent 50%), linear-gradient(rgba(111, 111, 111,.1) 50%, transparent 50%);/* チェック色 */
	background-size: 15px 15px;	/* チェックのサイズ */
  font-size: 1.6rem;
  text-align: left;
}
.slide {
  position: relative;
  margin: 1vw 1.6vw 0;
  text-align: left;
  display: grid;
}
.cookImg {
  width: 100%;
  height: 10vw;
  -o-object-fit: cover;
  object-fit: cover;
}
.slideTitle {
  text-align: center;
  font-size: 1.6rem;
}
:deep(.carousel__prev) {
  left: -2vw;
}
:deep(.carousel__next) {
  right: -2vw;
}
@media screen and (max-width:640px) {
  .recommend {
    width: 90%;
    margin: 5vw auto 13vw;
  }
  .gingamuCheck {
    margin: unset;
  }
  .slide {
    height: unset;
    display: block;
  }
  .cookImg {
    height: 20vw;
  }
  :deep(.carousel__prev) {
    left: -8vw;
  }
  :deep(.carousel__next) {
    right: -8vw;
  }
}
</style>
