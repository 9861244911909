<template>
  <button id="downloadImage" class="downloadImage" @click="downloadImage">
    <img src="../assets/icons/download.svg" alt="">
  </button>
  <span id="loader" class="loader"></span>
  <div class="yajirusiWrap">
    <table>
      <tr>
        <td style="background-color: rgb(222, 255, 212);">文字サイズ</td>
        <td>
          <button @click="fontSizeUp" class="yajirusi">▲</button>
        </td>
        <td>
          <button @click="fontSizeDown" class="yajirusi">▼</button>
        </td>
      </tr>
      <tr>
        <td style="background-color: rgb(222, 255, 212);">文字間隔</td>
        <td>
          <button @click="fontKankakuDown" class="yajirusi">◀</button>
        </td>
        <td>
          <button @click="fontKankakuUp" class="yajirusi">▶</button>
        </td>
      </tr>
      <tr>
        <td style="background-color: aliceblue;">全体の行間</td>
        <td>
          <button @click="hirogeru" class="yajirusi">▲</button>
        </td>
        <td>
          <button @click="sebameru" class="yajirusi">▼</button>
        </td>
      </tr>
      <tr>
        <td style="background-color: aliceblue;">全体の上下</td>
        <td>
          <button @click="zentaiAgeru" class="yajirusi">▲</button>
        </td>
        <td>
          <button @click="zentaiSageru" class="yajirusi">▼</button>
        </td>
      </tr>
      <tr>
        <td style="background-color: antiquewhite;">材料の横幅</td>
        <td>
          <button @click="ingredientDetailHidari" class="yajirusi">◀</button>
        </td>
        <td>
          <button @click="ingredientDetailMigi" class="yajirusi">▶</button>
        </td>
      </tr>
      <tr>
        <td style="background-color: antiquewhite;">材料全体の位置</td>
        <td>
          <button @click="ingredientPartHidari" class="yajirusi">◀</button>
        </td>
        <td>
          <button @click="ingredientPartMigi" class="yajirusi">▶</button>
        </td>
      </tr>
      <tr>
        <td style="background-color: silver;">下味の横幅</td>
        <td>
          <button @click="preparationDetailHidari" class="yajirusi">◀</button>
        </td>
        <td>
          <button @click="preparationDetailMigi" class="yajirusi">▶</button>
        </td>
      </tr>
      <tr>
        <td style="background-color: silver;">下味全体の横幅</td>
        <td>
          <button @click="preparationZentaiHidari" class="yajirusi">◀</button>
        </td>
        <td>
          <button @click="preparationZentaiMigi" class="yajirusi">▶</button>
        </td>
      </tr>
      <tr>
        <td style="background-color: silver;">下味の位置</td>
        <td>
          <button @click="preparationPartHidari" class="yajirusi">◀</button>
        </td>
        <td>
          <button @click="preparationPartMigi" class="yajirusi">▶</button>
        </td>
      </tr>
      <tr>
        <td>調味料の横幅</td>
        <td>
          <button @click="seasoningDetailHidari" class="yajirusi">◀</button>
        </td>
        <td>
          <button @click="seasoningDetailMigi" class="yajirusi">▶</button>
        </td>
      </tr>
      <tr>
        <td>調味料全体の位置</td>
        <td>
          <button @click="seasoningPartHidari" class="yajirusi">◀</button>
        </td>
        <td>
          <button @click="seasoningPartMigi" class="yajirusi">▶</button>
        </td>
      </tr>
    </table>
  </div>
  <div id="zairyo" class="zairyo">
    <div v-if="materialData">
      <p class="materialTitle">材料（{{ toFullWidth(materialData.numOfPeople) }}分）</p>
      <div class="material">
        <div v-if="materialData.ingredients && materialData.ingredients.length > 0" class="ingredient">
          <div class="ingredientPart">
            <p class="title">【材料】</p>
            <div class="ingredientZentai">
              <div v-for="(ingredient, key) in materialData.ingredients" :key="key">
                <table class="iHeight ingredientTable">
                  <tbody>
                    <tr>
                      <td class="ingredientTd">・{{ ingredient.name }}</td>
                      <td>{{ toFullWidth(ingredient.weight1 ? ingredient.weight1 : "") }}{{ toFullWidth(ingredient.weight2 ? ingredient.weight2 : "") }}</td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="ingredient.preparation && ingredient.preparation.length > 0" class="kakko">
                  <table class="preparationPart frame-box-002">
                    <tbody>
                      <tr v-for="(preparation, key) in ingredient.preparation" :key="key" class="iHeight">
                        <td class="preparationTd">{{ preparation.name }}</td>
                        <td>{{ toFullWidth(preparation.weight1 ? preparation.weight1 : "") }}{{ toFullWidth(preparation.weight2 ? preparation.weight2 : "") }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="materialData.seasonings && materialData.seasonings.length > 0" class="seasoning">
          <div class="seasoningPart">
            <p class="title">【調味料】</p>
            <div class="seasonZentai">
              <div v-for="(seasoning, key) in materialData.seasonings" :key="key">
                <table class="iHeight seasoningTable">
                  <tbody>
                    <tr>
                      <td class="seasoningTd"><span v-if="!seasoning.tenCheck || seasoning.tenCheck == false">・</span><span v-else>&emsp;</span>{{ seasoning.group ? seasoning.group : "" }}{{ seasoning.name }}</td>
                      <td>{{ toFullWidth(seasoning.weight1 ? seasoning.weight1 : "") }}{{ toFullWidth(seasoning.weight2 ? seasoning.weight2 : "") }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../main.js";
import { doc, getDoc, updateDoc } from "firebase/firestore";

import * as htmlToImage from 'html-to-image';

export default {
  name: 'Zairyo',
  components: {
  },
  data() {
    return {
      id: null,
      materialData: null,
      fontSizeUpValue: 0,
      fontSizeDownValue: 0,
      fontKankakuUpValue: 0,
      fontKankakuDownValue: 0,
      hirogeruValue: 0,
      sebameruValue: 0,
      zentaiAgeruValue: 0,
      zentaiSageruValue: 0,
      ingredientDetailHidariValue: 0,
      ingredientDetailMigiValue: 0,
      ingredientPartHidariValue: 0,
      ingredientPartMigiValue: 0,
      preparationDetailHidariValue: 0,
      preparationDetailMigiValue: 0,
      preparationPartHidariValue: 0,
      preparationPartMigiValue: 0,
      seasoningDetailHidariValue: 0,
      seasoningDetailMigiValue: 0,
      seasoningPartHidariValue: 0,
      seasoningPartMigiValue: 0,

      isRender: false,
    }
  },
  watch: {
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    const parent = this;
    window.addEventListener('focus', function() {
      parent.awaitFunc();
    });
    this.awaitFunc();
  },
  methods: {
    async awaitFunc() {
      const cookingSnapshot = await getDoc(doc(firebase.db, "Cookings2", this.id));
      let data = cookingSnapshot.data();
      this.materialData = JSON.parse(data.material);
      this.fontSizeUpValue = data.fontSizeUpValue ? data.fontSizeUpValue : 0;
      this.fontSizeDownValue = data.fontSizeDownValue ? data.fontSizeDownValue : 0;
      this.fontKankakuUpValue = data.fontKankakuUpValue ? data.fontKankakuUpValue : 0;
      this.fontKankakuDownValue = data.fontKankakuDownValue ? data.fontKankakuDownValue : 0;
      this.hirogeruValue = data.hirogeruValue ? data.hirogeruValue : 0;
      this.sebameruValue = data.sebameruValue ? data.sebameruValue : 0;
      this.zentaiAgeruValue = data.zentaiAgeruValue ? data.zentaiAgeruValue : 0;
      this.zentaiSageruValue = data.zentaiSageruValue ? data.zentaiSageruValue : 0;
      this.ingredientDetailHidariValue = data.ingredientDetailHidariValue ? data.ingredientDetailHidariValue : 0;
      this.ingredientDetailMigiValue = data.ingredientDetailMigiValue ? data.ingredientDetailMigiValue : 0;
      this.ingredientPartHidariValue = data.ingredientPartHidariValue ? data.ingredientPartHidariValue : 0;
      this.ingredientPartMigiValue = data.ingredientPartMigiValue ? data.ingredientPartMigiValue : 0;
      this.preparationDetailHidariValue = data.preparationDetailHidariValue ? data.preparationDetailHidariValue : 0;
      this.preparationDetailMigiValue = data.preparationDetailMigiValue ? data.preparationDetailMigiValue : 0;
      this.preparationPartHidariValue = data.preparationPartHidariValue ? data.preparationPartHidariValue : 0;
      this.preparationPartMigiValue = data.preparationPartMigiValue ? data.preparationPartMigiValue : 0;
      this.seasoningDetailHidariValue = data.seasoningDetailHidariValue ? data.seasoningDetailHidariValue : 0;
      this.seasoningDetailMigiValue = data.seasoningDetailMigiValue ? data.seasoningDetailMigiValue : 0;
      this.seasoningPartHidariValue = data.seasoningPartHidariValue ? data.seasoningPartHidariValue : 0;
      this.seasoningPartMigiValue = data.seasoningPartMigiValue ? data.seasoningPartMigiValue : 0;
      this.$nextTick(() => {
        if (this.isRender) {
          return;
        } 
        let fontSizeValue = (this.fontSizeUpValue - this.fontSizeDownValue);
        if (fontSizeValue != 0) {
          let material = document.getElementsByClassName("material")[0];
          material.style.fontSize = (fontSizeValue + 32) + "px";
        }
        let fontKankakuValue = (this.fontKankakuUpValue - this.fontKankakuDownValue);
        if (fontKankakuValue > 0) {
          let material = document.getElementsByClassName("material")[0];
          material.style.letterSpacing = fontSizeValue + "px";
        }
        let gyoukanValue = (this.hirogeruValue - this.sebameruValue);
        if (gyoukanValue != 0) {
          gyoukanValue *= 2;
          let iHeights = document.getElementsByClassName("iHeight");
          const prevHeight = iHeights[0].clientHeight;
          for (let iHeight of iHeights) {
            iHeight.style.height = (prevHeight + gyoukanValue) + "px";
          }
        }
        let zentaiJougeValue = (this.zentaiSageruValue - this.zentaiAgeruValue);
        if (zentaiJougeValue) {
          zentaiJougeValue *= 2;
          let iHeight = document.getElementsByClassName("ingredientZentai")[0];
          iHeight.style.top = iHeight.style.top ? (parseInt(iHeight.style.top.replace("px","")) - zentaiJougeValue) + "px" : zentaiJougeValue + "px";

          let sHeight = document.getElementsByClassName("seasonZentai")[0];
          sHeight.style.top = sHeight.style.top ? (parseInt(sHeight.style.top.replace("px","")) - zentaiJougeValue) + "px" : zentaiJougeValue + "px";
        }
        let ingredientDetailValue = (this.ingredientDetailMigiValue - this.ingredientDetailHidariValue);
        if (ingredientDetailValue != 0) {
          ingredientDetailValue *= 2;
          let iWidths = document.getElementsByClassName("ingredientTd");
          const prevWidth = parseInt(iWidths[0].clientWidth) + ingredientDetailValue;
          for (let iWidth of iWidths) {
            iWidth.style.width = prevWidth + "px";
          }
        }
        let ingredientPartValue = (this.ingredientPartMigiValue - this.ingredientPartHidariValue);
        if (ingredientPartValue != 0) {
          ingredientPartValue *= 2;
          let iHeight = document.getElementsByClassName("ingredientPart")[0];
          iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) + ingredientPartValue) + "px" : ingredientPartValue + "px";
        }
        let preparationDetailValue = (this.preparationDetailMigiValue - this.preparationDetailHidariValue);
        if (preparationDetailValue != 0) {
          preparationDetailValue *= 2;
          let iWidths = document.getElementsByClassName("preparationTd");
          const prevWidth = parseInt(iWidths[0].clientWidth) + preparationDetailValue;
          for (let iWidth of iWidths) {
            iWidth.style.width = prevWidth + "px";
          }
        }
        let preparationPartValue = (this.preparationPartMigiValue - this.preparationPartHidariValue);
        if (preparationPartValue != 0) {
          preparationPartValue *= 2;
          let iHeight = document.getElementsByClassName("preparationPart")[0];
          iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) - preparationPartValue) + "px" : preparationPartValue + "px";
        }
        let seasoningDetailValue = (this.seasoningDetailMigiValue - this.seasoningDetailHidariValue);
        if (seasoningDetailValue != 0) {
          seasoningDetailValue *= 2;
          let iWidths = document.getElementsByClassName("seasoningTd");
          const prevWidth = parseInt(iWidths[0].clientWidth) + seasoningDetailValue;
          for (let iWidth of iWidths) {
            iWidth.style.width = prevWidth + "px";
          }
        }
        let seasoningPartValue = (this.seasoningPartMigiValue - this.seasoningPartHidariValue);
        if (seasoningPartValue != 0) {
          seasoningPartValue *= 2;
          let iHeight = document.getElementsByClassName("seasoningPart")[0];
          iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) + seasoningPartValue) + "px" : seasoningPartValue + "px";
        }
        this.isRender = true;
      });
    },
    toFullWidth(str) {
      // 半角英数字を全角に変換
      str = String(str);
      if (str.indexOf("/") > -1) {
        let strs = str.split("/");
        for (let i = 0; i < strs.length; i++) {
          strs[i] = strs[i].replace(/[A-Za-z0-9]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
          });
        }
        return strs[0] + "/" + strs[1];
      } else {
        return str.replace(/[A-Za-z0-9]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
        });
      }
    },
    downloadImage() {
      document.getElementById("downloadImage").style.display = "none";
      document.getElementById("loader").style.display = "grid";
      htmlToImage.toJpeg(document.getElementById('zairyo'))
      .then(function (dataUrl) {
        // 成功時に実行したい処理を記述する
        // 私の場合はダウンロード処理を実行
        const a = document.createElement('a')
        a.download = '材料.jpg';
        a.href = dataUrl;
        a.click();
      document.getElementById("downloadImage").style.display = "grid";
      document.getElementById("loader").style.display = "none";
      });
    },
    fontSizeUp() {
      let material = document.getElementsByClassName("material")[0];
      material.style.fontSize = material.style.fontSize ? (parseInt(material.style.fontSize.replace("px","")) + 1) + "px" : "33px";

      this.fontSizeUpValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { fontSizeUpValue: this.fontSizeUpValue });

    },
    fontSizeDown() {
      let material = document.getElementsByClassName("material")[0];
      material.style.fontSize = material.style.fontSize ? (parseInt(material.style.fontSize.replace("px","")) - 1) + "px" : "31px";

      this.fontSizeDownValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { fontSizeDownValue: this.fontSizeDownValue });
      
    },
    fontKankakuUp() {
      let material = document.getElementsByClassName("material")[0];
      material.style.letterSpacing = material.style.letterSpacing ? (parseInt(material.style.letterSpacing.replace("px","")) + 1) + "px" : "0";

      this.fontKankakuUpValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { fontKankakuUpValue: this.fontKankakuUpValue });

    },
    fontKankakuDown() {
      let material = document.getElementsByClassName("material")[0];
      material.style.letterSpacing = material.style.letterSpacing ? (parseInt(material.style.letterSpacing.replace("px","")) - 1) + "px" : "0";

      this.fontKankakuDownValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { fontKankakuDownValue: this.fontKankakuDownValue });
      
    },
    hirogeru() {
      let iHeights = document.getElementsByClassName("iHeight");
      const prevHeight = iHeights[0].clientHeight;
      for (let iHeight of iHeights) {
        iHeight.style.height = (prevHeight + 2) + "px";
      }

      this.hirogeruValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { hirogeruValue: this.hirogeruValue });
    },
    sebameru() {
      let iHeights = document.getElementsByClassName("iHeight");
      const prevHeight = iHeights[0].clientHeight;
      for (let iHeight of iHeights) {
        iHeight.style.height = (prevHeight - 2) + "px";
      }
      
      this.sebameruValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { sebameruValue: this.sebameruValue });
    },
    zentaiAgeru() {
      let iHeight = document.getElementsByClassName("ingredientZentai")[0];
      iHeight.style.top = iHeight.style.top ? (parseInt(iHeight.style.top.replace("px","")) - 2) + "px" : "-1px";

      let sHeight = document.getElementsByClassName("seasonZentai")[0];
      sHeight.style.top = sHeight.style.top ? (parseInt(sHeight.style.top.replace("px","")) - 2) + "px" : "-1px";

      this.zentaiAgeruValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { zentaiAgeruValue: this.zentaiAgeruValue });
    },
    zentaiSageru() {
      let iHeight = document.getElementsByClassName("ingredientZentai")[0];
      iHeight.style.top = iHeight.style.top ? (parseInt(iHeight.style.top.replace("px","")) + 2) + "px" : "1px";

      let sHeight = document.getElementsByClassName("seasonZentai")[0];
      sHeight.style.top = sHeight.style.top ? (parseInt(sHeight.style.top.replace("px","")) + 2) + "px" : "1px";

      this.zentaiSageruValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { zentaiSageruValue: this.zentaiSageruValue });
    },
    ingredientDetailHidari() {
      let iWidths = document.getElementsByClassName("ingredientTd");
      const prevWidth = parseInt(iWidths[0].clientWidth) - 2;
      for (let iWidth of iWidths) {
        iWidth.style.width = prevWidth + "px";
      }

      this.ingredientDetailHidariValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { ingredientDetailHidariValue: this.ingredientDetailHidariValue });
    },
    ingredientDetailMigi() {
      let iWidths = document.getElementsByClassName("ingredientTd");
      const prevWidth = parseInt(iWidths[0].clientWidth) + 2;
      for (let iWidth of iWidths) {
        iWidth.style.width = prevWidth + "px";
      }

      this.ingredientDetailMigiValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { ingredientDetailMigiValue: this.ingredientDetailMigiValue });
    },
    ingredientPartHidari() {
      let iHeight = document.getElementsByClassName("ingredientPart")[0];
      iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) - 2) + "px" : "-1px";

      this.ingredientPartHidariValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { ingredientPartHidariValue: this.ingredientPartHidariValue });
    },
    ingredientPartMigi() {
      let iHeight = document.getElementsByClassName("ingredientPart")[0];
      iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) + 2) + "px" : "-1px";

      this.ingredientPartMigiValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { ingredientPartMigiValue: this.ingredientPartMigiValue });
    },
    preparationDetailHidari() {
      let iWidths = document.getElementsByClassName("preparationTd");
      const prevWidth = parseInt(iWidths[0].clientWidth) - 2;
      for (let iWidth of iWidths) {
        iWidth.style.width = prevWidth + "px";
      }

      this.preparationDetailHidariValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { preparationDetailHidariValue: this.preparationDetailHidariValue });
    },
    preparationDetailMigi() {
      let iWidths = document.getElementsByClassName("preparationTd");
      const prevWidth = parseInt(iWidths[0].clientWidth) + 2;
      for (let iWidth of iWidths) {
        iWidth.style.width = prevWidth + "px";
      }

      this.preparationDetailMigiValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { preparationDetailMigiValue: this.preparationDetailMigiValue });
    },
    preparationZentaiHidari() {
      let iWidths = document.getElementsByClassName("kakko");
      const prevWidth = parseInt(iWidths[0].clientWidth) - 2;
      for (let iWidth of iWidths) {
        iWidth.style.width = prevWidth + "px";
      }

      // this.preparationDetailHidariValue += 1;
      // updateDoc(doc(firebase.db, "Cookings2", this.id), { preparationDetailHidariValue: this.preparationDetailHidariValue });
    },
    preparationZentaiMigi() {
      let iWidths = document.getElementsByClassName("kakko");
      const prevWidth = parseInt(iWidths[0].clientWidth) + 2;
      for (let iWidth of iWidths) {
        iWidth.style.width = prevWidth + "px";
      }

      // this.preparationDetailMigiValue += 1;
      // updateDoc(doc(firebase.db, "Cookings2", this.id), { preparationDetailMigiValue: this.preparationDetailMigiValue });
    },
    preparationPartHidari() {
      let iHeight = document.getElementsByClassName("preparationPart")[0];
      iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) - 2) + "px" : "-1px";

      this.preparationPartHidariValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { preparationPartHidariValue: this.preparationPartHidariValue });
    },
    preparationPartMigi() {
      let iHeight = document.getElementsByClassName("preparationPart")[0];
      iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) + 2) + "px" : "-1px";

      this.preparationPartMigiValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { preparationPartMigiValue: this.preparationPartMigiValue });
    },
    seasoningDetailHidari() {
      let iWidths = document.getElementsByClassName("seasoningTd");
      const prevWidth = parseInt(iWidths[0].clientWidth) - 2;
      for (let iWidth of iWidths) {
        iWidth.style.width = prevWidth + "px";
      }

      this.seasoningDetailHidariValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { seasoningDetailHidariValue: this.seasoningDetailHidariValue });
    },
    seasoningDetailMigi() {
      let iWidths = document.getElementsByClassName("seasoningTd");
      const prevWidth = parseInt(iWidths[0].clientWidth) + 2;
      for (let iWidth of iWidths) {
        iWidth.style.width = prevWidth + "px";
      }

      this.seasoningDetailMigiValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { seasoningDetailMigiValue: this.seasoningDetailMigiValue });
    },
    seasoningPartHidari() {
      let iHeight = document.getElementsByClassName("seasoningPart")[0];
      iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) - 2) + "px" : "-1px";

      this.seasoningPartHidariValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { seasoningPartHidariValue: this.seasoningPartHidariValue });
    },
    seasoningPartMigi() {
      let iHeight = document.getElementsByClassName("seasoningPart")[0];
      iHeight.style.left = iHeight.style.left ? (parseInt(iHeight.style.left.replace("px","")) + 2) + "px" : "-1px";

      this.seasoningPartMigiValue += 1;
      updateDoc(doc(firebase.db, "Cookings2", this.id), { seasoningPartMigiValue: this.seasoningPartMigiValue });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../public/css/markdown.css';

@font-face {
  font-family: 'chihaya';
  src: url('../../public/fonts/nchihaya.ttf') format('truetype');
}
#zairyo {
  background-color: #F7F0DB;
  font-family: 'chihaya';
  text-align: left;
  width: 1600px;
  height: 900px;
}
#previewPart {
  position: relative;
}
#kakoi {
  width: 1280px;
  height: 720px;
}
#kakoi img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.materialTitle {
  font-size: 4rem;
  line-height: 8rem;
  text-align: center;
  margin: 0;
}
.material {
  width: 90%;
  display: inline-flex;
  position: relative;
  font-size: 32px;
  background: #ededed;
  box-shadow: 0px 0px 0px 5px #ededed;
  border: dashed 2px white;
  margin: 0 63px;
  height: 700px;
}
.material:after {
  position: absolute;
  content: '';
  right: -7px;
  top: -7px;
  border-width: 0 15px 15px 0;
  border-style: solid;
  border-color: #d9d9d9 #fff #d9d9d9;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15);
}
.title {
  font-size: 3rem;
  line-height: 9rem;
}
.material p {
  margin: 0; 
  padding: 0;
}
.ingredient {
  position: relative;
  width: 50%;
}
.ingredientPart {
  position: absolute;
  width: 100%;
  top: 0%;
  left: 0%;
  padding-left: 2vw;
}
.seasoning {
  position: relative;
  width: 50%;
}
.seasoningPart {
  position: absolute;
  width: 100%;
  top: 0%;
  left: 0%;
  padding-left: 2vw;
}
.ingredientTable,
.seasoningTable {
  margin-bottom: 0;
  width: 100%;
  padding-left: 2vw;
}
.ingredientTd {
  width: 50%;
  padding: 0;
}
.preparationTd {
  width: 50%;
  padding: 0;
}
.seasoningTd {
  width: 50%;
  padding: 0;
}
/* preparationPart */
.preparationPart {
  width: 100%;
  padding-left: 1vw;
}
.kakko {
  position: relative;
  margin: 0.4vw 0 1vw 5vw;
  width: 84%;
}
.shitaaji {
  position: absolute;
  font-size: 0.9em;
  left: 2.5vw;
  z-index: 999;
  top: 3.4vw;
}
.downloadImage {
  position: absolute;
  width: 5vw;
  height: 1.2vw;
  border: none;
  background-color: unset;
  top: 10px;
  right: 30px;
}
.frame-box-002 {
	position: relative;
}
.frame-box-002::before, .frame-box-002::after {
	content: '';
	width: 15px;
	height: 100%;
	position: absolute;
}
.frame-box-002::before {
	border-left: solid 3px #646E16;
	border-top: solid 3px #646E16;
	border-bottom: solid 3px #646E16;
  border-radius: 3px;
	top: 0;
	left: 0;
}
.frame-box-002::after {
	border-right: solid 3px #646E16;
	border-top: solid 3px #646E16;
	border-bottom: solid 3px #646E16;
  border-radius: 3px;
	top: 0;
	right: 0;
}
.iHeight, .sHeight {
  height: 50px;
}
.yajirusiWrap {
  position: absolute;
  display: grid;
  top: 10vw;
  right: 27px;
}
.yajirusiWrap table {
  border-collapse: collapse;
}
.yajirusiWrap td {
  border: 1px solid black;
  height: 3vw;
}
.yajirusiWrap td:first-child {
  background-color: #eeeeee;
  font-weight: bold;
  padding: 0 10px;
}
.yajirusi {
  border: none;
  background-color: unset;
  font-size: 2vw;
  cursor: pointer;
}
.loader {
  font-size: 10px;
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  position: absolute;
  text-indent: -9999em;
  animation: mulShdSpin-964f2d40 1.1s infinite ease;
  transform: translateZ(0);
  display: none;
  top: 43px;
  right: 68px;
}
.ingredientZentai {
  position: relative;
  top: 0px;
}
.seasonZentai {
  position: relative;
  top: 0px;
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(75,75,75, 0.2), 2.5em 0em 0 0em rgba(75,75,75, 0.2), 1.75em 1.75em 0 0em rgba(75,75,75, 0.2), 0em 2.5em 0 0em rgba(75,75,75, 0.2), -1.8em 1.8em 0 0em rgba(75,75,75, 0.2), -2.6em 0em 0 0em rgba(75,75,75, 0.5), -1.8em -1.8em 0 0em rgba(75,75,75, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75,75,75, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(75,75,75, 0.2), 1.75em 1.75em 0 0em rgba(75,75,75, 0.2), 0em 2.5em 0 0em rgba(75,75,75, 0.2), -1.8em 1.8em 0 0em rgba(75,75,75, 0.2), -2.6em 0em 0 0em rgba(75,75,75, 0.2), -1.8em -1.8em 0 0em rgba(75,75,75, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(75,75,75, 0.5), 1.8em -1.8em 0 0em rgba(75,75,75, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(75,75,75, 0.2), 0em 2.5em 0 0em rgba(75,75,75, 0.2), -1.8em 1.8em 0 0em rgba(75,75,75, 0.2), -2.6em 0em 0 0em rgba(75,75,75, 0.2), -1.8em -1.8em 0 0em rgba(75,75,75, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75,75,75, 0.2), 1.8em -1.8em 0 0em rgba(75,75,75, 0.5), 2.5em 0em 0 0em rgba(75,75,75, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(75,75,75, 0.2), -1.8em 1.8em 0 0em rgba(75,75,75, 0.2), -2.6em 0em 0 0em rgba(75,75,75, 0.2), -1.8em -1.8em 0 0em rgba(75,75,75, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(75,75,75, 0.2), 1.8em -1.8em 0 0em rgba(75,75,75, 0.2), 2.5em 0em 0 0em rgba(75,75,75, 0.5), 1.75em 1.75em 0 0em rgba(75,75,75, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(75,75,75, 0.2), -2.6em 0em 0 0em rgba(75,75,75, 0.2), -1.8em -1.8em 0 0em rgba(75,75,75, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75,75,75, 0.2), 1.8em -1.8em 0 0em rgba(75,75,75, 0.2), 2.5em 0em 0 0em rgba(75,75,75, 0.2), 1.75em 1.75em 0 0em rgba(75,75,75, 0.5), 0em 2.5em 0 0em rgba(75,75,75, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(75,75,75, 0.2), -1.8em -1.8em 0 0em rgba(75,75,75, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(75,75,75, 0.2), 1.8em -1.8em 0 0em rgba(75,75,75, 0.2), 2.5em 0em 0 0em rgba(75,75,75, 0.2), 1.75em 1.75em 0 0em rgba(75,75,75, 0.2), 0em 2.5em 0 0em rgba(75,75,75, 0.5), -1.8em 1.8em 0 0em rgba(75,75,75, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(75,75,75, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75,75,75, 0.2), 1.8em -1.8em 0 0em rgba(75,75,75, 0.2), 2.5em 0em 0 0em rgba(75,75,75, 0.2), 1.75em 1.75em 0 0em rgba(75,75,75, 0.2), 0em 2.5em 0 0em rgba(75,75,75, 0.2), -1.8em 1.8em 0 0em rgba(75,75,75, 0.5), -2.6em 0em 0 0em rgba(75,75,75, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

@media screen and (max-width:640px) {
  .loader {
    position: fixed;
    width: 0.5rem;
    height: 0.5rem;
    top: 3.5rem;
    right: unset;
    left: 5.8rem;
    font-size: 8px;
  }
  .yajirusiWrap {
    position: fixed;
    top: 5vw;
    left: 3vw;
    right: unset;
  }
  .yajirusi {
    font-size: 2rem;
  }
  .downloadImage {
    position: fixed;
    width: 4rem;
    height: auto;
    top: 2rem;
    right: unset;
    left: 4rem;
  }
}
</style>
