import { createApp } from 'vue'
import App from './App.vue'
import router from './route/router.js'

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

import firebase from './config/firebase.js'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

import VueCarousel from '@chenfengyuan/vue-carousel'

import './registerServiceWorker'

const db = getFirestore(firebase);

const storage = getStorage(firebase);

export default { db, storage };

createApp(App)
    .use(firebase)
    .use(mavonEditor)
    .use(router)
    .component(VueCarousel.name, VueCarousel)
    .mount("#app");