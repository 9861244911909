<template>
  <div class="assemblyDialog">
    <div v-for="(value, index) in categories" :key="index" class="values">
      <div v-for="(value2, index2) in value.values" :class="value.color" class="value" :key="index2" @click="dblclick(value2)" draggable="true" @dragstart="onDragStart($event, value2)">
        {{ value2 }}
      </div>
    </div>
  </div>
  <div class="materialDialog">
    <div class="numOfPeople">
      <input id="numOfPeople" type="text" v-model="numOfPeople">
      <label for="numOfPeople">分</label>
    </div>
    <div class="materialWrap">
      <div class="ingredients">
        <p class="partTitle">材料</p>
        <table>
          <tr>
            <th>材料名</th>
            <th colspan="2">分量</th>
            <th>下味</th>
          </tr>
          <tr>
            <td><input type="text" id="iName_1"     class="name"    @keydown.up.exact.prevent="onUp('iName_1')"     @keydown.down.exact.prevent="onDown('iName_1')"     @keydown.left.exact.prevent="onLeft('iName_1')"     @keydown.right.exact.prevent="onRight('iName_1')"     @keydown.tab.exact.prevent="onTab('iName_1')"     @keydown.shift.tab.exact.prevent="onShiftTab('iName_1')"     @click="inputFocus($event)" v-model="iName_1"></td>
            <td><input type="text" id="iWeight_1_1" class="weight1" @keydown.up.exact.prevent="onUp('iWeight_1_1')" @keydown.down.exact.prevent="onDown('iWeight_1_1')" @keydown.left.exact.prevent="onLeft('iWeight_1_1')" @keydown.right.exact.prevent="onRight('iWeight_1_1')" @keydown.tab.exact.prevent="onTab('iWeight_1_1')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_1_1')" @click="inputFocus($event)" v-model="iWeight_1_1"></td>
            <td><input type="text" id="iWeight_1_2" class="weight2" @keydown.up.exact.prevent="onUp('iWeight_1_2')" @keydown.down.exact.prevent="onDown('iWeight_1_2')" @keydown.left.exact.prevent="onLeft('iWeight_1_2')" @keydown.right.exact.prevent="onRight('iWeight_1_2')" @keydown.tab.exact.prevent="onTab('iWeight_1_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_1_2')" @click="inputFocus($event)" v-model="iWeight_1_2"></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <span v-if="iCheck_2 == true" class="nest">∟</span>
              <input v-if="iCheck_2 == true" type="text" id="iName_2" class="name nestInput" @keydown.up.exact.prevent="onUp('iName_2')" @keydown.down.exact.prevent="onDown('iName_2')" @keydown.left.exact.prevent="onLeft('iName_2')" @keydown.right.exact.prevent="onRight('iName_2')" @keydown.tab.exact.prevent="onTab('iName_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_2')" @click="inputFocus($event)" v-model="iName_2">
              <input v-else                  type="text" id="iName_2" class="name"           @keydown.up.exact.prevent="onUp('iName_2')" @keydown.down.exact.prevent="onDown('iName_2')" @keydown.left.exact.prevent="onLeft('iName_2')" @keydown.right.exact.prevent="onRight('iName_2')" @keydown.tab.exact.prevent="onTab('iName_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_2')" @click="inputFocus($event)" v-model="iName_2">
            </td>
            <td><input type="text" id="iWeight_2_1" class="weight1" @keydown.up.exact.prevent="onUp('iWeight_2_1')" @keydown.down.exact.prevent="onDown('iWeight_2_1')" @keydown.left.exact.prevent="onLeft('iWeight_2_1')" @keydown.right.exact.prevent="onRight('iWeight_2_1')" @keydown.tab.exact.prevent="onTab('iWeight_2_1')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_2_1')" @click="inputFocus($event)" v-model="iWeight_2_1"></td>
            <td><input type="text" id="iWeight_2_2" class="weight2" @keydown.up.exact.prevent="onUp('iWeight_2_2')" @keydown.down.exact.prevent="onDown('iWeight_2_2')" @keydown.left.exact.prevent="onLeft('iWeight_2_2')" @keydown.right.exact.prevent="onRight('iWeight_2_2')" @keydown.tab.exact.prevent="onTab('iWeight_2_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_2_2')" @click="inputFocus($event)" v-model="iWeight_2_2"></td>
            <td><input type="checkbox" name="iCheck_2" id="iCheck_2" v-model="iCheck_2"></td>
          </tr>
          <tr>
            <td>
              <span v-if="iCheck_3 == true" class="nest">∟</span>
              <input v-if="iCheck_3 == true" type="text" id="iName_3" class="name nestInput" @keydown.up.exact.prevent="onUp('iName_3')" @keydown.down.exact.prevent="onDown('iName_3')" @keydown.left.exact.prevent="onLeft('iName_3')" @keydown.right.exact.prevent="onRight('iName_3')" @keydown.tab.exact.prevent="onTab('iName_3')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_3')" @click="inputFocus($event)" v-model="iName_3">
              <input v-else                  type="text" id="iName_3" class="name"           @keydown.up.exact.prevent="onUp('iName_3')" @keydown.down.exact.prevent="onDown('iName_3')" @keydown.left.exact.prevent="onLeft('iName_3')" @keydown.right.exact.prevent="onRight('iName_3')" @keydown.tab.exact.prevent="onTab('iName_3')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_3')" @click="inputFocus($event)" v-model="iName_3">
            </td>
            <td><input type="text" id="iWeight_3_1" class="weight1" @keydown.up.exact.prevent="onUp('iWeight_3_1')" @keydown.down.exact.prevent="onDown('iWeight_3_1')" @keydown.left.exact.prevent="onLeft('iWeight_3_1')" @keydown.right.exact.prevent="onRight('iWeight_3_1')" @keydown.tab.exact.prevent="onTab('iWeight_3_1')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_3_1')" @click="inputFocus($event)" v-model="iWeight_3_1"></td>
            <td><input type="text" id="iWeight_3_2" class="weight2" @keydown.up.exact.prevent="onUp('iWeight_3_2')" @keydown.down.exact.prevent="onDown('iWeight_3_2')" @keydown.left.exact.prevent="onLeft('iWeight_3_2')" @keydown.right.exact.prevent="onRight('iWeight_3_2')" @keydown.tab.exact.prevent="onTab('iWeight_3_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_3_2')" @click="inputFocus($event)" v-model="iWeight_3_2"></td>
            <td><input type="checkbox" name="iCheck_3" id="iCheck_3" v-model="iCheck_3"></td>
          </tr>
          <tr>
            <td>
              <span v-if="iCheck_4 == true" class="nest">∟</span>
              <input v-if="iCheck_4 == true" type="text" id="iName_4" class="name nestInput" @keydown.up.exact.prevent="onUp('iName_4')" @keydown.down.exact.prevent="onDown('iName_4')" @keydown.left.exact.prevent="onLeft('iName_4')" @keydown.right.exact.prevent="onRight('iName_4')" @keydown.tab.exact.prevent="onTab('iName_4')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_4')" @click="inputFocus($event)" v-model="iName_4">
              <input v-else                  type="text" id="iName_4" class="name"           @keydown.up.exact.prevent="onUp('iName_4')" @keydown.down.exact.prevent="onDown('iName_4')" @keydown.left.exact.prevent="onLeft('iName_4')" @keydown.right.exact.prevent="onRight('iName_4')" @keydown.tab.exact.prevent="onTab('iName_4')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_4')" @click="inputFocus($event)" v-model="iName_4">
            </td>
            <td><input type="text" id="iWeight_4_1" class="weight1" @keydown.up.exact.prevent="onUp('iWeight_4_1')" @keydown.down.exact.prevent="onDown('iWeight_4_1')" @keydown.left.exact.prevent="onLeft('iWeight_4_1')" @keydown.right.exact.prevent="onRight('iWeight_4_1')" @keydown.tab.exact.prevent="onTab('iWeight_4_1')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_4_1')" @click="inputFocus($event)" v-model="iWeight_4_1"></td>
            <td><input type="text" id="iWeight_4_2" class="weight2" @keydown.up.exact.prevent="onUp('iWeight_4_2')" @keydown.down.exact.prevent="onDown('iWeight_4_2')" @keydown.left.exact.prevent="onLeft('iWeight_4_2')" @keydown.right.exact.prevent="onRight('iWeight_4_2')" @keydown.tab.exact.prevent="onTab('iWeight_4_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_4_2')" @click="inputFocus($event)" v-model="iWeight_4_2"></td>
            <td><input type="checkbox" name="iCheck_4" id="iCheck_4" v-model="iCheck_4"></td>
          </tr>
          <tr>
            <td>
              <span v-if="iCheck_5 == true" class="nest">∟</span>
              <input v-if="iCheck_5 == true" type="text" id="iName_5" class="name nestInput" @keydown.up.exact.prevent="onUp('iName_5')" @keydown.down.exact.prevent="onDown('iName_5')" @keydown.left.exact.prevent="onLeft('iName_5')" @keydown.right.exact.prevent="onRight('iName_5')" @keydown.tab.exact.prevent="onTab('iName_5')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_5')" @click="inputFocus($event)" v-model="iName_5">
              <input v-else                  type="text" id="iName_5" class="name"           @keydown.up.exact.prevent="onUp('iName_5')" @keydown.down.exact.prevent="onDown('iName_5')" @keydown.left.exact.prevent="onLeft('iName_5')" @keydown.right.exact.prevent="onRight('iName_5')" @keydown.tab.exact.prevent="onTab('iName_5')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_5')" @click="inputFocus($event)" v-model="iName_5">
            </td>
            <td><input type="text" id="iWeight_5_1" class="weight1" @keydown.up.exact.prevent="onUp('iWeight_5_1')" @keydown.down.exact.prevent="onDown('iWeight_5_1')" @keydown.left.exact.prevent="onLeft('iWeight_5_1')" @keydown.right.exact.prevent="onRight('iWeight_5_1')" @keydown.tab.exact.prevent="onTab('iWeight_5_1')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_5_1')" @click="inputFocus($event)" v-model="iWeight_5_1"></td>
            <td><input type="text" id="iWeight_5_2" class="weight2" @keydown.up.exact.prevent="onUp('iWeight_5_2')" @keydown.down.exact.prevent="onDown('iWeight_5_2')" @keydown.left.exact.prevent="onLeft('iWeight_5_2')" @keydown.right.exact.prevent="onRight('iWeight_5_2')" @keydown.tab.exact.prevent="onTab('iWeight_5_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_5_2')" @click="inputFocus($event)" v-model="iWeight_5_2"></td>
            <td><input type="checkbox" name="iCheck_5" id="iCheck_5" v-model="iCheck_5"></td>
          </tr>
          <tr>
            <td>
              <span v-if="iCheck_6 == true" class="nest">∟</span>
              <input v-if="iCheck_6 == true" type="text" id="iName_6" class="name nestInput" @keydown.up.exact.prevent="onUp('iName_6')" @keydown.down.exact.prevent="onDown('iName_6')" @keydown.left.exact.prevent="onLeft('iName_6')" @keydown.right.exact.prevent="onRight('iName_6')" @keydown.tab.exact.prevent="onTab('iName_6')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_6')" @click="inputFocus($event)" v-model="iName_6">
              <input v-else                  type="text" id="iName_6" class="name"           @keydown.up.exact.prevent="onUp('iName_6')" @keydown.down.exact.prevent="onDown('iName_6')" @keydown.left.exact.prevent="onLeft('iName_6')" @keydown.right.exact.prevent="onRight('iName_6')" @keydown.tab.exact.prevent="onTab('iName_6')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_6')" @click="inputFocus($event)" v-model="iName_6">
            </td>
            <td><input type="text" id="iWeight_6_1" class="weight1" @keydown.up.exact.prevent="onUp('iWeight_6_1')" @keydown.down.exact.prevent="onDown('iWeight_6_1')" @keydown.left.exact.prevent="onLeft('iWeight_6_1')" @keydown.right.exact.prevent="onRight('iWeight_6_1')" @keydown.tab.exact.prevent="onTab('iWeight_6_1')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_6_1')" @click="inputFocus($event)" v-model="iWeight_6_1"></td>
            <td><input type="text" id="iWeight_6_2" class="weight2" @keydown.up.exact.prevent="onUp('iWeight_6_2')" @keydown.down.exact.prevent="onDown('iWeight_6_2')" @keydown.left.exact.prevent="onLeft('iWeight_6_2')" @keydown.right.exact.prevent="onRight('iWeight_6_2')" @keydown.tab.exact.prevent="onTab('iWeight_6_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_6_2')" @click="inputFocus($event)" v-model="iWeight_6_2"></td>
            <td><input type="checkbox" name="iCheck_6" id="iCheck_6" v-model="iCheck_6"></td>
          </tr>
          <tr>
            <td>
              <span v-if="iCheck_7 == true" class="nest">∟</span>
              <input v-if="iCheck_7 == true" type="text" id="iName_7" class="name nestInput" @keydown.up.exact.prevent="onUp('iName_7')" @keydown.down.exact.prevent="onDown('iName_7')" @keydown.left.exact.prevent="onLeft('iName_7')" @keydown.right.exact.prevent="onRight('iName_7')" @keydown.tab.exact.prevent="onTab('iName_7')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_7')" @click="inputFocus($event)" v-model="iName_7">
              <input v-else                  type="text" id="iName_7" class="name"           @keydown.up.exact.prevent="onUp('iName_7')" @keydown.down.exact.prevent="onDown('iName_7')" @keydown.left.exact.prevent="onLeft('iName_7')" @keydown.right.exact.prevent="onRight('iName_7')" @keydown.tab.exact.prevent="onTab('iName_7')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_7')" @click="inputFocus($event)" v-model="iName_7">
            </td>
            <td><input type="text" id="iWeight_7_1" class="weight1" @keydown.up.exact.prevent="onUp('iWeight_7_1')" @keydown.down.exact.prevent="onDown('iWeight_7_1')" @keydown.left.exact.prevent="onLeft('iWeight_7_1')" @keydown.right.exact.prevent="onRight('iWeight_7_1')" @keydown.tab.exact.prevent="onTab('iWeight_7_1')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_7_1')" @click="inputFocus($event)" v-model="iWeight_7_1"></td>
            <td><input type="text" id="iWeight_7_2" class="weight2" @keydown.up.exact.prevent="onUp('iWeight_7_2')" @keydown.down.exact.prevent="onDown('iWeight_7_2')" @keydown.left.exact.prevent="onLeft('iWeight_7_2')" @keydown.right.exact.prevent="onRight('iWeight_7_2')" @keydown.tab.exact.prevent="onTab('iWeight_7_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_7_2')" @click="inputFocus($event)" v-model="iWeight_7_2"></td>
            <td><input type="checkbox" name="iCheck_7" id="iCheck_7" v-model="iCheck_7"></td>
          </tr>
          <tr>
            <td>
              <span v-if="iCheck_8 == true" class="nest">∟</span>
              <input v-if="iCheck_8 == true" type="text" id="iName_8" class="name nestInput" @keydown.up.exact.prevent="onUp('iName_8')" @keydown.down.exact.prevent="onDown('iName_8')" @keydown.left.exact.prevent="onLeft('iName_8')" @keydown.right.exact.prevent="onRight('iName_8')" @keydown.tab.exact.prevent="onTab('iName_8')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_8')" @click="inputFocus($event)" v-model="iName_8">
              <input v-else                  type="text" id="iName_8" class="name"           @keydown.up.exact.prevent="onUp('iName_8')" @keydown.down.exact.prevent="onDown('iName_8')" @keydown.left.exact.prevent="onLeft('iName_8')" @keydown.right.exact.prevent="onRight('iName_8')" @keydown.tab.exact.prevent="onTab('iName_8')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_8')" @click="inputFocus($event)" v-model="iName_8">
            </td>
            <td><input type="text" id="iWeight_8_1" class="weight1" @keydown.up.exact.prevent="onUp('iWeight_8_1')" @keydown.down.exact.prevent="onDown('iWeight_8_1')" @keydown.left.exact.prevent="onLeft('iWeight_8_1')" @keydown.right.exact.prevent="onRight('iWeight_8_1')" @keydown.tab.exact.prevent="onTab('iWeight_8_1')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_8_1')" @click="inputFocus($event)" v-model="iWeight_8_1"></td>
            <td><input type="text" id="iWeight_8_2" class="weight2" @keydown.up.exact.prevent="onUp('iWeight_8_2')" @keydown.down.exact.prevent="onDown('iWeight_8_2')" @keydown.left.exact.prevent="onLeft('iWeight_8_2')" @keydown.right.exact.prevent="onRight('iWeight_8_2')" @keydown.tab.exact.prevent="onTab('iWeight_8_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_8_2')" @click="inputFocus($event)" v-model="iWeight_8_2"></td>
            <td><input type="checkbox" name="iCheck_8" id="iCheck_8" v-model="iCheck_8"></td>
          </tr>
          <tr>
            <td>
              <span v-if="iCheck_9 == true" class="nest">∟</span>
              <input v-if="iCheck_9 == true" type="text" id="iName_9" class="name nestInput" @keydown.up.exact.prevent="onUp('iName_9')" @keydown.down.exact.prevent="onDown('iName_9')" @keydown.left.exact.prevent="onLeft('iName_9')" @keydown.right.exact.prevent="onRight('iName_9')" @keydown.tab.exact.prevent="onTab('iName_9')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_9')" @click="inputFocus($event)" v-model="iName_9">
              <input v-else                  type="text" id="iName_9" class="name"           @keydown.up.exact.prevent="onUp('iName_9')" @keydown.down.exact.prevent="onDown('iName_9')" @keydown.left.exact.prevent="onLeft('iName_9')" @keydown.right.exact.prevent="onRight('iName_9')" @keydown.tab.exact.prevent="onTab('iName_9')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_9')" @click="inputFocus($event)" v-model="iName_9">
            </td>
            <td><input type="text" id="iWeight_9_1" class="weight1" @keydown.up.exact.prevent="onUp('iWeight_9_1')" @keydown.down.exact.prevent="onDown('iWeight_9_1')" @keydown.left.exact.prevent="onLeft('iWeight_9_1')" @keydown.right.exact.prevent="onRight('iWeight_9_1')" @keydown.tab.exact.prevent="onTab('iWeight_9_1')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_9_1')" @click="inputFocus($event)" v-model="iWeight_9_1"></td>
            <td><input type="text" id="iWeight_9_2" class="weight2" @keydown.up.exact.prevent="onUp('iWeight_9_2')" @keydown.down.exact.prevent="onDown('iWeight_9_2')" @keydown.left.exact.prevent="onLeft('iWeight_9_2')" @keydown.right.exact.prevent="onRight('iWeight_9_2')" @keydown.tab.exact.prevent="onTab('iWeight_9_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_9_2')" @click="inputFocus($event)" v-model="iWeight_9_2"></td>
            <td><input type="checkbox" name="iCheck_9" id="iCheck_9" v-model="iCheck_9"></td>
          </tr>
          <tr>
            <td>
              <span v-if="iCheck_10 == true" class="nest">∟</span>
              <input v-if="iCheck_10 == true" type="text" id="iName_10" class="name nestInput" @keydown.up.exact.prevent="onUp('iName_10')" @keydown.down.exact.prevent="onDown('iName_10')" @keydown.left.exact.prevent="onLeft('iName_10')" @keydown.right.exact.prevent="onRight('iName_10')" @keydown.tab.exact.prevent="onTab('iName_10')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_10')" @click="inputFocus($event)" v-model="iName_10">
              <input v-else                  type="text" id="iName_10" class="name"           @keydown.up.exact.prevent="onUp('iName_10')" @keydown.down.exact.prevent="onDown('iName_10')" @keydown.left.exact.prevent="onLeft('iName_10')" @keydown.right.exact.prevent="onRight('iName_10')" @keydown.tab.exact.prevent="onTab('iName_10')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_10')" @click="inputFocus($event)" v-model="iName_10">
            </td>
            <td><input type="text" id="iWeight_10_1" class="weight1" @keydown.up.exact.prevent="onUp('iWeight_10_1')" @keydown.down.exact.prevent="onDown('iWeight_10_1')" @keydown.left.exact.prevent="onLeft('iWeight_10_1')" @keydown.right.exact.prevent="onRight('iWeight_10_1')" @keydown.tab.exact.prevent="onTab('iWeight_10_1')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_10_1')" @click="inputFocus($event)" v-model="iWeight_10_1"></td>
            <td><input type="text" id="iWeight_10_2" class="weight2" @keydown.up.exact.prevent="onUp('iWeight_10_2')" @keydown.down.exact.prevent="onDown('iWeight_10_2')" @keydown.left.exact.prevent="onLeft('iWeight_10_2')" @keydown.right.exact.prevent="onRight('iWeight_10_2')" @keydown.tab.exact.prevent="onTab('iWeight_10_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_10_2')" @click="inputFocus($event)" v-model="iWeight_10_2"></td>
            <td><input type="checkbox" name="iCheck_10" id="iCheck_10" v-model="iCheck_10"></td>
          </tr>
          <tr>
            <td>
              <span v-if="iCheck_11 == true" class="nest">∟</span>
              <input v-if="iCheck_11 == true" type="text" id="iName_11" class="name nestInput" @keydown.up.exact.prevent="onUp('iName_11')" @keydown.down.exact.prevent="onDown('iName_11')" @keydown.left.exact.prevent="onLeft('iName_11')" @keydown.right.exact.prevent="onRight('iName_11')" @keydown.tab.exact.prevent="onTab('iName_11')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_11')" @click="inputFocus($event)" v-model="iName_11">
              <input v-else                  type="text" id="iName_11" class="name"           @keydown.up.exact.prevent="onUp('iName_11')" @keydown.down.exact.prevent="onDown('iName_11')" @keydown.left.exact.prevent="onLeft('iName_11')" @keydown.right.exact.prevent="onRight('iName_11')" @keydown.tab.exact.prevent="onTab('iName_11')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_11')" @click="inputFocus($event)" v-model="iName_11">
            </td>
            <td><input type="text" id="iWeight_11_1" class="weight1" @keydown.up.exact.prevent="onUp('iWeight_11_1')" @keydown.down.exact.prevent="onDown('iWeight_11_1')" @keydown.left.exact.prevent="onLeft('iWeight_11_1')" @keydown.right.exact.prevent="onRight('iWeight_11_1')" @keydown.tab.exact.prevent="onTab('iWeight_11_1')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_11_1')" @click="inputFocus($event)" v-model="iWeight_11_1"></td>
            <td><input type="text" id="iWeight_11_2" class="weight2" @keydown.up.exact.prevent="onUp('iWeight_11_2')" @keydown.down.exact.prevent="onDown('iWeight_11_2')" @keydown.left.exact.prevent="onLeft('iWeight_11_2')" @keydown.right.exact.prevent="onRight('iWeight_11_2')" @keydown.tab.exact.prevent="onTab('iWeight_11_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_11_2')" @click="inputFocus($event)" v-model="iWeight_11_2"></td>
            <td><input type="checkbox" name="iCheck_11" id="iCheck_11" v-model="iCheck_11"></td>
          </tr>
          <tr>
            <td>
              <span v-if="iCheck_12 == true" class="nest">∟</span>
              <input v-if="iCheck_12 == true" type="text" id="iName_12" class="name nestInput" @keydown.up.exact.prevent="onUp('iName_12')" @keydown.down.exact.prevent="onDown('iName_12')" @keydown.left.exact.prevent="onLeft('iName_12')" @keydown.right.exact.prevent="onRight('iName_12')" @keydown.tab.exact.prevent="onTab('iName_12')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_12')" @click="inputFocus($event)" v-model="iName_12">
              <input v-else                  type="text" id="iName_12" class="name"           @keydown.up.exact.prevent="onUp('iName_12')" @keydown.down.exact.prevent="onDown('iName_12')" @keydown.left.exact.prevent="onLeft('iName_12')" @keydown.right.exact.prevent="onRight('iName_12')" @keydown.tab.exact.prevent="onTab('iName_12')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_12')" @click="inputFocus($event)" v-model="iName_12">
            </td>
            <td><input type="text" id="iWeight_12_1" class="weight1" @keydown.up.exact.prevent="onUp('iWeight_12_1')" @keydown.down.exact.prevent="onDown('iWeight_12_1')" @keydown.left.exact.prevent="onLeft('iWeight_12_1')" @keydown.right.exact.prevent="onRight('iWeight_12_1')" @keydown.tab.exact.prevent="onTab('iWeight_12_1')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_12_1')" @click="inputFocus($event)" v-model="iWeight_12_1"></td>
            <td><input type="text" id="iWeight_12_2" class="weight2" @keydown.up.exact.prevent="onUp('iWeight_12_2')" @keydown.down.exact.prevent="onDown('iWeight_12_2')" @keydown.left.exact.prevent="onLeft('iWeight_12_2')" @keydown.right.exact.prevent="onRight('iWeight_12_2')" @keydown.tab.exact.prevent="onTab('iWeight_12_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_12_2')" @click="inputFocus($event)" v-model="iWeight_12_2"></td>
            <td><input type="checkbox" name="iCheck_12" id="iCheck_12" v-model="iCheck_12"></td>
          </tr>
          <tr>
            <td>
              <span v-if="iCheck_13 == true" class="nest">∟</span>
              <input v-if="iCheck_13 == true" type="text" id="iName_13" class="name nestInput" @keydown.up.exact.prevent="onUp('iName_13')" @keydown.down.exact.prevent="onDown('iName_13')" @keydown.left.exact.prevent="onLeft('iName_13')" @keydown.right.exact.prevent="onRight('iName_13')" @keydown.tab.exact.prevent="onTab('iName_13')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_13')" @click="inputFocus($event)" v-model="iName_13">
              <input v-else                  type="text" id="iName_13" class="name"           @keydown.up.exact.prevent="onUp('iName_13')" @keydown.down.exact.prevent="onDown('iName_13')" @keydown.left.exact.prevent="onLeft('iName_13')" @keydown.right.exact.prevent="onRight('iName_13')" @keydown.tab.exact.prevent="onTab('iName_13')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_13')" @click="inputFocus($event)" v-model="iName_13">
            </td>
            <td><input type="text" id="iWeight_13_1" class="weight1" @keydown.up.exact.prevent="onUp('iWeight_13_1')" @keydown.down.exact.prevent="onDown('iWeight_13_1')" @keydown.left.exact.prevent="onLeft('iWeight_13_1')" @keydown.right.exact.prevent="onRight('iWeight_13_1')" @keydown.tab.exact.prevent="onTab('iWeight_13_1')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_13_1')" @click="inputFocus($event)" v-model="iWeight_13_1"></td>
            <td><input type="text" id="iWeight_13_2" class="weight2" @keydown.up.exact.prevent="onUp('iWeight_13_2')" @keydown.down.exact.prevent="onDown('iWeight_13_2')" @keydown.left.exact.prevent="onLeft('iWeight_13_2')" @keydown.right.exact.prevent="onRight('iWeight_13_2')" @keydown.tab.exact.prevent="onTab('iWeight_13_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_13_2')" @click="inputFocus($event)" v-model="iWeight_13_2"></td>
            <td><input type="checkbox" name="iCheck_13" id="iCheck_13" v-model="iCheck_13"></td>
          </tr>
          <tr>
            <td>
              <span v-if="iCheck_14 == true" class="nest">∟</span>
              <input v-if="iCheck_14 == true" type="text" id="iName_14" class="name nestInput" @keydown.up.exact.prevent="onUp('iName_14')" @keydown.down.exact.prevent="onDown('iName_14')" @keydown.left.exact.prevent="onLeft('iName_14')" @keydown.right.exact.prevent="onRight('iName_14')" @keydown.tab.exact.prevent="onTab('iName_14')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_14')" @click="inputFocus($event)" v-model="iName_14">
              <input v-else                  type="text" id="iName_14" class="name"           @keydown.up.exact.prevent="onUp('iName_14')" @keydown.down.exact.prevent="onDown('iName_14')" @keydown.left.exact.prevent="onLeft('iName_14')" @keydown.right.exact.prevent="onRight('iName_14')" @keydown.tab.exact.prevent="onTab('iName_14')" @keydown.shift.tab.exact.prevent="onShiftTab('iName_14')" @click="inputFocus($event)" v-model="iName_14">
            </td>
            <td><input type="text" id="iWeight_14_1" class="weight1" @keydown.up.exact.prevent="onUp('iWeight_14_1')" @keydown.down.exact.prevent="onDown('iWeight_14_1')" @keydown.left.exact.prevent="onLeft('iWeight_14_1')" @keydown.right.exact.prevent="onRight('iWeight_14_1')" @keydown.tab.exact.prevent="onTab('iWeight_14_1')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_14_1')" @click="inputFocus($event)" v-model="iWeight_14_1"></td>
            <td><input type="text" id="iWeight_14_2" class="weight2" @keydown.up.exact.prevent="onUp('iWeight_14_2')" @keydown.down.exact.prevent="onDown('iWeight_14_2')" @keydown.left.exact.prevent="onLeft('iWeight_14_2')" @keydown.right.exact.prevent="onRight('iWeight_14_2')" @keydown.tab.exact.prevent="onTab('iWeight_14_2')" @keydown.shift.tab.exact.prevent="onShiftTab('iWeight_14_2')" @click="inputFocus($event)" v-model="iWeight_14_2"></td>
            <td><input type="checkbox" name="iCheck_14" id="iCheck_14" v-model="iCheck_14"></td>
          </tr>
        </table>
      </div>
      <div class="seasoning">
        <p class="partTitle">調味料</p>
        <table>
          <tr>
            <th>調味料名</th>
            <th colspan="2">分量</th>
            <th>★</th>
            <th>・</th>
          </tr>
          <tr>
            <td><input type="text" id="sName_1"     class="name"    @keydown.up.exact.prevent="onUp('sName_1')" @keydown.down.exact.prevent="onDown('sName_1')" @keydown.left.exact.prevent="onLeft('sName_1')" @keydown.right.exact.prevent="onRight('sName_1')" @keydown.tab.exact.prevent="onTab('sName_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sName_1')" @click="inputFocus($event)" v-model="sName_1"></td>
            <td><input type="text" id="sWeight_1_1" class="weight1" @keydown.up.exact.prevent="onUp('sWeight_1_1')" @keydown.down.exact.prevent="onDown('sWeight_1_1')" @keydown.left.exact.prevent="onLeft('sWeight_1_1')" @keydown.right.exact.prevent="onRight('sWeight_1_1')" @keydown.tab.exact.prevent="onTab('sWeight_1_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_1_1')" @click="inputFocus($event)" v-model="sWeight_1_1"></td>
            <td><input type="text" id="sWeight_1_2" class="weight2" @keydown.up.exact.prevent="onUp('sWeight_1_2')" @keydown.down.exact.prevent="onDown('sWeight_1_2')" @keydown.left.exact.prevent="onLeft('sWeight_1_2')" @keydown.right.exact.prevent="onRight('sWeight_1_2')" @keydown.tab.exact.prevent="onTab('sWeight_1_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_1_2')" @click="inputFocus($event)" v-model="sWeight_1_2"></td>
            <td><select name="sGroup_1" id="sGroup_1" v-model="sGroup_1"><option value=""></option><option value="★">★</option><option value="◆">◆</option><option value="■">■</option></select></td>
            <td><input type="checkbox" name="sTenCheck_1" id="sTenCheck_1" v-model="sTenCheck_1"></td>
          </tr>
          <tr>
            <td><input type="text" id="sName_2"     class="name"    @keydown.up.exact.prevent="onUp('sName_2')" @keydown.down.exact.prevent="onDown('sName_2')" @keydown.left.exact.prevent="onLeft('sName_2')" @keydown.right.exact.prevent="onRight('sName_2')" @keydown.tab.exact.prevent="onTab('sName_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sName_2')" @click="inputFocus($event)" v-model="sName_2"></td>
            <td><input type="text" id="sWeight_2_1" class="weight1" @keydown.up.exact.prevent="onUp('sWeight_2_1')" @keydown.down.exact.prevent="onDown('sWeight_2_1')" @keydown.left.exact.prevent="onLeft('sWeight_2_1')" @keydown.right.exact.prevent="onRight('sWeight_2_1')" @keydown.tab.exact.prevent="onTab('sWeight_2_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_2_1')" @click="inputFocus($event)" v-model="sWeight_2_1"></td>
            <td><input type="text" id="sWeight_2_2" class="weight2" @keydown.up.exact.prevent="onUp('sWeight_2_2')" @keydown.down.exact.prevent="onDown('sWeight_2_2')" @keydown.left.exact.prevent="onLeft('sWeight_2_2')" @keydown.right.exact.prevent="onRight('sWeight_2_2')" @keydown.tab.exact.prevent="onTab('sWeight_2_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_2_2')" @click="inputFocus($event)" v-model="sWeight_2_2"></td>
            <td><select name="sGroup_2" id="sGroup_2" v-model="sGroup_2"><option value=""></option><option value="★">★</option><option value="◆">◆</option><option value="■">■</option></select></td>
            <td><input type="checkbox" name="sTenCheck_2" id="sTenCheck_2" v-model="sTenCheck_2"></td>
          </tr>
          <tr>
            <td><input type="text" id="sName_3"     class="name"    @keydown.up.exact.prevent="onUp('sName_3')" @keydown.down.exact.prevent="onDown('sName_3')" @keydown.left.exact.prevent="onLeft('sName_3')" @keydown.right.exact.prevent="onRight('sName_3')" @keydown.tab.exact.prevent="onTab('sName_3')" @keydown.shift.tab.exact.prevent="onShiftTab('sName_3')" @click="inputFocus($event)" v-model="sName_3"></td>
            <td><input type="text" id="sWeight_3_1" class="weight1" @keydown.up.exact.prevent="onUp('sWeight_3_1')" @keydown.down.exact.prevent="onDown('sWeight_3_1')" @keydown.left.exact.prevent="onLeft('sWeight_3_1')" @keydown.right.exact.prevent="onRight('sWeight_3_1')" @keydown.tab.exact.prevent="onTab('sWeight_3_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_3_1')" @click="inputFocus($event)" v-model="sWeight_3_1"></td>
            <td><input type="text" id="sWeight_3_2" class="weight2" @keydown.up.exact.prevent="onUp('sWeight_3_2')" @keydown.down.exact.prevent="onDown('sWeight_3_2')" @keydown.left.exact.prevent="onLeft('sWeight_3_2')" @keydown.right.exact.prevent="onRight('sWeight_3_2')" @keydown.tab.exact.prevent="onTab('sWeight_3_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_3_2')" @click="inputFocus($event)" v-model="sWeight_3_2"></td>
            <td><select name="sGroup_3" id="sGroup_3" v-model="sGroup_3"><option value=""></option><option value="★">★</option><option value="◆">◆</option><option value="■">■</option></select></td>
            <td><input type="checkbox" name="sTenCheck_3" id="sTenCheck_3" v-model="sTenCheck_3"></td>
          </tr>
          <tr>
            <td><input type="text" id="sName_4"     class="name"    @keydown.up.exact.prevent="onUp('sName_4')" @keydown.down.exact.prevent="onDown('sName_4')" @keydown.left.exact.prevent="onLeft('sName_4')" @keydown.right.exact.prevent="onRight('sName_4')" @keydown.tab.exact.prevent="onTab('sName_4')" @keydown.shift.tab.exact.prevent="onShiftTab('sName_4')" @click="inputFocus($event)" v-model="sName_4"></td>
            <td><input type="text" id="sWeight_4_1" class="weight1" @keydown.up.exact.prevent="onUp('sWeight_4_1')" @keydown.down.exact.prevent="onDown('sWeight_4_1')" @keydown.left.exact.prevent="onLeft('sWeight_4_1')" @keydown.right.exact.prevent="onRight('sWeight_4_1')" @keydown.tab.exact.prevent="onTab('sWeight_4_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_4_1')" @click="inputFocus($event)" v-model="sWeight_4_1"></td>
            <td><input type="text" id="sWeight_4_2" class="weight2" @keydown.up.exact.prevent="onUp('sWeight_4_2')" @keydown.down.exact.prevent="onDown('sWeight_4_2')" @keydown.left.exact.prevent="onLeft('sWeight_4_2')" @keydown.right.exact.prevent="onRight('sWeight_4_2')" @keydown.tab.exact.prevent="onTab('sWeight_4_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_4_2')" @click="inputFocus($event)" v-model="sWeight_4_2"></td>
            <td><select name="sGroup_4" id="sGroup_4" v-model="sGroup_4"><option value=""></option><option value="★">★</option><option value="◆">◆</option><option value="■">■</option></select></td>
            <td><input type="checkbox" name="sTenCheck_4" id="sTenCheck_4" v-model="sTenCheck_4"></td>
          </tr>
          <tr>
            <td><input type="text" id="sName_5"     class="name"    @keydown.up.exact.prevent="onUp('sName_5')" @keydown.down.exact.prevent="onDown('sName_5')" @keydown.left.exact.prevent="onLeft('sName_5')" @keydown.right.exact.prevent="onRight('sName_5')" @keydown.tab.exact.prevent="onTab('sName_5')" @keydown.shift.tab.exact.prevent="onShiftTab('sName_5')" @click="inputFocus($event)" v-model="sName_5"></td>
            <td><input type="text" id="sWeight_5_1" class="weight1" @keydown.up.exact.prevent="onUp('sWeight_5_1')" @keydown.down.exact.prevent="onDown('sWeight_5_1')" @keydown.left.exact.prevent="onLeft('sWeight_5_1')" @keydown.right.exact.prevent="onRight('sWeight_5_1')" @keydown.tab.exact.prevent="onTab('sWeight_5_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_5_1')" @click="inputFocus($event)" v-model="sWeight_5_1"></td>
            <td><input type="text" id="sWeight_5_2" class="weight2" @keydown.up.exact.prevent="onUp('sWeight_5_2')" @keydown.down.exact.prevent="onDown('sWeight_5_2')" @keydown.left.exact.prevent="onLeft('sWeight_5_2')" @keydown.right.exact.prevent="onRight('sWeight_5_2')" @keydown.tab.exact.prevent="onTab('sWeight_5_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_5_2')" @click="inputFocus($event)" v-model="sWeight_5_2"></td>
            <td><select name="sGroup_5" id="sGroup_5" v-model="sGroup_5"><option value=""></option><option value="★">★</option><option value="◆">◆</option><option value="■">■</option></select></td>
            <td><input type="checkbox" name="sTenCheck_5" id="sTenCheck_5" v-model="sTenCheck_5"></td>
          </tr>
          <tr>
            <td><input type="text" id="sName_6"     class="name"    @keydown.up.exact.prevent="onUp('sName_6')" @keydown.down.exact.prevent="onDown('sName_6')" @keydown.left.exact.prevent="onLeft('sName_6')" @keydown.right.exact.prevent="onRight('sName_6')" @keydown.tab.exact.prevent="onTab('sName_6')" @keydown.shift.tab.exact.prevent="onShiftTab('sName_6')" @click="inputFocus($event)" v-model="sName_6"></td>
            <td><input type="text" id="sWeight_6_1" class="weight1" @keydown.up.exact.prevent="onUp('sWeight_6_1')" @keydown.down.exact.prevent="onDown('sWeight_6_1')" @keydown.left.exact.prevent="onLeft('sWeight_6_1')" @keydown.right.exact.prevent="onRight('sWeight_6_1')" @keydown.tab.exact.prevent="onTab('sWeight_6_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_6_1')" @click="inputFocus($event)" v-model="sWeight_6_1"></td>
            <td><input type="text" id="sWeight_6_2" class="weight2" @keydown.up.exact.prevent="onUp('sWeight_6_2')" @keydown.down.exact.prevent="onDown('sWeight_6_2')" @keydown.left.exact.prevent="onLeft('sWeight_6_2')" @keydown.right.exact.prevent="onRight('sWeight_6_2')" @keydown.tab.exact.prevent="onTab('sWeight_6_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_6_2')" @click="inputFocus($event)" v-model="sWeight_6_2"></td>
            <td><select name="sGroup_6" id="sGroup_6" v-model="sGroup_6"><option value=""></option><option value="★">★</option><option value="◆">◆</option><option value="■">■</option></select></td>
            <td><input type="checkbox" name="sTenCheck_6" id="sTenCheck_6" v-model="sTenCheck_6"></td>
          </tr>
          <tr>
            <td><input type="text" id="sName_7"     class="name"    @keydown.up.exact.prevent="onUp('sName_7')" @keydown.down.exact.prevent="onDown('sName_7')" @keydown.left.exact.prevent="onLeft('sName_7')" @keydown.right.exact.prevent="onRight('sName_7')" @keydown.tab.exact.prevent="onTab('sName_7')" @keydown.shift.tab.exact.prevent="onShiftTab('sName_7')" @click="inputFocus($event)" v-model="sName_7"></td>
            <td><input type="text" id="sWeight_7_1" class="weight1" @keydown.up.exact.prevent="onUp('sWeight_7_1')" @keydown.down.exact.prevent="onDown('sWeight_7_1')" @keydown.left.exact.prevent="onLeft('sWeight_7_1')" @keydown.right.exact.prevent="onRight('sWeight_7_1')" @keydown.tab.exact.prevent="onTab('sWeight_7_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_7_1')" @click="inputFocus($event)" v-model="sWeight_7_1"></td>
            <td><input type="text" id="sWeight_7_2" class="weight2" @keydown.up.exact.prevent="onUp('sWeight_7_2')" @keydown.down.exact.prevent="onDown('sWeight_7_2')" @keydown.left.exact.prevent="onLeft('sWeight_7_2')" @keydown.right.exact.prevent="onRight('sWeight_7_2')" @keydown.tab.exact.prevent="onTab('sWeight_7_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_7_2')" @click="inputFocus($event)" v-model="sWeight_7_2"></td>
            <td><select name="sGroup_7" id="sGroup_7" v-model="sGroup_7"><option value=""></option><option value="★">★</option><option value="◆">◆</option><option value="■">■</option></select></td>
            <td><input type="checkbox" name="sTenCheck_7" id="sTenCheck_7" v-model="sTenCheck_7"></td>
          </tr>
          <tr>
            <td><input type="text" id="sName_8"     class="name"    @keydown.up.exact.prevent="onUp('sName_8')" @keydown.down.exact.prevent="onDown('sName_8')" @keydown.left.exact.prevent="onLeft('sName_8')" @keydown.right.exact.prevent="onRight('sName_8')" @keydown.tab.exact.prevent="onTab('sName_8')" @keydown.shift.tab.exact.prevent="onShiftTab('sName_8')" @click="inputFocus($event)" v-model="sName_8"></td>
            <td><input type="text" id="sWeight_8_1" class="weight1" @keydown.up.exact.prevent="onUp('sWeight_8_1')" @keydown.down.exact.prevent="onDown('sWeight_8_1')" @keydown.left.exact.prevent="onLeft('sWeight_8_1')" @keydown.right.exact.prevent="onRight('sWeight_8_1')" @keydown.tab.exact.prevent="onTab('sWeight_8_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_8_1')" @click="inputFocus($event)" v-model="sWeight_8_1"></td>
            <td><input type="text" id="sWeight_8_2" class="weight2" @keydown.up.exact.prevent="onUp('sWeight_8_2')" @keydown.down.exact.prevent="onDown('sWeight_8_2')" @keydown.left.exact.prevent="onLeft('sWeight_8_2')" @keydown.right.exact.prevent="onRight('sWeight_8_2')" @keydown.tab.exact.prevent="onTab('sWeight_8_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_8_2')" @click="inputFocus($event)" v-model="sWeight_8_2"></td>
            <td><select name="sGroup_8" id="sGroup_8" v-model="sGroup_8"><option value=""></option><option value="★">★</option><option value="◆">◆</option><option value="■">■</option></select></td>
            <td><input type="checkbox" name="sTenCheck_8" id="sTenCheck_8" v-model="sTenCheck_8"></td>
          </tr>
          <tr>
            <td><input type="text" id="sName_9"     class="name"    @keydown.up.exact.prevent="onUp('sName_9')" @keydown.down.exact.prevent="onDown('sName_9')" @keydown.left.exact.prevent="onLeft('sName_9')" @keydown.right.exact.prevent="onRight('sName_9')" @keydown.tab.exact.prevent="onTab('sName_9')" @keydown.shift.tab.exact.prevent="onShiftTab('sName_9')" @click="inputFocus($event)" v-model="sName_9"></td>
            <td><input type="text" id="sWeight_9_1" class="weight1" @keydown.up.exact.prevent="onUp('sWeight_9_1')" @keydown.down.exact.prevent="onDown('sWeight_9_1')" @keydown.left.exact.prevent="onLeft('sWeight_9_1')" @keydown.right.exact.prevent="onRight('sWeight_9_1')" @keydown.tab.exact.prevent="onTab('sWeight_9_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_9_1')" @click="inputFocus($event)" v-model="sWeight_9_1"></td>
            <td><input type="text" id="sWeight_9_2" class="weight2" @keydown.up.exact.prevent="onUp('sWeight_9_2')" @keydown.down.exact.prevent="onDown('sWeight_9_2')" @keydown.left.exact.prevent="onLeft('sWeight_9_2')" @keydown.right.exact.prevent="onRight('sWeight_9_2')" @keydown.tab.exact.prevent="onTab('sWeight_9_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_9_2')" @click="inputFocus($event)" v-model="sWeight_9_2"></td>
            <td><select name="sGroup_9" id="sGroup_9" v-model="sGroup_9"><option value=""></option><option value="★">★</option><option value="◆">◆</option><option value="■">■</option></select></td>
            <td><input type="checkbox" name="sTenCheck_9" id="sTenCheck_9" v-model="sTenCheck_9"></td>
          </tr>
          <tr>
            <td><input type="text" id="sName_10"     class="name"    @keydown.up.exact.prevent="onUp('sName_10')" @keydown.down.exact.prevent="onDown('sName_10')" @keydown.left.exact.prevent="onLeft('sName_10')" @keydown.right.exact.prevent="onRight('sName_10')" @keydown.tab.exact.prevent="onTab('sName_10')" @keydown.shift.tab.exact.prevent="onShiftTab('sName_10')" @click="inputFocus($event)" v-model="sName_10"></td>
            <td><input type="text" id="sWeight_10_1" class="weight1" @keydown.up.exact.prevent="onUp('sWeight_10_1')" @keydown.down.exact.prevent="onDown('sWeight_10_1')" @keydown.left.exact.prevent="onLeft('sWeight_10_1')" @keydown.right.exact.prevent="onRight('sWeight_10_1')" @keydown.tab.exact.prevent="onTab('sWeight_10_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_10_1')" @click="inputFocus($event)" v-model="sWeight_10_1"></td>
            <td><input type="text" id="sWeight_10_2" class="weight2" @keydown.up.exact.prevent="onUp('sWeight_10_2')" @keydown.down.exact.prevent="onDown('sWeight_10_2')" @keydown.left.exact.prevent="onLeft('sWeight_10_2')" @keydown.right.exact.prevent="onRight('sWeight_10_2')" @keydown.tab.exact.prevent="onTab('sWeight_10_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_10_2')" @click="inputFocus($event)" v-model="sWeight_10_2"></td>
            <td><select name="sGroup_10" id="sGroup_10" v-model="sGroup_10"><option value=""></option><option value="★">★</option><option value="◆">◆</option><option value="■">■</option></select></td>
            <td><input type="checkbox" name="sTenCheck_10" id="sTenCheck_10" v-model="sTenCheck_10"></td>
          </tr>
          <tr>
            <td><input type="text" id="sName_11"     class="name"    @keydown.up.exact.prevent="onUp('sName_11')" @keydown.down.exact.prevent="onDown('sName_11')" @keydown.left.exact.prevent="onLeft('sName_11')" @keydown.right.exact.prevent="onRight('sName_11')" @keydown.tab.exact.prevent="onTab('sName_11')" @keydown.shift.tab.exact.prevent="onShiftTab('sName_11')" @click="inputFocus($event)" v-model="sName_11"></td>
            <td><input type="text" id="sWeight_11_1" class="weight1" @keydown.up.exact.prevent="onUp('sWeight_11_1')" @keydown.down.exact.prevent="onDown('sWeight_11_1')" @keydown.left.exact.prevent="onLeft('sWeight_11_1')" @keydown.right.exact.prevent="onRight('sWeight_11_1')" @keydown.tab.exact.prevent="onTab('sWeight_11_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_11_1')" @click="inputFocus($event)" v-model="sWeight_11_1"></td>
            <td><input type="text" id="sWeight_11_2" class="weight2" @keydown.up.exact.prevent="onUp('sWeight_11_2')" @keydown.down.exact.prevent="onDown('sWeight_11_2')" @keydown.left.exact.prevent="onLeft('sWeight_11_2')" @keydown.right.exact.prevent="onRight('sWeight_11_2')" @keydown.tab.exact.prevent="onTab('sWeight_11_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_11_2')" @click="inputFocus($event)" v-model="sWeight_11_2"></td>
            <td><select name="sGroup_11" id="sGroup_11" v-model="sGroup_11"><option value=""></option><option value="★">★</option><option value="◆">◆</option><option value="■">■</option></select></td>
            <td><input type="checkbox" name="sTenCheck_11" id="sTenCheck_11" v-model="sTenCheck_11"></td>
          </tr>
          <tr>
            <td><input type="text" id="sName_12"     class="name"    @keydown.up.exact.prevent="onUp('sName_12')" @keydown.down.exact.prevent="onDown('sName_12')" @keydown.left.exact.prevent="onLeft('sName_12')" @keydown.right.exact.prevent="onRight('sName_12')" @keydown.tab.exact.prevent="onTab('sName_12')" @keydown.shift.tab.exact.prevent="onShiftTab('sName_12')" @click="inputFocus($event)" v-model="sName_12"></td>
            <td><input type="text" id="sWeight_12_1" class="weight1" @keydown.up.exact.prevent="onUp('sWeight_12_1')" @keydown.down.exact.prevent="onDown('sWeight_12_1')" @keydown.left.exact.prevent="onLeft('sWeight_12_1')" @keydown.right.exact.prevent="onRight('sWeight_12_1')" @keydown.tab.exact.prevent="onTab('sWeight_12_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_12_1')" @click="inputFocus($event)" v-model="sWeight_12_1"></td>
            <td><input type="text" id="sWeight_12_2" class="weight2" @keydown.up.exact.prevent="onUp('sWeight_12_2')" @keydown.down.exact.prevent="onDown('sWeight_12_2')" @keydown.left.exact.prevent="onLeft('sWeight_12_2')" @keydown.right.exact.prevent="onRight('sWeight_12_2')" @keydown.tab.exact.prevent="onTab('sWeight_12_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_12_2')" @click="inputFocus($event)" v-model="sWeight_12_2"></td>
            <td><select name="sGroup_12" id="sGroup_12" v-model="sGroup_12"><option value=""></option><option value="★">★</option><option value="◆">◆</option><option value="■">■</option></select></td>
            <td><input type="checkbox" name="sTenCheck_12" id="sTenCheck_12" v-model="sTenCheck_12"></td>
          </tr>
          <tr>
            <td><input type="text" id="sName_13"     class="name"    @keydown.up.exact.prevent="onUp('sName_13')" @keydown.down.exact.prevent="onDown('sName_13')" @keydown.left.exact.prevent="onLeft('sName_13')" @keydown.right.exact.prevent="onRight('sName_13')" @keydown.tab.exact.prevent="onTab('sName_13')" @keydown.shift.tab.exact.prevent="onShiftTab('sName_13')" @click="inputFocus($event)" v-model="sName_13"></td>
            <td><input type="text" id="sWeight_13_1" class="weight1" @keydown.up.exact.prevent="onUp('sWeight_13_1')" @keydown.down.exact.prevent="onDown('sWeight_13_1')" @keydown.left.exact.prevent="onLeft('sWeight_13_1')" @keydown.right.exact.prevent="onRight('sWeight_13_1')" @keydown.tab.exact.prevent="onTab('sWeight_13_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_13_1')" @click="inputFocus($event)" v-model="sWeight_13_1"></td>
            <td><input type="text" id="sWeight_13_2" class="weight2" @keydown.up.exact.prevent="onUp('sWeight_13_2')" @keydown.down.exact.prevent="onDown('sWeight_13_2')" @keydown.left.exact.prevent="onLeft('sWeight_13_2')" @keydown.right.exact.prevent="onRight('sWeight_13_2')" @keydown.tab.exact.prevent="onTab('sWeight_13_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_13_2')" @click="inputFocus($event)" v-model="sWeight_13_2"></td>
            <td><select name="sGroup_13" id="sGroup_13" v-model="sGroup_13"><option value=""></option><option value="★">★</option><option value="◆">◆</option><option value="■">■</option></select></td>
            <td><input type="checkbox" name="sTenCheck_13" id="sTenCheck_13" v-model="sTenCheck_13"></td>
          </tr>
          <tr>
            <td><input type="text" id="sName_14"     class="name"    @keydown.up.exact.prevent="onUp('sName_14')" @keydown.down.exact.prevent="onDown('sName_14')" @keydown.left.exact.prevent="onLeft('sName_14')" @keydown.right.exact.prevent="onRight('sName_14')" @keydown.tab.exact.prevent="onTab('sName_14')" @keydown.shift.tab.exact.prevent="onShiftTab('sName_14')" @click="inputFocus($event)" v-model="sName_14"></td>
            <td><input type="text" id="sWeight_14_1" class="weight1" @keydown.up.exact.prevent="onUp('sWeight_14_1')" @keydown.down.exact.prevent="onDown('sWeight_14_1')" @keydown.left.exact.prevent="onLeft('sWeight_14_1')" @keydown.right.exact.prevent="onRight('sWeight_14_1')" @keydown.tab.exact.prevent="onTab('sWeight_14_1')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_14_1')" @click="inputFocus($event)" v-model="sWeight_14_1"></td>
            <td><input type="text" id="sWeight_14_2" class="weight2" @keydown.up.exact.prevent="onUp('sWeight_14_2')" @keydown.down.exact.prevent="onDown('sWeight_14_2')" @keydown.left.exact.prevent="onLeft('sWeight_14_2')" @keydown.right.exact.prevent="onRight('sWeight_14_2')" @keydown.tab.exact.prevent="onTab('sWeight_14_2')" @keydown.shift.tab.exact.prevent="onShiftTab('sWeight_14_2')" @click="inputFocus($event)" v-model="sWeight_14_2"></td>
            <td><select name="sGroup_14" id="sGroup_14" v-model="sGroup_14"><option value=""></option><option value="★">★</option><option value="◆">◆</option><option value="■">■</option></select></td>
            <td><input type="checkbox" name="sTenCheck_13" id="sTenCheck_13" v-model="sTenCheck_13"></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template> 

<script>
import firebase from "../main.js";
import { collection, getDocs } from "firebase/firestore";

export default {
  name: 'materialDialog',
  props: {
    'materialData': {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      numOfPeople: null,

      iName_1: null, iWeight_1_1: null, iWeight_1_2: null,
      iName_2: null, iWeight_2_1: null, iWeight_2_2: null, iCheck_2: false,
      iName_3: null, iWeight_3_1: null, iWeight_3_2: null, iCheck_3: false,
      iName_4: null, iWeight_4_1: null, iWeight_4_2: null, iCheck_4: false,
      iName_5: null, iWeight_5_1: null, iWeight_5_2: null, iCheck_5: false,
      iName_6: null, iWeight_6_1: null, iWeight_6_2: null, iCheck_6: false,
      iName_7: null, iWeight_7_1: null, iWeight_7_2: null, iCheck_7: false,
      iName_8: null, iWeight_8_1: null, iWeight_8_2: null, iCheck_8: false,
      iName_9: null, iWeight_9_1: null, iWeight_9_2: null, iCheck_9: false,
      iName_10: null, iWeight_10_1: null, iWeight_10_2: null, iCheck_10: false,
      iName_11: null, iWeight_11_1: null, iWeight_11_2: null, iCheck_11: false,
      iName_12: null, iWeight_12_1: null, iWeight_12_2: null, iCheck_12: false,
      iName_13: null, iWeight_13_1: null, iWeight_13_2: null, iCheck_13: false,
      iName_14: null, iWeight_14_1: null, iWeight_14_2: null, iCheck_14: false,

      sName_1: null, sWeight_1_1: null, sWeight_1_2: null, sGroup_1: null, sTenCheck_1: false,
      sName_2: null, sWeight_2_1: null, sWeight_2_2: null, sGroup_2: null, sTenCheck_2: false,
      sName_3: null, sWeight_3_1: null, sWeight_3_2: null, sGroup_3: null, sTenCheck_3: false,
      sName_4: null, sWeight_4_1: null, sWeight_4_2: null, sGroup_4: null, sTenCheck_4: false,
      sName_5: null, sWeight_5_1: null, sWeight_5_2: null, sGroup_5: null, sTenCheck_5: false,
      sName_6: null, sWeight_6_1: null, sWeight_6_2: null, sGroup_6: null, sTenCheck_6: false,
      sName_7: null, sWeight_7_1: null, sWeight_7_2: null, sGroup_7: null, sTenCheck_7: false,
      sName_8: null, sWeight_8_1: null, sWeight_8_2: null, sGroup_8: null, sTenCheck_8: false,
      sName_9: null, sWeight_9_1: null, sWeight_9_2: null, sGroup_9: null, sTenCheck_9: false,
      sName_10: null, sWeight_10_1: null, sWeight_10_2: null, sGroup_10: null, sTenCheck_10: false,
      sName_11: null, sWeight_11_1: null, sWeight_11_2: null, sGroup_11: null, sTenCheck_11: false,
      sName_12: null, sWeight_12_1: null, sWeight_12_2: null, sGroup_12: null, sTenCheck_12: false,
      sName_13: null, sWeight_13_1: null, sWeight_13_2: null, sGroup_13: null, sTenCheck_13: false,
      sName_14: null, sWeight_14_1: null, sWeight_14_2: null, sGroup_14: null, sTenCheck_14: false,

      categories: [
        {
          name: "category1",
          color: "red",
          values: []
        },
        {
          name: "category2",
          color: "blue",
          values: []
        },
        {
          name: "category3",
          color: "green",
          values: []
        },
      ],

      nowFocusId: null,
    }
  },
  mounted() {
    if (this.materialData != null) {
      this.numOfPeople = this.materialData.numOfPeople;
      let i = 1;
      for (let ingredient of this.materialData.ingredients) {
        this["iName_" + i] = ingredient.name;
        this["iWeight_" + i + "_1"] = ingredient.weight1;
        this["iWeight_" + i + "_2"] = ingredient.weight2;
        if (ingredient.preparation && ingredient.preparation.length > 0) {
          for (let preparation of ingredient.preparation) {
            i++;
            this["iName_" + i] = preparation.name;
            this["iWeight_" + i + "_1"] = preparation.weight1;
            this["iWeight_" + i + "_2"] = preparation.weight2;
            this["iCheck_" + i] = true;
          }
        }
        i++;
      }
      i = 1;
      for (let seasoning of this.materialData.seasonings) {
        this["sName_" + i] = seasoning.name;
        this["sWeight_" + i + "_1"] = seasoning.weight1;
        this["sWeight_" + i + "_2"] = seasoning.weight2;
        this["sGroup_" + i] = seasoning.group;
        this["sTenCheck_" + i] = seasoning.tenCheck;
        i++;
      }
    }

    this.awaitFunc();
  },
  updated() {
  },
  beforeUnmount() {
    this.$emit("dialogClose", this);
  },
  methods: {
    async awaitFunc() {
      const categoriesQuerySnapshot = await getDocs(collection(firebase.db, "Categories"));
      categoriesQuerySnapshot.forEach((docu) => {
        let data = docu.data();
        if (data.category == 1) {
          this.categories[0].values = data.values;

        } else if (data.category == 2) {
          this.categories[1].values = data.values;

        } else if (data.category == 3) {
          this.categories[2].values = data.values;

        }
      });
    },
    dragList(event, dragId){
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.setData('list-id',dragId)
      console.log(dragId)
    },
    onDragStart(e, text) {
      e.dataTransfer.setData('text', text);
    },
    dblclick(text) {
      if (this.nowFocusId) {
        if (this[this.nowFocusId]) {
          this[this.nowFocusId] += text;
        } else {
          this[this.nowFocusId] = text;
        }
        let newTarget = document.getElementById(this.nowFocusId);
        if (newTarget) {
          newTarget.classList.add("active");
          newTarget.focus();
        }
      }
    },
    close() {
      this.$emit("dialogClose", this);
    },
    register() {
      this.$emit("dialogClose", this);
    },
    onUp(id) {
      let newId = null;
      const ids = id.split("_");
      const rowNum = parseInt(ids[1]);
      if (rowNum == 1) {
        return;
      }
      if (id.indexOf("iName_") >= 0 || id.indexOf("iWeight_") >= 0) {
        if (ids[0] == "iName") {
          newId = "iName_" + (rowNum - 1);

        } else if (ids[0] == "iWeight") {
          newId = "iWeight_" + (rowNum - 1) + "_" + ids[2];

        }
      } else if (id.indexOf("sName_") >= 0 || id.indexOf("sWeight_") >= 0) {
        if (ids[0] == "sName") {
          newId = "sName_" + (rowNum - 1);

        } else if (ids[0] == "sWeight") {
          newId = "sWeight_" + (rowNum - 1) + "_" + ids[2];

        }
      }
      document.getElementById(id).classList.remove("active");
      if (newId) {
        this.nowFocusId = newId;
        let newTarget = document.getElementById(newId);
        if (newTarget) {
          newTarget.classList.add("active");
          newTarget.focus();
        }
      }
    },
    onDown(id) {
      let newId = null;
      const ids = id.split("_");
      const rowNum = parseInt(ids[1]);
      if (rowNum == 14) {
        return;
      }
      if (id.indexOf("iName_") >= 0 || id.indexOf("iWeight_") >= 0) {
        if (ids[0] == "iName") {
          newId = "iName_" + (rowNum + 1);

        } else if (ids[0] == "iWeight") {
          newId = "iWeight_" + (rowNum + 1) + "_" + ids[2];

        }
      } else if (id.indexOf("sName_") >= 0 || id.indexOf("sWeight_") >= 0) {
        if (ids[0] == "sName") {
          newId = "sName_" + (rowNum + 1);

        } else if (ids[0] == "sWeight") {
          newId = "sWeight_" + (rowNum + 1) + "_" + ids[2];

        }
      }
      document.getElementById(id).classList.remove("active");
      if (newId) {
        this.nowFocusId = newId;
        let newTarget = document.getElementById(newId);
        if (newTarget) {
          newTarget.classList.add("active");
          newTarget.focus();
        }
      }
    },
    onLeft(id) {
      let newId = null;
      const ids = id.split("_");
      if (ids[0] == "iName") {
        return;
      }
      if (ids[0] == "iWeight") {
        const colNum = parseInt(ids[2]);
        if (colNum == 1) {
          newId = "iName_" + ids[1];
        } else {
          newId = "iWeight_" + ids[1] + "_1";
        }

      } else if (ids[0] == "sName") {
        newId = "iWeight_" + ids[1] + "_2";

      } else if (ids[0] == "sWeight") {
        const colNum = parseInt(ids[2]);
        if (colNum == 1) {
          newId = "sName_" + ids[1];
        } else {
          newId = "sWeight_" + ids[1] + "_1";
        }

      }
      document.getElementById(id).classList.remove("active");
      if (newId) {
        this.nowFocusId = newId;
        let newTarget = document.getElementById(newId);
        if (newTarget) {
          newTarget.classList.add("active");
          newTarget.focus();
        }
      }
    },
    onRight(id) {
      let newId = null;
      const ids = id.split("_");
      if (ids[0] == "iName") {
        newId = "iWeight_" + ids[1] + "_1";

      } else if (ids[0] == "iWeight") {
        const colNum = parseInt(ids[2]);
        if (colNum == 1) {
          newId = "iWeight_" + ids[1] + "_2";
        } else {
          newId = "sName_" + ids[1];
        }

      } else if (ids[0] == "sName") {
        newId = "sWeight_" + ids[1] + "_1";

      } else if (ids[0] == "sWeight") {
        const colNum = parseInt(ids[2]);
        if (colNum == 1) {
          newId = "sWeight_" + ids[1] + "_2";
        } else {
          return;
        }

      }
      document.getElementById(id).classList.remove("active");
      if (newId) {
        this.nowFocusId = newId;
        let newTarget = document.getElementById(newId);
        if (newTarget) {
          newTarget.classList.add("active");
          newTarget.focus();
        }
      }
    },
    onTab(id) {
      let newId = null;
      const ids = id.split("_");
      if (id.indexOf("iName_") >= 0 || id.indexOf("iWeight_") >= 0) {
        if (ids[0] == "iName") {
          newId = "iWeight_" + ids[1] + "_1";

        } else if (ids[0] == "iWeight") {
          if (ids[2] == '1') {
            newId = "iWeight_" + ids[1] + "_2";

          } else {
            const rowNum = parseInt(ids[1]);
            if (rowNum < 14) {
              newId = "iName_" + (rowNum + 1);

            } else {
              newId = "sName_1";

            }

          }
        }
      } else if (id.indexOf("sName_") >= 0 || id.indexOf("sWeight_") >= 0) {
        if (ids[0] == "sName") {
          newId = "sWeight_" + ids[1] + "_1";

        } else if (ids[0] == "sWeight") {
          if (ids[2] == '1') {
            newId = "sWeight_" + ids[1] + "_2";

          } else {
            const rowNum = parseInt(ids[1]);
            if (rowNum < 14) {
              newId = "sName_" + (rowNum + 1);

            } else {
              newId = "iName_1";

            }

          }
        }
      }
      document.getElementById(id).classList.remove("active");
      if (newId) {
        this.nowFocusId = newId;
        let newTarget = document.getElementById(newId);
        if (newTarget) {
          newTarget.classList.add("active");
          newTarget.focus();
        }
      }
    },
    onShiftTab(id) {
      let newId = null;
      const ids = id.split("_");
      if (id.indexOf("iName_") >= 0 || id.indexOf("iWeight_") >= 0) {
        if (ids[0] == "iName") {
          const rowNum = parseInt(ids[1]);
          if (rowNum == 1) {
            newId = "sWeight_14_2";

          } else {
            newId = "iWeight_" + (rowNum - 1) + "_2";

          }

        } else if (ids[0] == "iWeight") {
          if (ids[2] == '1') {
            newId = "iName_" + ids[1];

          } else {
            newId = "iWeight_" + ids[1] + "_1";

          }
        }
      } else if (id.indexOf("sName_") >= 0 || id.indexOf("sWeight_") >= 0) {
        if (ids[0] == "sName") {
          const rowNum = parseInt(ids[1]);
          if (rowNum == 1) {
            newId = "iWeight_14_2";

          } else {
            newId = "sWeight_" + (rowNum - 1) + "_2";

          }

        } else if (ids[0] == "sWeight") {
          if (ids[2] == '1') {
            newId = "sName_" + ids[1];

          } else {
            newId = "sWeight_" + ids[1] + "_1";

          }
        }
      }
      document.getElementById(id).classList.remove("active");
      if (newId) {
        this.nowFocusId = newId;
        let newTarget = document.getElementById(newId);
        if (newTarget) {
          newTarget.classList.add("active");
          newTarget.focus();
        }
      }
    },
    inputFocus(event) {
      let actives = document.getElementsByClassName("active");
      for (let active of actives) {
        active.classList.remove("active");
      }
      event.target.classList.add("active");
      this.nowFocusId = event.target.id;
    },
  }
}
</script>

<style scoped>
.materialDialog {
  position: absolute;
  width: 70%;
  height: 42vw;
  z-index: 9999;
  transform: translate(10%,7%);
  border-radius: 20px;
}
.materialWrap {
  display: inline-flex;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}
.numOfPeople {
  position: relative;
  width: 100%;
  background-color: rgb(255, 228, 228);
  border-radius: 10px 0 0 0;
  padding: 0.5vw 0;
  height: 2vw;
}

.numOfPeople input {
  height: 1.6vw;
  margin-right: 10px;
  width: 3vw;
  font-size: 1.2vw;
}
input:focus {
  outline: none;
}
.numOfPeople label {
  font-size: 1vw;
  line-height: 1.6vw;
  vertical-align: bottom;
}
.ingredients {
  position: relative;
  background-color: aliceblue;
  width: 50%;
  height: 39vw;
  border-radius: 0 0 0 10px;
  overflow-y: auto;
}
.seasoning {
  position: relative;
  background-color: beige;
  width: 50%;
  height: 39vw;
  overflow-y: auto;
}
.partTitle {
  font-size: 1.6vw;
  margin: 10px;
  font-weight: bold;
  text-align: left;
}
table {
  border-collapse: separate;
  margin: 0 auto;
}
th {
  line-height: 2rem;
}
th:nth-child(1) {
  background-color: #c4e4ff;
}
th:nth-child(2) {
  background-color: #ecffc4;
}
th:nth-child(3) {
  background-color: #ffe1ff;
}
th:nth-child(4) {
  background-color: #ffe1ff;
}
td {
  margin: 0;
  padding: 0;
}
input, select {
  height: 1.8vw;
}
input[type="checkbox"] {
  width: 1.2vw;
  margin: 0 0.8vw;
}
select {
  width: 3vw;
  margin: 0 0.4vw;
}
.name {
  width: 15vw;
}
.weight1 {
  width: 8vw;
}
.weight2 {
  width: 3vw;
}
.active {
  border: 1px solid red;
}
.nestInput {
  width: 14.2vw !important;
}
.assemblyDialog {
  position: absolute;
  width: 20%;
  height: 42vw;
  z-index: 10000;
  transform: translate(385%,7%);
  border-radius: 0 12px 12px 0;
  background-color: rgb(224, 245, 220);
}
.values {
  display: inline-flex;
  flex-wrap: wrap;
  overflow-y: auto;
  padding: 0 20px;
}
.value {
  margin: 5px;
  padding: 5px 7px;
  border-radius: 5px;
  background-color: aliceblue;
  border: 1px solid lightgray;
  cursor: pointer;
	-webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-wrap: nowrap;
  line-height: 1vw;
}
.box {
  width: 20px;
  height: 20px;
  background-color: red;
}
.dropzone {
  border: 1px solid #555;
  margin-top: 5px;
  width: 246px;
  min-height: 100px;
  position: relative;
  font-size: 12px;
}
.red{
  background-color: rgb(255, 225, 225);
}
.blue {
  background-color: rgb(215, 229, 255);
}
.green{
  background-color: rgb(201, 255, 213);
}
</style>
