<template>
  <HeaderLine/>
  <Menu/>
  <div class="home">
    <div class="gingamuCheck">
      <div class="recipeJP">レンチンメニュー</div>
      <div class="recipe">rentin menu</div>
    </div>
    <div class="partWrap">
      <div class="searchIconWrap">
        <img class="searchIcon" src="../assets/icons/search.svg" @click="dispSearchPart" alt="">
      </div>
      <div id="searchPart">
        <table class="searchTable">
          <tr>
            <td>
              <label class="searchInputLabel" for="searchInput">検索</label>
            </td>
            <td>
              <input type="text" id="searchInput" @input="searchCooking" placeholder="検索文字列">
            </td>
          </tr>
          <tr>
            <td class="tagTitle">
              <label class="searchInputLabel">Tag</label>
            </td>
            <td class="tagValues">
              <div v-for="(item, key) in tags" :key="key" class="tagPart">
                <span class="searchTag" @click="searchTag(item)">#{{ item }}</span>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div id="recipePart">
        <div class="selectTagWrap">
          <div v-for="selectTag in selectTags" :key="selectTag" class="selectTag">
            <div class="selectTagLeftPart">
              {{ selectTag }}
            </div>
            <div class="selectTagRightPart">
              <img class="deleteSelectTagIcon" @click="deleteSelectTag(selectTag)" src="../assets/icons/close.svg" alt="">
            </div>
          </div>
        </div>
        <dd>
          <dl class="cooking" v-for="(item, key) in dispCookings" :key="key">
            <div @click="displayRecipe(item.id)" class="deployCooking cookImgSum">
              <div class="ranchonBack"></div>
              <img class="cookImg" v-if="item.imgUrls && item.imgUrls.length > 0" :src="item.imgUrls[0]" alt="">
            </div>
            <div @click="displayRecipe(item.id)" class="deployCooking cookSum">
              <!-- <div class="cookSumDate">{{ item.createDateFmt }}</div> -->
              <div v-if="item.dispTitle" class="cookSumTitle">{{ item.dispTitle }}</div>
              <div v-else class="cookSumTitle">{{ item.title }}</div>
            </div>
          </dl>
        </dd>
      </div>
      <div id="adsensePart">
				<VueAdsense
					ad-client="ca-pub-1312778661109881" 
					ad-slot="6040877045"
					ad-style="display:block; text-align:center;"
					ad-format="fluid"
					ad-layout="in-article">
				</VueAdsense>
      </div>
    </div>
  </div>
  <ToTop/>
  <Footer/>
</template>

<script>
import firebase from "../main.js";
import { collection, getDocs } from "firebase/firestore";

import HeaderLine from "@/components/HeaderLine.vue";
import Menu from "@/components/Menu.vue";
import ToTop from "@/components/ToTop.vue";
import Footer from "@/components/Footer.vue";

import VueAdsense from 'vue-adsense'

export default {
  name: 'Home',
  components: {
    HeaderLine,
    Menu,
    ToTop,
    Footer,
		VueAdsense,
  },
  data() {
    return {
      dispCookings: [],
      cookings: [],
      tags: [],
      selectTags: [],
    }
  },
  mounted() {
    this.awaitFunc();
    document.getElementsByTagName('body')[0].style.backgroundColor = "#ffffea";
  },
  methods: {
    async awaitFunc() {
      this.cookings = [];
      const cookingsQuerySnapshot = await getDocs(collection(firebase.db, "Cookings2"));
      let dataIndex = 0;
      cookingsQuerySnapshot.forEach((docu) => {
        let data = docu.data();
        if (data.recipeCategory == "rentin" && data.isPublic) {
          data.id = docu.id;
          data.index = dataIndex;
          data.createDateFmt = this.dateFormat(data.createDate);
          data.updateDateFmt = this.dateFormat(data.updateDate);
          if (data.tags) {
            this.tags = this.tags.concat(data.tags.split(","));
          }
          this.cookings.push(data);
        }
        dataIndex++;
      });
      this.tags = Array.from(new Set(this.tags));
      this.cookings.sort((a, b) => b.createDate - a.createDate);
      this.dispCookings = this.cookings;
    },
    dateFormat(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return date.getFullYear() + "." + ("00" + (date.getMonth()+1)).slice(-2) + "." + ("00" + date.getDate()).slice(-2);
    },
    displayRecipe(id) {
      this.$router.push({name: 'RentinRecipe', query: {id: id}});
    },
    searchTag(tag) {
      if (this.selectTags.includes(tag)) {
        return;
      }
      this.selectTags.push(tag);
      this.dispCookings = this.cookings.filter((ele) => {
        const eleTags = ele.tags.split(",");
        for (let i = 0; i < eleTags.length; i++) {
          if (this.selectTags.includes(eleTags[i])) {
            return true;
          }
        }
      });
    },
    deleteSelectTag(tag) {
      this.selectTags = this.selectTags.filter((ele) => ele != tag);
      if (this.selectTags.length > 0) {
        this.dispCookings = this.cookings.filter((ele) => {
          const eleTags = ele.tags.split(",");
          for (let i = 0; i < eleTags.length; i++) {
            if (this.selectTags.includes(eleTags[i])) {
              return true;
            }
          }
        });
      } else {
        this.dispCookings = this.cookings;
      }
    },
    searchCooking(e) {
      const searchStr = e.target.value;
      const upper = searchStr.toUpperCase();
      const lower = searchStr.toLowerCase();
      this.dispCookings = this.cookings.filter((cook) => {
        if (cook.dispTitle) {
          const upperTitle = cook.dispTitle.toUpperCase();
          const lowerTitle = cook.dispTitle.toLowerCase();
          return upperTitle.indexOf(upper) >= 0
                || lowerTitle.indexOf(lower) >= 0;
        }
        return false;
      })
    },
    dispSearchPart() {
      if (document.getElementById("searchPart").style.display == "grid") {
        document.getElementById("searchPart").style.display = "none";
      } else {
        document.getElementById("searchPart").style.display = "grid";
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@font-face {
  font-family: 'chihaya';
  src: url('../../public/fonts/nchihaya.ttf') format('truetype');
}
.home {
  font-family: 'chihaya';
  width: 100%;
}
.gingamuCheck {
	margin: 1.5em auto 2em;
	padding: 2.2em;/*内側余白*/
	background-image: linear-gradient(90deg, rgba(111, 111, 111, .1) 50%, transparent 50%), linear-gradient(rgba(111, 111, 111,.1) 50%, transparent 50%);/* チェック色 */
	background-size: 15px 15px;	/* チェックのサイズ */
}
.recipeJP {
  font-size: 1.8vw;
  font-weight: bold;
  color: #a83424;
}
.recipe {
  font-size: 1vw;
  color: #a83424;
}
.partWrap {
  display: inline-flex;
  width: 100%;
}
#recipePart {
  width: 60%;
  margin: 0 auto;
  height: auto;
}
.cookImg {
  position: absolute;
  width: 100%;
  height: 10vw;
  object-fit: cover;
  z-index: 0;
}
.cookSum {
  width: 15vw;
  margin: 0 auto;
}
.cookImgSum {
  height: 10vw;
}
.cookSumDate {
  font-size: 14px;
  text-align: center;
}
.cookSumTitle {
  font-size: 1.2vw;
  line-height: 2.2vw;
  text-align: center;
  font-weight: bold;
}
.deployCooking {
  cursor: pointer;
}
dd {
  width: 100%;
  display: inline-flex;
  margin-inline-start: 0px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
dl {
  position: relative;
  width: 16vw;
  max-width: 16vw;
  height: 15vw;
  margin: 1vw 1.6vw 0;
  text-align: left;
}
.ranchonBack {
  width: 16vw;
  height: 10vw;
  position: absolute;
  top: -7px;
  left: -10px;
  background: linear-gradient(to right, #AAB300, #212114);
  transform: rotate(3deg);
  opacity: 0.5;
}
/* 検索 */
#searchPart {
  position: relative;
  width: 20%;
}
.searchIconWrap {
  display: none;
}
.searchIcon {
  width: 8vw;
  height: auto;
  float: right;
  padding-right: 4vw;
}
.searchTable {
  width: 80%;
  margin: 0 auto;
}
.tagTitle {
  vertical-align: top;
  padding-top: 1vw;
}
.tagValues {
  padding-top: 1vw;
  text-align: left;
  text-indent: 1rem;
}
.tagPart {
  font-size: 1rem;
  line-height: 1.6rem;
}
.searchTag {
  cursor: pointer;
  letter-spacing: 2px;
}
.selectTagWrap {
  display: flex;
  border-radius: 10px;
  margin-bottom: 0.8vw;
  margin-left: 1vw;
  flex-wrap: wrap;
}
.selectTag {
  padding: 5px 10px;
  margin: 0.2vw 0.4vw;
  background-color: #F7EDED;
  border-radius: 10px;
  line-height: 1.4vw;
  padding: 0 10px;
  display: inline-flex;
}
.selectTagLeftPart {
  padding-right: 0.3vw;
}
.selectTagRightPart {
  border-left: 2px solid lightgray;
  padding-left: 0.4vw;
}
.deleteSelectTagIcon {
  width: 0.6vw;
  height: auto;
  cursor: pointer;
}
.searchInputLabel {
  font-size: 1.6rem;
  vertical-align: bottom;
}
input:focus {
  outline: none;
  background-color: rgb(252, 252, 252) !important;
}
#searchInput {
  width: 12vw;
  height: 1.4vw;
  font-size: 1.2rem;
  vertical-align: text-bottom;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #919191;
  background-color: unset;
  font-family: 'chihaya';
}
/* アドセンス */
#adsensePart {
  position: relative;
  width: 20%;
}
@media screen and (max-width:640px) {
  .recipeJP {
    font-size: 2rem;
  }
  .recipe {
    font-size: 1.6rem;
  }
  .gingamuCheck {
    margin: 0 auto 0.6em;
    padding: 1em;/*内側余白*/
  }
  #recipePart {
    width: 95%;
    margin-bottom: 2rem;
  }
  dd {
    display: grid;
  }
  dl {
    width: unset;
    max-width: 100%;
    height: 11rem;
    margin: 0 auto 3rem;
    height: unset;
  }
  .cookImg {
    display: grid;
    position: relative;
    width: 80%;
    height: auto;
    margin: 0 auto 2vw;
    align-items: center;
  }
  .cookSum {
    width: 100%;
    margin: 0 auto;
  }
  .cookImgSum {
    height: unset;
  }
  .ranchonBack {
    width: 80%;
    height: 42vw;
    left: 27px;
  }
  .cookSumTitle {
    font-size: 1.2rem;
    line-height: 1.4rem;
    word-break: keep-all;
  }
  .partWrap {
    display: inline-grid;
    width: 100%;
  }
  .searchIconWrap {
    display: block;
    position: relative;
    width: 100%;
  }
  #searchPart {
    display: none;
    width: 100%;
  }
  .tagValues {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .tagTitle {
    width: 14vw;
  }
  .searchTable {
    width: 95%;
    margin-bottom: 2rem;
  }
  #searchInput {
    width: 90%;
    height: 7vw;
  }
  .selectTagWrap {
    margin-bottom: 8vw;
  }
  .selectTag {
    border-radius: 17px;
    font-size: 1.2rem;
    line-height: 2rem;
    padding-left: 12px;
  }
  .selectTagRightPart {
    padding-left: 1.4vw;
  }
  .deleteSelectTagIcon {
    width: 1.2rem;
    vertical-align: text-top;
  }
  #adsensePart {
    display: none;
  }
}
</style>
