<template>
  <div class="configDialog">
    <div class="tabMenuWrap">
      <div class="tabMenu" :class="showAssembly.cls" @click="showClick('assembly')">材料</div>
      <div class="tabMenu" :class="showTag.cls" @click="showClick('tag')">タグ</div>
    </div>
    <AssemblyConfig v-if="showAssembly.show" />
    <TagConfig v-if="showTag.show" />
  </div>
</template> 

<script>
import AssemblyConfig from './AssemblyConfigDialog.vue';
import TagConfig from './TagConfigDialog.vue';

export default {
  name: 'ConfigDialog',
  components: {
    AssemblyConfig,
    TagConfig
  },
  data() {
    return {
      showAssembly: {
        show: true,
        cls: 'tabMenu-select'
      },
      showTag: {
        show: false,
        cls: ''
      },
    }
  },
  mounted() {
  },
  beforeUnmount() {
    this.$emit("dialogClose", this);
  },
  methods: {
    showClick(tabName) {
      if (tabName == "assembly") {
        this.showAssembly.show = true;
        this.showAssembly.cls = 'tabMenu-select';
        this.showTag.show = false;
        this.showTag.cls = '';
      } else if (tabName == "tag") {
        this.showAssembly.show = false;
        this.showAssembly.cls = '';
        this.showTag.show = true;
        this.showTag.cls = 'tabMenu-select';
      }
    }
  }
}
</script>

<style scoped>
.configDialog {
  position: absolute;
  width: 80%;
  height: 41vw;
  z-index: 10000;
  transform: translate(10%, 8%);
  border-radius: 12px;
  border: 1px solid black;
  background-color: rgb(255, 255, 255);
}
.tabMenuWrap {
  width: 20vw;
  display: inline-flex;
  background: linear-gradient(to right, #7d7d7d, #454545);
  height: 3vw;
  border-radius: 10px;
  margin: 1vw auto;
}
.tabMenu {
  color: white;
  font-weight: bold;
  width: 8vw;
  margin: 0.5vw 1vw;
  height: 2vw;
  vertical-align: middle;
  border-radius: 10px;
  line-height: 2vw;
}
.tabMenu-select {
  color: unset !important;
  background-color: rgba(255,255,255,0.9) !important;
}
</style>
