<template>
  <div class="menu">
    <aside id="hdAside">
			<a id="hdYtBtn" href="https://www.youtube.com/channel/UC0hWaNJCTPDzalprencBYjQ" target="_blank"><span></span>Youtube</a>
			<a id="hdInstaBtn" href="https://www.instagram.com/rentin_syokudou/?hl=ja" target="_blank"><span></span>facebook</a>
		</aside>
    <div class="backLogo">
      <img src="../assets/images/rentin-logo.png" alt="">
    </div>
    <div class="titleWrap">
      <div class="subTitle">レンジで簡単、しっかりご飯</div>
      <div class="title">レンチン食堂</div>
    </div>
    <div class="menuSide">
      <div @click="transition('About')" class="menuPart hachiko">
        <img class="mugLogo" src="../assets/images/mugcup_kuro.png" alt="">
        <div class="menuBgAbout"></div>
        <div class="menuText">はっちゃん</div>
      </div>
      <div @click="transition('RentinMenu')" class="menuPart rentin">
        <img class="rentinLogo" src="../assets/images/rentinkun_kuro.png" alt="">
        <div class="menuBgRentin"></div>
        <div class="menuText">レンチンメニュー</div>
      </div>
      <!-- <div @click="transition('ToasterMenu')" class="menuPart toaster">
        <img class="toasterLogo" src="../assets/images/toaster.png" alt="">
        <div class="menuBgToaster"></div>
        <div class="menuText">トースターメニュー</div>
      </div> -->
    </div>
    <button v-if="isMobileMenu == false" @click="dispMenu" class="mobileMenu">
      <img class="mobileIcon" src="../assets/icons/menu_white.svg" alt="">
    </button>
    <button v-if="isMobileMenu == true" @click="dispMenu" class="mobileClose">
      <img class="mobileIcon" src="../assets/icons/close_white.svg" alt="">
    </button>
    <div id="mobileMenuSide">
      <div @click="transition('About')" class="menuPart hachiko">
        <div class="mobileMenuText">はっちゃん</div>
      </div>
      <div>- - - - - - - - - - - - - - - </div>
      <div @click="transition('RentinMenu')" class="menuPart rentin">
        <div class="mobileMenuText">レンチンメニュー</div>
      </div>
      <!-- <div>- - - - - - - - - - - - - - - </div>
      <div @click="transition('ToasterMenu')" class="menuPart toaster">
        <div class="mobileMenuText">トースターメニュー</div>
      </div> -->
      <div>- - - - - - - - - - - - - - - </div>
      <div class="menuPart toaster">
        <a href="https://www.youtube.com/channel/UC0hWaNJCTPDzalprencBYjQ" target="_blank">Youtube</a>
      </div>
      <div>- - - - - - - - - - - - - - - </div>
      <div class="menuPart toaster">
        <a href="https://www.instagram.com/rentin_syokudou/?hl=ja" target="_blank">Instagram</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {
      isMobileMenu: false,
    }
  },
  mounted() {
    if (location.pathname == "/") {
      document.getElementsByClassName("menuBgRentin")[0].style.display = "grid";
    } else if (location.pathname == "/menu") {
      document.getElementsByClassName("menuBgRentin")[0].style.display = "grid";
    } else if (location.pathname == "/recipe") {
      document.getElementsByClassName("menuBgRentin")[0].style.display = "grid";
    } else if (location.pathname == "/about") {
      document.getElementsByClassName("menuBgAbout")[0].style.display = "grid";
    } else if (location.pathname == "/tmenu") {
      document.getElementsByClassName("menuBgToaster")[0].style.display = "grid";
    } else if (location.pathname == "/trecipe") {
      document.getElementsByClassName("menuBgToaster")[0].style.display = "grid";
    }
  },
  methods: {
    transition(menu) {
      this.$router.push({name: menu});
    },
    dispMenu() {
      this.isMobileMenu = !this.isMobileMenu;
      if (this.isMobileMenu) {
        document.getElementById("mobileMenuSide").classList.add("fadeIn");
      } else {
        document.getElementById("mobileMenuSide").classList.remove("fadeIn");
      }
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'chihaya';
  src: url('../../public/fonts/nchihaya.ttf') format('truetype');
}
.menu {
  font-family: 'chihaya', Avenir, Helvetica, Arial, sans-serif;
}
.menuSide {
  display: inline-flex;
}
#hdAside {
  position: absolute;
  top: 20px;
  right: 0;
}
#hdAside a {
    display: inline-block;
    margin-right: 25px;
}
#hdYtBtn, #hdYtBtn span {
    width: 50px;
    height: 59px;
}
#hdTwBtn, #hdTwBtn span {
    width: 44px;
    height: 33px;
}
#hdInstaBtn, #hdInstaBtn span {
    width: 50px;
    height: 59px;
}
#hdYtBtn, #hdTwBtn, #hdInstaBtn {
    overflow: hidden;
}
a {
    text-decoration: none;
    color: #686868;
}
#hdYtBtn span, #hdTwBtn span, #hdInstaBtn span {
    display: block;
    overflow: hidden;
}
#hdYtBtn span {
  background-image: url('../assets/icons/btn_youtube.png');
  background-position: 0 0;
}
#hdTwBtn span {
  background-image: url('../assets/icons/btn_tw.png');
  background-position: 0 0;
}
#hdInstaBtn span {
  background-image: url('../assets/icons/btn_insta.png');
  background-position: 0 0;
}
#hdYtBtn span:hover {
  background-position: 0 -57px;
}
#hdTwBtn span:hover {
  background-position: 0 -33px;
}
#hdInstaBtn span:hover {
  background-position: 0 -59px;
}
.menuPart {
  position: relative;
  border-right: 4px dotted rgb(253, 220, 171);
  width: 8vw;
}
.menuBgAbout {
  display: none;
  position: absolute;
  background-color: #fad2d2;
  width: 4vw;
  height: 14px;
  left: 44px;
  bottom: -5px;
}
.menuBgRentin {
  display: none;
  position: absolute;
  background-color: #fad2d2;
  width: 127px;
  height: 14px;
  left: 20px;
  bottom: -5px;
}
.menuBgToaster {
  display: none;
  position: absolute;
  background-color: #fad2d2;
  width: 134px;
  height: 14px;
  left: 14px;
  bottom: -5px;
}
.mugLogo {
  width: 3vw;
  height: auto;
}
.rentinLogo {
  width: 5vw;
  height: auto;
}
.toasterLogo {
  width: 4vw;
  height: auto;
}
.menuText {
  position: relative;
}
.titleWrap {
  margin: 10px auto 0;
}
.subTitle {
  font-size: 15px;
}
.title {
  font-size: 2.6vw;
  color: #a89850;
  line-height: 4vw;
}
.backLogo {
  position: fixed;
  top: 0vw;
  left: 2vw;
  z-index: 0;
  opacity: 10%;
}
.backLogo img {
  width: 10vw;
  height: 10vw;
}
.mobileMenu {
  display: none;
}
.mobileClose {
  display: none;
}
#mobileMenuSide {
  display: none;
}
@media screen and (max-width:640px) {
  .backLogo img {
    display: none;
  }
  .titleWrap {
    text-align: left;
  }
  .title {
    font-size: 2rem;
    line-height: 3rem;
    text-indent: 11px;
  }
  .subTitle {
    text-indent: 15px;
  }
  .menuSide {
    display: none;
  }
  #hdAside {
    display: none;
  }
  .menuPart {
    width: unset;
    border-right: unset;
  }
  .mugLogo {
    width: 4rem;
  }
  .rentinLogo {
    width: 7rem;
  }
  .toasterLogo {
    width: 6rem;
  }
  .mobileMenu,
  .mobileClose {
    display: grid;
    border: none;
    background-color: unset;
    width: 10vw;
    height: 10vw;
    position: absolute;
    top: 4vw;
    right: 3vw;
    background-color: #a89850;
    border-radius: 5px;
  }
  .mobileIcon {
    position: absolute;
    width: 60%;
    height: auto;
    top: 2vw;
    left: 2vw;
  }
  #mobileMenuSide {
    display: none;
    position: fixed;
    top: 15vw;
    right: 3vw;
    background-color: #a89850;
    color: white;
    z-index: 999;
    border-radius: 10px;
    padding: 3vw 0;
  }
  .mobileMenuText {
    font-size: 1.6rem;
    line-height: 6rem;
    text-align: center;
    padding: 0 2rem;
    user-select: none;
  }
  a {
    font-size: 1.6rem;
    line-height: 6rem;
    text-align: center;
    padding: 0 2rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: white;
  }
  .fadeIn {
    display: block !important;
    animation: fadeIn 0.5s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
