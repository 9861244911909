<template>
  <div class="tagConfig">
    <div class="tags">
      <span class="addTag">
        <img src="../assets/icons/new.svg" class="newIcon" alt="">
        <input type="text" class="addTagInput" v-model="inputTag" @change="addTag">
      </span>
      <draggable v-model="tags" item-key="no" tag="div" @end="onSort" :options="{animation:300}">
        <template #item="{ element }">
          <span class="spanTag">
            <img src="../assets/icons/dnd.svg" class="dndIcon" alt="">
            <img src="../assets/icons/delete.svg" @click="deleteTag(element.id)" class="deleteIcon" alt="">
            <input type="text" v-model="element.tag"  @change="changeValue(element.id, $event)">
          </span>
        </template>
      </draggable>
    </div>
  </div>
</template> 

<script>
import firebase from "../main.js";
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore";

import draggable from 'vuedraggable'

export default {
  name: 'ConfigDialog',
  components: {
    draggable
  },
  data() {
    return {
      tags: [],
      inputTag: null,

      items: [
        {no:1, name:'キャベツ', categoryNo:'1'},
        {no:2, name:'ステーキ', categoryNo:'2'},
        {no:3, name:'リンゴ', categoryNo:'3'}
      ]
    }
  },
  mounted() {
    this.awaitFunc();
  },
  beforeUnmount() {
  },
  methods: {
    async awaitFunc() {
      this.categories = [];
      const categoriesQuerySnapshot = await getDocs(collection(firebase.db, "Tags"));
      categoriesQuerySnapshot.forEach((docu) => {
        let data = docu.data();
        data.id = docu.id;
        this.tags.push(data);
      });
      this.tags.sort((a, b) => {
        return a.index < b.index ? -1 : 1;
      });
    },
    changeValue(id, event) {
      updateDoc(doc(firebase.db, "Tags", id), { tag: event.target.value });
    },
    async addTag() {
      if (this.inputTag) {
        let addData = {
          index: this.tags.length + 1,
          tag: this.inputTag,
        }
        const db = collection(firebase.db, "Tags");
        const docRef = await addDoc(db, addData);
        addData.id = docRef.id;
        this.tags.push(addData);
        this.inputTag = null;
      }
    },
    async deleteTag(id) {
      if (id) {
        await deleteDoc(doc(firebase.db, "Tags", id));
        this.tags = this.tags.filter((tag) => tag.id != id);
      }
    },
    async onSort() {
      for (let index = 0; index < this.tags.length; index++) {
        updateDoc(doc(firebase.db, "Tags", this.tags[index].id), { index: index + 1 });
      }
    }

  },
}
</script>

<style scoped>
.tagConfig {
  display: inline-flex;
  width: 90%;
  height: 34vw;
}

.tags {
  width: 100%;
  margin: 0.4vw 1vw;
  text-align: left;
  overflow-y: auto;
}
.spanTag {
  position: relative;
  display: inline-grid;
  height: 1.6vw;
  padding: 0.5vw 1vw;
  font-size: 0.8rem;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgb(255, 225, 225);
  margin: 0.5vw 1vw;
}
.spanTag:hover {
  cursor: grab;
}
.spanTag:active {
  cursor: grabbing;
}
.addTag {
  position: relative;
  display: inline-grid;
  height: 1.6vw;
  padding: 0.5vw 1vw;
  font-size: 0.8rem;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgb(225, 255, 226);
  margin: 0.5vw 1vw;
}
.addTagInput {
  width: 9vw;
  height: 1.6vw;
  border: none;
  background-color: rgb(225, 255, 226);
}
.addTagInput:focus {
  outline: none;
  background-color: rgb(198, 255, 200);
}
input {
  width: 9vw;
  height: 1.6vw;
  border: none;
  background-color: rgb(255, 225, 225);
}
input:focus {
  outline: none;
  background-color: rgb(255, 208, 208);
}
.newIcon,
.dndIcon {
  position: absolute;
  width: 1vw;
  height: auto;
  border: 1px solid black;
  border-radius: 50%;
  background-color: white;
  padding: 3px;
  top: -5px;
  left: -5px;
}
.deleteIcon {
  position: absolute;
  width: 1vw;
  height: auto;
  border: 1px solid black;
  border-radius: 50%;
  background-color: white;
  padding: 3px;
  top: -5px;
  right: -5px;
}
</style>
